import {
  ContentLayout,
  usePermission,
  PageNotFound,
  CheckBoxListDialog,
  CustomeDialog,
  HeaderBreadcrumbs,
} from "../../../shared";
import * as React from "react";
import { Button, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  GeneralList,
  ProductStoresList,
  ProductOptionalGroupsList,
  ProductAvailabilityGroupsList,
  ProductAvailability,
  ProductDetailInfo,
  ProductPriceLevel,
  ProductBundleGroups,
  ProductBarcode,
  ProductPromotions,
  ProductFuturePrice,
  ProductSeo,
} from "../components";
import { useProducts } from "..";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LedgerAccountsList } from "../../Configurations/components";
import { ProductReviews } from "../components/ProductReviews";
import { styled } from "@material-ui/styles";

export const ProductsDetailsList = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // reviews view permission
  const isReviewsViewable = usePermission("review-rating-view");
  const {
    state,
    currentTab,
    globalData,
    setCurentTab,
    HandleDetailsInfoChange,
    onsubmitDetailsInfo,
    handleNestedInput,
    handleAvailabilityDate,
    onSubmitAvailability,
    HandleFilterAG,
    handleModal,
    handleAGSelect,
    handleAssignAGToProduct,
    handleDeleteAGFromProduct,
    HandleFilterOG,
    handleOGSelect,
    handleAssignOGToProduct,
    handleDeleteOGFromProduct,
    HandleFilterStore,
    handleStoreSelect,
    handleAssignStoresToProduct,
    handleDeleteStoreFromProduct,
    HandleVarientChange,
    handleCreateAndUpdatevarient,
    handleDeleteVariant,
    handleDefaultVariant,
    HandlePriceLevelChange,
    handleUpdateProductPricelevel,
    handleBundleProductSelect,
    HandleFilterBundleGroup,
    handleAssignproductToBundle,
    handleDeleteProductFromBundle,
    onAddBarcode,
    handleDelete,
    handleSelectAll,
    onCloneSubmit,
    handleSelectAllCopyOptions,
    onCloneOptionsSelect,
    productCloneOptions,
    onProductCopyClick,
    isCreate,
    handleOnChange,
    onChange,
    onSaveBarcode,
    onUpdateBarcode,
    onDeleteIndex,
    onEditBarcode,
    handleDeleteCrossSelling,
    handleDeleteSimilarProducts,
    handleStatusCrossSelling,
    handlePromotionsSelect,
    handleAssignProductToPromotions,
    handleStatusSimilarProducts,
    validateDate,
    isAllValid,
    isNotValidated,
    handleChangeBundleInput,
    handleChangeOGInput,
    updateIsloading,
  } = useProducts({ isDetailsId: id });
  const {
    basicDetails,
    detailsInfo,
    availability,
    availabilityGroup,
    optionalGroup,
    stores,
    isOpen,
    varients,
    priceLevel,
    bundleGroup,
    promotions,
    validation,
    copyProduct: { isOpen: copyIsopen, productName, copyOptions, isBusy },
  } = state;
  const handleChange = (event, newValue) => {
    setCurentTab("productDetails", newValue);

    // Reset reviews search pram
    if (location.search.includes("?reviews")) {
      navigate(location.pathname, { replace: true });
    }
  };

  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }

  return (
    <>
      <ContentLayout
        title={basicDetails.details?.name || ""}
        active={basicDetails.details?.status === 1 ? "Active" : "Inactive"}
        backImage={basicDetails.details?.image}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Products", href: "/products" },
              { name: basicDetails.details?.name || "" },
            ]}
          />
        }
        rightContent={
          <div className="disabled-buttons">
            {isCreate && (
              <Button
                type="button"
                size="medium"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => {
                  !["gift_card", "delivery"].includes(
                    basicDetails.details?.product_type
                  ) && onProductCopyClick(basicDetails.details?.name, id);
                }}
                disabled={["gift_card", "delivery"].includes(
                  basicDetails.details?.product_type
                )}
              >
                <img
                  alt="delete"
                  src="/icons/ic_copy_blue.svg"
                  className={` ${
                    ["gift_card", "delivery"].includes(
                      basicDetails.details?.product_type
                    ) && "disabled-img"
                  }`}
                />
              </Button>
            )}
          </div>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "128px" }}
          >
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              onChange={handleChange}
            >
              <Tab label="General" value="1" />
              {basicDetails.details?.product_type === "bundle" && (
                <Tab label="Bundle Groups" value="2" />
              )}
              <Tab label="Stores" value="3" />
              {!["gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Details" value="4" />}
              {!["gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Availability" value="5" />}
              {!["modifier", "gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Promotions" value="6" />}
              {!["gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Seo" value="7" />}

              {!["modifier", "gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Availability Group" value="8" />}

              {!["bundle", "modifier", "gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Modifier Groups" value="9" />}
              {/* <Tab label="Other" value="10" /> */}
              {!["gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Price Level" value="11" />}
              {!["gift_card", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Barcode" value="12" />}
              {!["bundle"].includes(basicDetails.details?.product_type) && (
                <Tab label="Accounts" value="13" />
              )}
              {!["gift_card", "modifier", "delivery"].includes(
                basicDetails.details?.product_type
              ) && <Tab label="Future Pricing" value="14" />}
              {isReviewsViewable && <Tab label="Reviews" value="15" />}
            </TabList>
          </Box>
          <NoPaddingTabPanel value="1">
            <GeneralList
              data={basicDetails}
              varients={varients}
              globalData={globalData}
              HandleVarientChange={HandleVarientChange}
              handleCreateAndUpdatevarient={handleCreateAndUpdatevarient}
              handleModal={handleModal}
              handleDeleteVariant={handleDeleteVariant}
              handleDefaultVariant={handleDefaultVariant}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="2">
            <ProductBundleGroups
              data={bundleGroup}
              globalData={globalData}
              variants={basicDetails.details?.variants}
              handleModal={handleModal}
              handleBundleProductSelect={handleBundleProductSelect}
              HandleFilterBundleGroup={HandleFilterBundleGroup}
              handleAssignproductToBundle={handleAssignproductToBundle}
              handleDeleteProductFromBundle={handleDeleteProductFromBundle}
              handleChangeBundleInput={handleChangeBundleInput}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="3">
            <ProductStoresList
              data={stores}
              basicDetails={basicDetails}
              HandleFilterStore={HandleFilterStore}
              handleStoreSelect={handleStoreSelect}
              handleModal={handleModal}
              handleAssignStoresToProduct={handleAssignStoresToProduct}
              handleDeleteStoreFromProduct={handleDeleteStoreFromProduct}
              handleSelectAll={handleSelectAll}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="4">
            <ProductDetailInfo
              data={detailsInfo}
              globalData={globalData}
              HandleDetailsInfoChange={HandleDetailsInfoChange}
              onsubmitDetailsInfo={onsubmitDetailsInfo}
              handleNestedInput={handleNestedInput}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="5">
            <ProductAvailability
              data={availability}
              validation={validation}
              isNotValidated={isNotValidated}
              validateDate={validateDate}
              isAllValid={isAllValid}
              handleAvailabilityDate={handleAvailabilityDate}
              onSubmitAvailability={onSubmitAvailability}
              productType={basicDetails.details?.product_type}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="6">
            <ProductPromotions
              data={promotions}
              isCrossSelling={basicDetails?.details?.has_cross_selling}
              isSimilarProduct={basicDetails?.details?.has_similar_product}
              handleModal={handleModal}
              setCurentTab={setCurentTab}
              handleDeleteCrossSelling={handleDeleteCrossSelling}
              handleDeleteSimilarProducts={handleDeleteSimilarProducts}
              handleStatusCrossSelling={handleStatusCrossSelling}
              handlePromotionsSelect={handlePromotionsSelect}
              handleAssignProductToPromotions={handleAssignProductToPromotions}
              handleStatusSimilarProducts={handleStatusSimilarProducts}
              updateIsloading={updateIsloading}
              id={id}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="7">
            <ProductSeo id={id} state={state} />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="8">
            <ProductAvailabilityGroupsList
              data={availabilityGroup}
              HandleFilterAG={HandleFilterAG}
              handleAGSelect={handleAGSelect}
              handleModal={handleModal}
              handleAssignAGToProduct={handleAssignAGToProduct}
              handleDeleteAGFromProduct={handleDeleteAGFromProduct}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="9">
            <ProductOptionalGroupsList
              data={optionalGroup}
              HandleFilterOG={HandleFilterOG}
              handleOGSelect={handleOGSelect}
              handleModal={handleModal}
              handleAssignOGToProduct={handleAssignOGToProduct}
              handleDeleteOGFromProduct={handleDeleteOGFromProduct}
              globalData={globalData}
              handleChangeOGInput={handleChangeOGInput}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="10">Other</NoPaddingTabPanel>
          <NoPaddingTabPanel value="11">
            <ProductPriceLevel
              data={priceLevel}
              HandlePriceLevelChange={HandlePriceLevelChange}
              handleUpdateProductPricelevel={handleUpdateProductPricelevel}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="12">
            <ProductBarcode
              state={state}
              onAddBarcode={onAddBarcode}
              handleDelete={handleDelete}
              globalData={globalData}
              handleOnChange={handleOnChange}
              onChange={onChange}
              onSaveBarcode={onSaveBarcode}
              onUpdateBarcode={onUpdateBarcode}
              onDeleteIndex={onDeleteIndex}
              onEditBarcode={onEditBarcode}
            />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="13">
            <LedgerAccountsList type="product" />
          </NoPaddingTabPanel>
          <NoPaddingTabPanel value="14">
            <ProductFuturePrice />
          </NoPaddingTabPanel>
          {isReviewsViewable && (
            <NoPaddingTabPanel value="15">
              <ProductReviews />
            </NoPaddingTabPanel>
          )}
        </TabContext>
      </ContentLayout>
      <CustomeDialog
        open={copyIsopen}
        handleClose={() => onProductCopyClick()}
        PaperProps={{
          sx: { width: "423px", height: "408px", p: "16px" },
        }}
        title="Copy Product"
        content={
          <CheckBoxListDialog
            Items={productCloneOptions || []}
            onSelect={onCloneOptionsSelect}
            onSubmit={onCloneSubmit}
            isSelected={(id) => copyOptions.includes(id)}
            searchBar={false}
            subHeader={`Creating a copy of product "${productName}"`}
            hasSelectAll={true}
            onAllSelect={handleSelectAllCopyOptions}
            isSelectAllChecked={productCloneOptions?.every((item) =>
              copyOptions.includes(item.id)
            )}
            selectAllText="Include All"
            loading={isBusy}
          />
        }
      />
    </>
  );
};

const NoPaddingTabPanel = styled(TabPanel)(() => ({
  paddingInline: "0",
}));
