import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export const OrdersTable = (prop) => {
  const { state } = prop;

  return (
    <Paper sx={{ boxShadow: "none" }}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell align="left">Item</TableCell>
              <TableCell align="left">Product Price</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="right">Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.data.Products.map((item, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={0}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={1}
                    >
                      {item.product_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={2}
                    >
                      ${item.price}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={3}
                    >
                      {item.quantity}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "baseline",
                      }}
                      key={4}
                    >
                      {/* ${item.price} */}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #E6E6E6",
                        verticalAlign: "bottom",
                      }}
                      align="right"
                      key={5}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={13}
                        sx={{
                          lineHeight: "15px",
                          marginBottom: "8px",
                        }}
                      >
                        ${item.total}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}

            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell
                sx={{
                  borderLeft: "1px solid #E6E6E6",
                  fontWeight: "500",
                }}
              >
                Grand Total
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                }}
                align="right"
              >
                ${state.data.grant_total}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
