import {
  CheckBoxListDialog,
  ContentLayout,
  CustomeDialog,
  Loader,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import { Button, Link, Chip, Typography, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTable } from "../../../shared";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useStore } from "../hooks";
import { sortItems } from "../../../utils";
import InputControl from "../../../shared/components/InputControl";
import { ExportCsv } from "../../../shared";

export const StoreList = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const {
    states,
    onChangeFilter,
    resetFilters,
    isCreateVisible,
    globalData,
    isVisible,
    setCurentTab,
    onStoreCopyClick,
    storeCloneOptions,
    onCloneSubmit,
    onCloneOptionsSelect,
    handleSelectAllCopyOptions,
  } = useStore({ isStore: true });

  const {
    copyStore: { isOpen, storeName, copyOptions, isBusy },
  } = states;

  const onCreate = () => {
    navigate("/stores/create");
  };

  const ActionButtons = ({
    row,
    currentTabAction,
    ProductCurrentTabAction,
  }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <Link
          component="button"
          variant="body2"
          className="LinkBtn"
          onClick={ProductCurrentTabAction}
        >
          Products
        </Link>
        {isCreateVisible && (
          <img
            onClick={() => onStoreCopyClick(row.name, row.id)}
            alt="details"
            src="/icons/ic_copy.svg"
          />
        )}
        {isVisible && (
          <>
            <img
              alt="details"
              onClick={currentTabAction}
              src="/icons/arrow_forward.svg"
            />
          </>
        )}
      </div>
    );
  };
  const headers = [
    { label: "Name", key: "name" },
    { label: "Sales", key: "sales" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_formatted" },
    { label: "Status", key: "status" },
  ];
  const csvData = states.store.map((item) => {
    return {
      ...item,
      status: item.status ? "Active" : "Inactive",
    };
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Sales ($)",
        accessor: "$sales",
        disableGlobalFilter: true,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Typography variant="body2">
              {row.original.phone_formatted}
            </Typography>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ value }) => {
          return (
            <Chip
              label={value === 1 ? "Active" : "Inactive"}
              color="success"
              variant="outlined"
              size="small"
              disabled={value === 1 ? false : "filled"}
            />
          );
        },
      },

      {
        Header: "Action",
        disableGlobalFilter: true,
        id: "action",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              row={row}
              ProductCurrentTabAction={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setCurentTab("storeDetails", "3");
                navigate(`/stores/details/${row.id}`);
              }}
              currentTabAction={() => {
                setCurentTab("storeDetails", "1");
                navigate(`/stores/details/${row.id}`);
              }}
            />
          );
        },
      },
    ],
    []
  );
  if (states.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ContentLayout
        title="Stores"
        drawerOpen={open}
        drawerOnClose={() => setOpen(!open)}
        rightContent={
          <>
            <ExportCsv
              fileName="Stores_Infomation_xlsx"
              csvData={csvData}
              csvHeaders={headers}
            />
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => setOpen(!open)}
            >
              <img
                src="/icons/ic_Tools.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Column Option
            </Button>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => onCreate()}
              >
                New Store
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={states.store}
          isLoading={states.isBusy}
          drawerOpen={open}
          defaultSearch={false}
          drawerOnClose={() => setOpen(!open)}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={states.filters.searchText}
                onChange={(e) => onChangeFilter(e)}
              />
              <InputControl
                type="select"
                name="status"
                label="Status"
                optionValue={globalData.status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={states.filters.status}
              />

              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `/stores/details/${e.id}`;
          }}
          onRowAction={() => {
            setCurentTab("storeDetails", "1");
          }}
        />
      </ContentLayout>
      <CustomeDialog
        open={isOpen}
        handleClose={() => onStoreCopyClick()}
        PaperProps={{
          sx: { width: "423px", height: "408px", p: "16px" },
        }}
        title="Copy Store"
        content={
          <CheckBoxListDialog
            Items={storeCloneOptions || []}
            onSelect={onCloneOptionsSelect}
            onSubmit={onCloneSubmit}
            isSelected={(id) => copyOptions.includes(id)}
            searchBar={false}
            subHeader={`Creating a copy of store "${storeName}"`}
            hasSelectAll={true}
            onAllSelect={handleSelectAllCopyOptions}
            isSelectAllChecked={storeCloneOptions?.every((item) =>
              copyOptions.includes(item.id)
            )}
            selectAllText="Include All"
            loading={isBusy}
          />
        }
      />
    </>
  );
};
