import { API } from "../../../utils";

export const getWastageDetailsById = async (id) => {
  const res = await API.get(`wastages/${id}`);
  return res.data;
};
export const getWastage = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `wastages?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};

export const DownloadWastageList = async (query) => {
  const res = await API.get(`export/wastages`, {
    ...query,
    responseType: "blob",
  },);
  return res;
};
