import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import {
  ContentLayout,
  CustomDetailsList,
  EndofDayTable,
  EndofDayTableSimple,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";

import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { useEndofDay } from "../hooks";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { errorMessage } from "../../../utils";
import { useEffect } from "react";
import Swal from "sweetalert2";

export const AddorEditEndofDay = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let data = null;
  if (searchParams.get("date")) {
    data = {
      store_id: Number(searchParams.get("store_id")),
      terminal_id: Number(searchParams.get("terminal_id")),
      date: searchParams.get("date"),
    };
  }
  const navigate = useNavigate();
  const [rerender, forceUpdate] = React.useState(false);
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        floatAmount: {
          message: "The float amount cannot be negative",
          rule: (value) => {
            return value >= 0 ? true : false;
          },
        },
      },
    })
  );
  const filtervalidator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    setState,
    storeList,
    onChange,
    onSaveEndofDay,
    handleDateChange,
    handleDropdown,
    Cashtotal,
    Floattotal,
    isStoreAdmin,
    isSummaryVisble,
    EODSummary,
    onUpdateEndofDay,
    onFilterChange,
    variance,
  } = useEndofDay({
    isEndofDay: false,
    EndofdayId: id,
    depositslipData: data,
    isStore: true,
    isCreate: true,
    isDetail: false,
  });

  const { endofdaySummary } = state;
  const optionsTerminals = state?.terminalData.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const optionsStores = storeList?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const checkMinValues = (data) => {
    const errors = [];
    data.forEach((item) => {
      if (+item.entry_count > 0 && +item.bank < 0) {
        errors.push({ label: item.denomination_label });
      }
    });
    return errors.length > 0 ? true : false;
  };

  const onSubmit = () => {
    const hasError = checkMinValues(state.data.denominations);
    if (hasError) {
      Swal.fire({
        title: "Check bank values",
        icon: "error",
        text: "Bank fields must be a positive value",
      });
    } else {
      const { variance_status, eod_variance } = endofdaySummary;
      if (state.data.float_total < 0) {
        errorMessage("The float amount cannot be negative");
      }
      if (validator.current.allValid()) {
        if (
          Number(Floattotal) !== Number(state.data.float_total) &&
          !state.data.cashless_till
        ) {
          errorMessage("Float amount and float total is not equal");
        } else if (
          variance_status &&
          Math.abs(variance.toFixed(2)) > eod_variance &&
          !state.isSubmitted
        ) {
          errorMessage("Please recount and submit.");
          setState((draft) => {
            draft.varianceError = true;
            draft.isSubmitted = true;
          });
        } else {
          id ? onUpdateEndofDay(id) : onSaveEndofDay();
        }
      } else {
        validator.current.showMessages();
        forceUpdate(!rerender);
      }
    }
  };

  useEffect(() => {
    if (state.isSubmitted) {
      const { variance_status, eod_variance } = endofdaySummary;
      if (Math.abs(variance) > eod_variance && variance_status) {
        setState((draft) => {
          draft.varianceError = true;
        });
        forceUpdate(!rerender);
      } else {
        setState((draft) => {
          draft.varianceError = false;
        });
      }
    }
  }, [variance, state.isSubmitted]);

  const column = [
    {
      title: "Staff Name",
      accessor: "user_name",
    },
    {
      title: "Submit Date",
      accessor: "sale_date",
    },
    {
      title: "Status",
      accessor: ({ status }) => {
        return (
          <Chip
            label={status === 1 ? "Approved" : "Pending"}
            color={status === 1 ? "success" : "warning"}
            variant={"outlined"}
            sx={{ bgcolor: "#FFF4CE" }}
            size="small"
          />
        );
      },
    },
  ];
  const onApply = () => {
    if (filtervalidator.current.allValid()) {
      EODSummary();
    } else {
      filtervalidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {state.isBusy ? (
        <Loader />
      ) : (
        <ContentLayout
          title={
            id
              ? state.data.store_name + "-" + state.data.terminal_name
              : "End Of Day"
          }
          breadcrumbs={
            <>
              {id ? (
                <HeaderBreadcrumbs
                  links={[
                    {
                      name: "Dashboard",
                      href: isStoreAdmin ? "/store-admin" : "/",
                    },
                    {
                      name: "End of Day",
                      href: isStoreAdmin
                        ? "/store-admin/end-of-day"
                        : "/end-of-day",
                    },
                    {
                      name: "Details",
                      href: isStoreAdmin
                        ? `/store-admin/end-of-day/details/${id}`
                        : `/end-of-day/details/${id}`,
                    },
                    { name: "edit" },
                  ]}
                />
              ) : (
                <HeaderBreadcrumbs
                  links={[
                    { name: "Dashboard", href: "/" },
                    {
                      name: "End of Day",
                      href: isStoreAdmin
                        ? "/store-admin/end-of-day"
                        : "/end-of-day",
                    },
                    { name: "create" },
                  ]}
                />
              )}
            </>
          }
        >
          {validator.current.purgeFields()}
          <Card sx={{ p: "32px 24px", mb: "24px", height: "110px" }}>
            <Stack direction="row" spacing={2}>
              {!isStoreAdmin && (
                <InputControl
                  name="store_id"
                  type="dropdown"
                  disabled={id}
                  options={optionsStores}
                  value={
                    (optionsStores.length > 0 &&
                      state.filterData.store_id &&
                      optionsStores?.find(
                        (c) => c.value === state.filterData.store_id
                      )) ||
                    null
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(e, newValue) => handleDropdown(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store Name"
                      error={filtervalidator.current.message(
                        "Store Name",
                        state.filterData.store_id,
                        "required"
                      )}
                      helperText={filtervalidator.current.message(
                        "Store Name",
                        state.filterData.store_id,
                        "required"
                      )}
                    />
                  )}
                />
              )}

              <InputControl
                name="terminal_id"
                type="dropdown"
                disabled={id}
                options={optionsTerminals}
                getOptionLabel={(option) => option.label}
                value={
                  (optionsTerminals.length > 0 &&
                    state.filterData.terminal_id &&
                    optionsTerminals?.find(
                      (c) => c.value === state.filterData.terminal_id
                    )) ||
                  null
                }
                onChange={(e, newValue) =>
                  onFilterChange({
                    target: {
                      name: "terminal_id",
                      value: newValue?.value || "",
                    },
                  })
                }
                renderInput={(params) => (
                  <TextField
                    label="Terminal Name"
                    {...params}
                    error={filtervalidator.current.message(
                      "Terminal Name",
                      state.filterData.terminal_id,
                      "required"
                    )}
                    helperText={filtervalidator.current.message(
                      "Terminal Name",
                      state.filterData.terminal_id,
                      "required"
                    )}
                    required
                  />
                )}
              />
              <InputControl
                type="datePicker"
                disabled={id}
                disableFuture={true}
                name="sale_date"
                label="Sale Date"
                inputFormat="dd/MM/yyyy"
                sx={{
                  width: "100%",
                }}
                required
                value={
                  state.filterData.sale_date ? state.filterData.sale_date : null
                }
                onChange={(e) => {
                  handleDateChange(e, "sale_date");
                }}
                error={filtervalidator.current.message(
                  "Sale Date",
                  state.filterData.sale_date,
                  "required"
                )}
                helperText={filtervalidator.current.message(
                  "Sale Date",
                  state.filterData.sale_date,
                  "required"
                )}
              />
              <Button
                variant="contained"
                sx={{ height: "40px", width: "68px" }}
                disabled={state.isApplyButtonBusy || id}
                onClick={() => onApply()}
              >
                Apply
              </Button>
            </Stack>
          </Card>
          {state.isLoading ? (
            <Loader />
          ) : (
            <>
              {state.showEodTable === false && (
                <Box>
                  <CustomDetailsList
                    columns={column}
                    data={state?.eod ? state?.eod : {}}
                    title="Submitted Details"
                    headerRightContent={
                      <>
                        <Button
                          type="button"
                          size="medium"
                          variant="text"
                          flex-direction="right"
                          onClick={() =>
                            isStoreAdmin
                              ? navigate(
                                  `/store-admin/end-of-day/details/${state?.eod?.id}`
                                )
                              : navigate(
                                  `/end-of-day/details/${state?.eod?.id}`
                                )
                          }
                        >
                          View Details
                        </Button>
                      </>
                    }
                  />
                </Box>
              )}
              {state.showEodTable === true && (
                <Card>
                  <Stack sx={{ p: "32px 24px" }}>
                    <Typography variant="subtitle1" fontWeight={600}>
                      End of Day
                    </Typography>
                  </Stack>
                  <Divider />
                  {!state.data.cashless_till && (
                    <>
                      <Grid
                        container
                        spacing={2}
                        sx={{ p: "32px 24px", height: "110px" }}
                      >
                        <Grid item xs={2}>
                          <Typography variant="subtitle1" fontWeight={600}>
                            Float Amount
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <InputControl
                            name="float_total"
                            label="Amount ($)"
                            type="number"
                            value={state.data.float_total}
                            onChange={(e) => onChange(e)}
                            error={validator.current.message(
                              "Amount",
                              state.data.float_total,
                              "required|numeric|integer|floatAmount"
                            )}
                            helperText={validator.current.message(
                              "Amount",
                              state.data.float_total,
                              "required|numeric|integer|floatAmount"
                            )}
                            required
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Typography sx={{ fontSize: 600, fontSize: "16px" }}>
                            {state.data.eod_template_name}
                          </Typography>
                        </Grid>
                      </Grid>
                      {state.data.simple ? (
                        <EndofDayTableSimple
                          denominations={state.data.denominations}
                          float_amount={state.data.float_total}
                          isCalculated={state.isCalculated}
                          setState={setState}
                          stateKey="data"
                        />
                      ) : (
                        <EndofDayTable
                          denominations={state.data.denominations}
                          float_amount={state.data.float_total}
                          isCalculated={state.isCalculated}
                          setState={setState}
                          stateKey="data"
                        />
                      )}
                    </>
                  )}
                  <Grid container sx={{ p: "32px 24px", gap: "8px" }}>
                    <Grid item md={4}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        Cheque
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <InputControl
                        name="cheque_total"
                        label="(In $)"
                        type="number"
                        value={state.data.cheque_total}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>
                  </Grid>
                  {!isSummaryVisble && (
                    <>
                      <Box
                        component="main"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#E1FBF2",
                          p: "32px 24px",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={24}
                          color="#0F875B"
                        >
                          Total Amount: $
                          {(Cashtotal + +state.data.cheque_total).toFixed(2)}
                        </Typography>
                      </Box>
                      <Divider />
                      {state.varianceError && (
                        <RemarksSection
                          state={state}
                          onChange={onChange}
                          validator={validator}
                        />
                      )}
                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        disabled={state.isSaveButtonBusy}
                        sx={{
                          float: "right",
                          margin: "24px",
                        }}
                        onClick={() => onSubmit()}
                      >
                        {isSummaryVisble ? "End The Day" : "Submit"}
                      </Button>
                    </>
                  )}
                </Card>
              )}

              {state.showEodTable === true && (
                <Box marginTop={2} marginBottom={5}>
                  {isSummaryVisble && (
                    <Card>
                      <Grid
                        container
                        sx={{
                          height: "83px",
                          padding: "32px 24px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            fontSize={16}
                            sx={{
                              lineHeight: "19px",
                            }}
                          >
                            Summary
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Stack direction="row">
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>Cash in Hand</Typography>
                          <Typography>
                            ${Cashtotal ? Cashtotal.toFixed(2) : "0.00"}
                          </Typography>
                        </Stack>
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>Loyalty</Typography>

                          <Typography>
                            <Typography>
                              $
                              {state?.endofdaySummary?.loyalty_total
                                ? state?.endofdaySummary?.loyalty_total?.toFixed(
                                    2
                                  )
                                : "0.00"}
                            </Typography>
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row">
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>System Cash</Typography>
                          <Typography>
                            $
                            {state?.endofdaySummary?.system_cash
                              ? state?.endofdaySummary?.system_cash.toFixed(2)
                              : "0.00"}
                          </Typography>
                        </Stack>
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>Gift Card</Typography>
                          <Typography>
                            $
                            {state?.endofdaySummary?.gift_card_total
                              ? state?.endofdaySummary?.gift_card_total
                              : "0.00"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row">
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>Round off</Typography>
                          <Typography>
                            <Typography>
                              $
                              {state?.endofdaySummary?.round_off_total
                                ? state?.endofdaySummary?.round_off_total.toFixed(
                                    2
                                  )
                                : "0.00"}
                            </Typography>
                          </Typography>
                        </Stack>
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>Client Account</Typography>
                          <Typography>
                            $
                            {state?.endofdaySummary?.client_account_total
                              ? state?.endofdaySummary?.client_account_total
                              : "0.00"}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction="row">
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>Variance</Typography>
                          <Typography
                            color={variance === 0 ? "#0F875B" : "#C42B1C"}
                          >
                            ${variance.toFixed(2)}
                          </Typography>
                        </Stack>
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>EFT/Card</Typography>
                          <Typography>
                            <Typography>
                              $
                              {state?.endofdaySummary?.card_total
                                ? state?.endofdaySummary?.card_total?.toFixed(2)
                                : "0.00"}
                            </Typography>
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction="row">
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography>Cheque</Typography>
                          <Typography>
                            $
                            {state?.data?.cheque_total
                              ? state?.data?.cheque_total
                              : "0.00"}
                          </Typography>
                        </Stack>
                        <Stack
                          border={1}
                          borderColor="#E6E6E6"
                          width="100%"
                          height="48px"
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14.5px 26px",
                          }}
                        >
                          <Typography color="#808080">Total Tax</Typography>
                          <Typography color="#808080">
                            $
                            {state?.endofdaySummary?.total_tax
                              ? state?.endofdaySummary?.total_tax
                              : "0.00"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Box
                        component="main"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#E1FBF2",
                          p: "32px 24px",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={24}
                          color="#0F875B"
                        >
                          Gross Sales: $
                          {(Cashtotal + +state.data.cheque_total).toFixed(2)}
                        </Typography>
                      </Box>
                      <Divider />
                      {state.varianceError && (
                        <RemarksSection
                          state={state}
                          onChange={onChange}
                          validator={validator}
                        />
                      )}

                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        sx={{
                          float: "right",
                          margin: "24px",
                        }}
                        onClick={() => onSubmit()}
                        disabled={state.isSaveButtonBusy}
                      >
                        {isSummaryVisble ? "End The Day" : "Submit"}
                      </Button>
                    </Card>
                  )}
                </Box>
              )}
            </>
          )}
        </ContentLayout>
      )}
    </>
  );
};

const RemarksSection = ({ state, onChange, validator }) => {
  return (
    <Stack margin="24px" gap="14px">
      <Alert color="error" severity="warning">
        Please recount and submit.
      </Alert>
      <InputControl
        type="text"
        multiline
        name="remarks"
        value={state.data.remarks}
        onChange={onChange}
        placeholder="Remarks*"
        sx={{
          ".MuiInputBase-multiline": {
            minHeight: "110px",
            alignItems: "flex-start",
          },
        }}
        error={validator.current.message(
          "Remarks",
          state.data.remarks,
          "required"
        )}
        helperText={validator.current.message(
          "Remarks",
          state.data.remarks,
          "required"
        )}
      />
    </Stack>
  );
};
