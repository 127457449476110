import React from "react";
import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import { Box, TextField } from "@mui/material";
import { useXeroAndMyOb } from "../hooks/useXeroAndMyOb";
import InputControl from "../../../shared/components/InputControl";
import ReportGenerationPanel from "../components/ReportGenerationPanel";
import CollapsibleSettingsTable from "../components/CollapsibleSettingsTable";

export const XeroAndMyObList = () => {
  const {
    breadcrumbsLink,
    storeList,
    isStoreAdmin,
    state,
    title,
    dateValidator,
    settingsValidator,
    handleRowChange,
    handleSaveUpdates,
    handleSearch,
    onGenerateReport,
    validateDate,
    handleSearchClear,
    handleInputChange,
    filterAccountSettingsList,
    setState,
  } = useXeroAndMyOb();

  // Tab configuration
  const tabs = React.useMemo(() => {
    // Filter the list on search
    const filteredArray =
      state.searchValue === ""
        ? state.accountSettingsList
        : filterAccountSettingsList({ value: state.searchValue });

    // Trigger error message
    settingsValidator.current.showMessages();

    return [
      {
        label: "Report",
        value: "1",
        panel: (
          <ReportGenerationPanel
            {...{
              handleInputChange,
              onSubmit: onGenerateReport,
              state,
              validateDate,
              validator: dateValidator,
            }}
          />
        ),
      },
      {
        label: "Settings",
        value: "2",
        panel: (
          <CollapsibleSettingsTable
            {...{
              title,
              state,
              tableData: filteredArray,
              isLoading: state.isBusy,
              handleSearch,
              handleSearchClear,
              handleRowChange,
              handleSaveUpdates,
              validator: settingsValidator,
            }}
          />
        ),
      },
    ];
  }, [
    state.isBusy,
    state.data,
    state.validation,
    state.searchValue,
    settingsValidator,
    state.accountSettingsList,
    state.isSettingsUpdated,
    state.isSaving,
    title,
  ]);

  return (
    <ContentLayout
      breadcrumbs={<HeaderBreadcrumbs heading="" links={breadcrumbsLink} />}
      rightContent={
        !isStoreAdmin && (
          <Box component="div" sx={{ minWidth: "271px" }}>
            <InputControl
              type="dropdown"
              name="store_id"
              disableClearable
              options={storeList ?? []}
              id="disable-close-on-select"
              getOptionLabel={(option) => option.name}
              value={
                (storeList?.length > 0 &&
                  state.data.store_id &&
                  storeList?.find((c) => c.id === state.data.store_id)) ||
                null
              }
              onChange={(e, newValue) => {
                handleInputChange(
                  {
                    target: {
                      name: "store_id",
                      value: newValue.id,
                    },
                  },
                  "store_id"
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Store"
                  error={dateValidator.current.message(
                    "store",
                    state.data.store_id,
                    "required"
                  )}
                  helperText={dateValidator.current.message(
                    "store",
                    state.data.store_id,
                    "required"
                  )}
                />
              )}
            />
          </Box>
        )
      }
      title={title.toUpperCase()}
      tabProps={{
        label: title,
        list: tabs,
      }}
    ></ContentLayout>
  );
};
