import * as React from "react";
import { ContentLayout, CustomTable, Loader } from "../../../shared";
import {
  Button,
  Stack,
  Card,
  Divider,
  TextField,
  Box,
  Typography,
  createFilterOptions,
  Checkbox,
  Chip,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { useStore } from "../../Stores";
import { useReports } from "../hooks";
import moment from "moment";
import { arraySort } from "../../../utils";

export const ReportList = () => {
  const {
    state,
    globalData,
    isStoreAdmin,
    onChangeFilter,
    onClearFilter,
    handleGenerateReport,
    onDeleteStoreSelect,
    handleExportTableList,
    onDeleteDaySelect,
  } = useReports();
  const { filters } = state;
  const { storeList } = useStore({
    isStore: !isStoreAdmin ? true : false,
    storeId: null,
  });
  const [columnOptions, setColumnOptions] = React.useState(false);
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        endDate: {
          required: true,
          message: "The end date should not be before start date",
          rule: (value) => {
            const { startTime, endTime } = value;
            return (
              startTime &&
              endTime &&
              moment(startTime).isSameOrBefore(moment(endTime))
            );
          },
        },
      },
    })
  );
  const toggleColumns = () => {
    setColumnOptions(!columnOptions);
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleGenerateReport();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const storeOptions = storeList?.map((v) => {
    return { value: v.id, label: v.name };
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const findSum = (data, key, isDollar = true, roundOff = 2) => {
    const total = data.reduce(
      (acc, item) => Number(item.original[key]) + acc,
      0
    );
    return isDollar ? "$" + total.toFixed(roundOff) : total.toFixed(roundOff);
  };

  const findTotal = (data, key) => {
    const total = data.reduce(
      (acc, item) => Number(item.original[key]) + acc,
      0
    );
    return total;
  };

  const columnsEod = React.useMemo(
    () => [
      {
        Header: "Sale Date",
        accessor: "DateFormatted",
        Cell: ({ row }) => {
          return row?.original?.SaleDate;
        },
        Footer: (
          <Typography
            sx={{ height: "17px", lineHeight: "16px" }}
            color="black"
            fontWeight={500}
            fontSize="14px"
          >
            Total
          </Typography>
        ),
      },

      {
        Header: "Store Name",
        accessor: "StoreName",
      },

      {
        Header: "Gross Sales",
        accessor: "GrossSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "GrossSales"),
      },

      {
        Header: "GST",
        accessor: "GST",
        Cell: ({ row }) => {
          return "$" + row?.original?.GST?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "GST"),
      },

      {
        Header: "Net Sales",
        accessor: "NetSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "NetSales"),
      },

      {
        Header: "Cash Banked",
        accessor: "CashBanked",
        Cell: ({ row }) => {
          return "$" + row?.original?.CashBanked?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "CashBanked"),
      },

      {
        Header: "Sys Cash",
        accessor: "SysCash",
        Cell: ({ row }) => {
          return "$" + row?.original?.SysCash?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "SysCash"),
      },

      {
        Header: "Variance",
        accessor: "Variance",
        Cell: ({ row }) => {
          return "$" + row?.original?.Variance?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "Variance"),
      },

      {
        Header: "Eft POS",
        accessor: "EftPOS",
        Cell: ({ row }) => {
          return "$" + row?.original?.EftPOS?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "EftPOS"),
      },

      {
        Header: "Gross Acc Sales",
        accessor: "GrossAccSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossAccSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "GrossAccSales"),
      },

      {
        Header: "Transactions",
        accessor: "Transactions",
        Footer: ({ rows }) => findTotal(rows, "Transactions"),
      },

      {
        Header: "Gross ATV",
        accessor: "GrossATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossATV?.toFixed(2);
        },
        Footer: ({ rows }) => {
          const totalGrossSales = findTotal(rows, "GrossSales");
          const totalTransactions = findTotal(rows, "Transactions");
          const grossAtv =
            Number(totalGrossSales) / Number(totalTransactions) || 0;
          return grossAtv.toFixed(2);
        },
      },

      {
        Header: "Net ATV",
        accessor: "NetATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetATV?.toFixed(2);
        },
        Footer: ({ rows }) => {
          const totalNetSales = findTotal(rows, "NetSales");
          const totalTransactions = findTotal(rows, "Transactions");
          const netAtv = Number(totalNetSales) / Number(totalTransactions) || 0;
          return netAtv.toFixed(2);
        },
      },

      {
        Header: "Total Wastage Cost Ex GST",
        accessor: "TotalWastageCostExGST",
        Cell: ({ row }) => {
          return "$" + row?.original?.TotalWastageCostExGST?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "TotalWastageCostExGST"),
      },

      {
        Header: "Discount",
        accessor: "Discount",
        Cell: ({ row }) => {
          return "$" + row?.original?.Discount?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "Discount"),
      },

      {
        Header: "Net Refund",
        accessor: "NetRefund",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetRefund?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "NetRefund"),
      },

      {
        Header: "Gift Card Sales",
        accessor: "GiftCardSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GiftCardSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "GiftCardSales"),
      },

      {
        Header: "Loyalty Sales",
        accessor: "LoyaltySales",
        Cell: ({ row }) => {
          return "$" + row?.original?.LoyaltySales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "LoyaltySales"),
      },

      {
        Header: "CA Cash",
        accessor: "CACash",
        Cell: ({ row }) => {
          return "$" + row?.original?.CACash?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "CACash"),
      },

      {
        Header: "CA EFT",
        accessor: "CAEFT",
        Cell: ({ row }) => {
          return "$" + row?.original?.CAEFT?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "CAEFT"),
      },

      {
        Header: "CA Cheque",
        accessor: "CACheque",
        Cell: ({ row }) => {
          return "$" + row?.original?.CACheque?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "CACheque"),
      },
      {
        Header: "CA Bank Transfer",
        accessor: "CABankTransfer",
        Cell: ({ row }) => {
          return "$" + row?.original?.CABankTransfer?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "CABankTransfer"),
      },
      {
        Header: "Refund Deduction",
        accessor: "RefundDeduction",
        Cell: ({ row }) => {
          return "$" + row?.original?.RefundDeduction?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "RefundDeduction"),
      },
      {
        Header: "Ubereats Sales",
        accessor: "UbereatsSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.UbereatsSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "UbereatsSales"),
      },
      {
        Header: "Menulog Sales",
        accessor: "MenulogSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.MenulogSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "MenulogSales"),
      },
      {
        Header: "DD Sales",
        accessor: "DDSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.DDSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "DDSales"),
      },
      {
        Header: "Online Ordering Sales",
        accessor: "OnlineOrderingSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.OnlineOrderingSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "OnlineOrderingSales"),
      },
    ],
    []
  );
  const columnsNetsale = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "DateFormatted",
        Cell: ({ row }) => {
          return row?.original?.Date;
        },
        Footer: (
          <Typography
            sx={{ height: "17px", lineHeight: "16px" }}
            color="black"
            fontWeight={500}
            fontSize="14px"
          >
            Total
          </Typography>
        ),
      },

      {
        Header: "Week Day",
        accessor: "WeekNo",
        Cell: ({ row }) => {
          return row?.original?.WeekDay;
        },
      },

      {
        Header: "Store Name",
        accessor: "StoreName",
      },

      {
        Header: "Net Sales",
        accessor: "NetSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetSales?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "NetSales", true, 3),
      },

      {
        Header: "Prev Year Net Sales",
        accessor: "PrevYearNetSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.PrevYearNetSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "PrevYearNetSales"),
      },

      {
        Header: "Variance Sales Ex Tax(%)",
        accessor: "VarianceSalesExTax",
        Cell: ({ row }) => {
          return row?.original?.VarianceSalesExTax?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "VarianceSalesExTax", false, 3),
      },

      {
        Header: "Transactions",
        accessor: "Transactions",
        Footer: ({ rows }) => findTotal(rows, "Transactions"),
      },

      {
        Header: "Prev Year Transactions",
        accessor: "PrevYearTransactions",
        Footer: ({ rows }) => findTotal(rows, "PrevYearTransactions"),
      },

      {
        Header: "Variance Transactions(%)",
        accessor: "VarianceTransactions",
        Cell: ({ row }) => {
          return row?.original?.VarianceTransactions;
        },
        Footer: ({ rows }) => findSum(rows, "VarianceTransactions", false),
      },

      {
        Header: "ATV",
        accessor: "ATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.ATV?.toFixed(3);
        },
        Footer: ({ rows }) => {
          const totalNetSales = findTotal(rows, "NetSales");
          const totalTransactions = findTotal(rows, "Transactions");
          const atv = Number(totalNetSales) / Number(totalTransactions) || 0;
          return atv.toFixed(3);
        },
      },

      {
        Header: "ATV Prev Year",
        accessor: "ATVPrevYear",
        Cell: ({ row }) => {
          return "$" + row?.original?.ATVPrevYear?.toFixed(3);
        },
        Footer: ({ rows }) => {
          const totalNetSales = findTotal(rows, "PrevYearNetSales");
          const totalTransactions = findTotal(rows, "PrevYearTransactions");
          const atv = Number(totalNetSales) / Number(totalTransactions) || 0;
          return atv.toFixed(3);
        },
      },

      {
        Header: "Variance ATV(%)",
        accessor: "VarianceATV",
        Cell: ({ row }) => {
          return row?.original?.VarianceATV;
        },
        Footer: ({ rows }) => findSum(rows, "VarianceATV", false),
        disableSortBy: true,
      },
    ],
    []
  );
  const columnsStoresale = React.useMemo(
    () => [
      {
        Header: "Sale Date",
        accessor: "DateFormatted",
        Cell: ({ row }) => {
          return row?.original?.SaleDate;
        },
      },

      {
        Header: "Store Name",
        accessor: "StoreName",
      },

      {
        Header: "Transactions",
        accessor: "Transactions",
      },

      {
        Header: "Net Sales",
        accessor: "NetSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetSales?.toFixed(3);
        },
      },

      {
        Header: " Net ATV",
        accessor: "NetATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetATV?.toFixed(3);
        },
      },

      {
        Header: "Gross Sales",
        accessor: "GrossSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossSales?.toFixed(3);
        },
      },

      {
        Header: "Gross ATV ",
        accessor: "GrossATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossATV?.toFixed(3);
        },
      },
    ],
    []
  );
  const columnsStoreMonthlysale = React.useMemo(
    () => [
      {
        Header: "Sale Date",
        accessor: "SaleDate",
        Cell: ({ row }) => {
          return row?.original?.SaleDate;
        },
      },

      {
        Header: "Store Name",
        accessor: "StoreName",
      },

      {
        Header: "Transactions",
        accessor: "Transactions",
      },

      {
        Header: "Net Sales",
        accessor: "NetSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetSales?.toFixed(3);
        },
      },

      {
        Header: "Net ATV",
        accessor: "NetATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetATV?.toFixed(3);
        },
      },

      {
        Header: "Gross Sales",
        accessor: "GrossSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossSales?.toFixed(3);
        },
      },

      {
        Header: "Gross ATV",
        accessor: "GrossATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossATV?.toFixed(3);
        },
      },
    ],
    []
  );

  const columnStaff = React.useMemo(
    () => [
      {
        Header: "Staff",
        accessor: "Staff",
        Footer: (
          <Typography
            sx={{ height: "17px", lineHeight: "16px" }}
            color="black"
            fontWeight={500}
            fontSize="14px"
          >
            Total
          </Typography>
        ),
      },

      {
        Header: "Transactions",
        accessor: "Transactions",
        Footer: ({ rows }) => findTotal(rows, "Transactions"),
      },
      {
        Header: "Gross Sales",
        accessor: "GrossSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "GrossSales"),
      },

      {
        Header: "Gross ATV",
        accessor: "GrossATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossATV?.toFixed(2);
        },
        Footer: ({ rows }) => {
          const totalGrossSales = findTotal(rows, "GrossSales");
          const totalTransactions = findTotal(rows, "Transactions");
          const grossAtv =
            Number(totalGrossSales) / Number(totalTransactions) || 0;
          return grossAtv.toFixed(2);
        },
      },

      {
        Header: "Net Sales",
        accessor: "NetSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "NetSales"),
      },

      {
        Header: "Net ATV",
        accessor: "NetATV",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetATV?.toFixed(2);
        },
        Footer: ({ rows }) => {
          const totalNetSales = findTotal(rows, "NetSales");
          const totalTransactions = findTotal(rows, "Transactions");
          const netAtv = Number(totalNetSales) / Number(totalTransactions) || 0;
          return netAtv.toFixed(2);
        },
      },

      {
        Header: "Cleared Transactions",
        accessor: "ClearedTransactions",
        Footer: ({ rows }) => findTotal(rows, "ClearedTransactions"),
      },

      {
        Header: "Cleared Value",
        accessor: "ClearedValue",
        Cell: ({ row }) => {
          return "$" + row?.original?.ClearedValue?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "ClearedValue"),
      },

      {
        Header: "Discounts",
        accessor: "Discounts",
        Footer: ({ rows }) => findTotal(rows, "Discounts"),
      },

      {
        Header: "Discounts Value",
        accessor: "DiscountsValue",
        Cell: ({ row }) => {
          return "$" + row?.original?.DiscountsValue?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "DiscountsValue"),
      },

      {
        Header: "No Sales Items",
        accessor: "NoSalesItems",
        Footer: ({ rows }) => findTotal(rows, "NoSalesItems"),
      },
    ],
    []
  );
  const columnWastageanalysis = React.useMemo(
    () => [
      {
        Header: "Account/Type",
        accessor: "Account/Type",
        Footer: (
          <Typography
            sx={{ height: "17px", lineHeight: "16px" }}
            color="black"
            fontWeight={500}
            fontSize="14px"
          >
            Total
          </Typography>
        ),
      },

      {
        Header: "Item Class/Category",
        accessor: "ItemClass/Category",
      },

      {
        Header: "Item Name",
        accessor: "ItemName",
      },

      {
        Header: "RRP",
        accessor: "RRP",
        Cell: ({ row }) => {
          return "$" + row?.original?.RRP?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "RRP"),
      },

      {
        Header: "Cost Ex GST",
        accessor: "CostExGST",
        Cell: ({ row }) => {
          return "$" + row?.original?.CostExGST?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "CostExGST"),
      },

      {
        Header: "Qty",
        accessor: "Qty",
        Footer: ({ rows }) => findSum(rows, "Qty", false , 0),

      },

      {
        Header: "Total Cost Ex GST",
        accessor: "TotalCostExGST",
        Cell: ({ row }) => {
          return "$" + row?.original?.TotalCostExGST?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "TotalCostExGST"),

      },

      {
        Header: "Total RRP",
        accessor: "TotalRRP",
        Cell: ({ row }) => {
          return "$" + row?.original?.TotalRRP?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "TotalRRP"),
      },
    ],
    []
  );
  const columnwastagedayanalysiswithproduct_qty = React.useMemo(
    () => [
      {
        Header: "Account",
        accessor: "Account",
        Footer: (
          <Typography
            sx={{ height: "17px", lineHeight: "16px" }}
            color="black"
            fontWeight={500}
            fontSize="14px"
          >
            Total
          </Typography>
        ),
      },

      {
        Header: "ItemClass",
        accessor: "ItemClass",
      },

      {
        Header: "Week Day",
        accessor: "WeekNo",
        Cell: ({ row }) => {
          return row?.original?.WeekDay;
        },
      },

      {
        Header: "Transaction Date",
        accessor: "DateFormatted",
        Cell: ({ row }) => {
          return row?.original?.TransactionDate;
        },
      },

      {
        Header: "Item Name",
        accessor: "ItemName",
      },

      {
        Header: "Qty",
        accessor: "Qty",
        Footer: ({ rows }) => findSum(rows, "Qty", false , 0),
      },

      {
        Header: "Cost Ex GST",
        accessor: "CostExGST",
        Cell: ({ row }) => {
          return "$" + row?.original?.CostExGST?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "CostExGST"),

      },

      {
        Header: "Total Cost Ex GST",
        accessor: "TotalCostExGST",
        Cell: ({ row }) => {
          return "$" + row?.original?.TotalCostExGST?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "TotalCostExGST"),
      },

      {
        Header: "RRP",
        accessor: "RRP",
        Cell: ({ row }) => {
          return "$" + row?.original?.RRP?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "RRP"),
      },

      {
        Header: "Total RRP",
        accessor: "TotalRRP",
        Cell: ({ row }) => {
          return "$" + row?.original?.TotalRRP?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "TotalRRP"),
      },
    ],
    []
  );

  const columnNcogsAnalysis = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "Store",
        Cell: ({ row }) => {
          return row?.original?.Store;
        },
        Footer: (
          <Typography
            sx={{ height: "17px", lineHeight: "16px" }}
            color="black"
            fontWeight={500}
            fontSize="14px"
          >
            Total
          </Typography>
        ),
      },

      {
        Header: "Date",
        accessor: "DateFormatted",
        Cell: ({ row }) => {
          return row?.original?.Date;
        },
      },

      {
        Header: "Product",
        accessor: "Product",
      },

      {
        Header: "RRP",
        accessor: "RRP",
        Cell: ({ row }) => {
          return "$" + row?.original?.RRP?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "RRP"),
      },
      {
        Header: "Product Quantity",
        accessor: "ProductQuantity",
        Cell: ({ row }) => {
          return row?.original?.ProductQuantity;
        },
        Footer: ({ rows }) => findTotal(rows, "ProductQuantity"),
      },
      {
        Header: "Average Price",
        accessor: "AvgPrice",
        Cell: ({ row }) => {
          return "$" + row?.original?.AvgPrice?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "AvgPrice"),
      },
      {
        Header: "Gross Sales",
        accessor: "GrossSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossSales?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "GrossSales"),
      },

      {
        Header: "GST",
        accessor: "GST",
        Cell: ({ row }) => {
          return "$" + row?.original?.GST?.toFixed(2);
        },
        Footer: ({ rows }) => findSum(rows, "GST"),
      },
      {
        Header: "Net Sales",
        accessor: "NetSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.NetSales?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "NetSales", true, 3),
      },
      {
        Header: "UnitCost",
        accessor: "UnitCost",
        Cell: ({ row }) => {
          return "$" + row?.original?.UnitCost?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "UnitCost", true, 3),
      },
      {
        Header: "TotalCost",
        accessor: "TotalCost",
        Cell: ({ row }) => {
          return "$" + row?.original?.TotalCost?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "TotalCost", true, 3),
      },
      {
        Header: "SCOGS(%)",
        accessor: "SCOGS",
        Cell: ({ row }) => {
          return row?.original?.SCOGS?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "SCOGS", false, 3),
      },
      {
        Header: "Wastage Quantity",
        accessor: "WastageQuantity",
        Footer: ({ rows }) => findTotal(rows, "WastageQuantity"),
      },
      {
        Header: "Wastage Cost",
        accessor: "WastageCost",
        Cell: ({ row }) => {
          return "$" + row?.original?.WastageCost?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "WastageCost", true, 3),
      },
      {
        Header: "WCOGS(%)",
        accessor: "WastageCogs",
        Cell: ({ row }) => {
          return row?.original?.WastageCogs?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "WastageCogs", false, 3),
      },
      {
        Header: "NCOGS(%)",
        accessor: "NCOGS",
        Cell: ({ row }) => {
          return row?.original?.NCOGS?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "NCOGS", false, 3),
      },
      {
        Header: "Profit Margin",
        accessor: "ProfitMargin",
        Cell: ({ row }) => {
          return "$" + row?.original?.ProfitMargin?.toFixed(3);
        },
        Footer: ({ rows }) => findSum(rows, "ProfitMargin", true, 3),
      },
    ],
    []
  );

  const columnRoyaltyProductAnalysis = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: "Product",
      },
      {
        Header: "Date",
        accessor: "DateFormatted",
        Cell: ({ row }) => {
          return row?.original?.Date;
        },
      },
      {
        Header: "Sale Price",
        accessor: "SalePrice",
        Cell: ({ row }) => {
          return "$" + row?.original?.SalePrice?.toFixed(2);
        },
      },
      {
        Header: "Quantity",
        accessor: "Quantity",
      },
      {
        Header: "Gross Sales",
        accessor: "GrossSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GrossSales?.toFixed(2);
        },
      },
      {
        Header: "GST",
        accessor: "GST",
        Cell: ({ row }) => {
          return "$" + row?.original?.GST?.toFixed(2);
        },
      },
      {
        Header: "Royalty(%)",
        accessor: "Royalty",
        Cell: ({ row }) => {
          return row?.original?.Royalty?.toFixed(2);
        },
      },
    ],
    []
  );
  const columnRoyaltyStoreAnalysis = React.useMemo(
    () => [
      {
        Header: "Store",
        accessor: "Store",
      },
      {
        Header: "Date",
        accessor: "DateFormatted",
        Cell: ({ row }) => {
          return row?.original?.Date;
        },
      },

      {
        Header: "Total Transactions",
        accessor: "TotalTransactions",
        Cell: ({ row }) => {
          return row?.original?.TotalTransactions;
        },
      },
      {
        Header: "Gross Sales",
        accessor: "GossSales",
        Cell: ({ row }) => {
          return "$" + row?.original?.GossSales?.toFixed(3);
        },
      },
      {
        Header: "GST",
        accessor: "GST",
        Cell: ({ row }) => {
          return "$" + row?.original?.GST?.toFixed(2);
        },
      },
      {
        Header: "Loyalty Payment",
        accessor: "LoyalityPayment",
        Cell: ({ row }) => {
          return "$" + row?.original?.LoyalityPayment?.toFixed(2);
        },
      },
      {
        Header: "Gift Card Sold",
        accessor: "GiftCardSold",
        Cell: ({ row }) => {
          return "$" + row?.original?.GiftCardSold?.toFixed(2);
        },
      },
      {
        Header: "Gift Card Sold For Free",
        accessor: "GiftCardSoldForFree",
        Cell: ({ row }) => {
          return "$" + row?.original?.GiftCardSoldForFree?.toFixed(2);
        },
      },

      {
        Header: "Royalty(%)",
        accessor: "Royalty",
        Cell: ({ row }) => {
          return row?.original?.Royalty?.toFixed(3);
        },
      },
    ],
    []
  );
  const columnTypes = (columnTypes) => {
    switch (columnTypes) {
      case 1:
        return columnsEod;
      case 2:
        return columnsNetsale;
      case 3:
        return columnsStoresale;
      case 4:
        return columnsStoreMonthlysale;
      case 5:
        return columnStaff;
      case 6:
        return columnWastageanalysis;
      case 8:
        return columnwastagedayanalysiswithproduct_qty;
      case 9:
        return columnNcogsAnalysis;
      case 10:
        return columnRoyaltyStoreAnalysis;
      case 11:
        return columnRoyaltyProductAnalysis;
      default:
        return [];
    }
  };
  return (
    <>
      <ContentLayout
        title="Reports"
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={state.isLoading || !filters.type}
            >
              Generate
            </Button>
          </>
        }
      >
        {false ? (
          <Loader />
        ) : (
          <>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 1,
              }}
            >
              <Card sx={{ marginBottom: "20px" }}>
                {validator.current.purgeFields()}

                <Stack p="24px 24px 16px">
                  <>
                    <Stack marginTop={3} direction="row" gap={2} width="100%">
                      <InputControl
                        type="select"
                        name="type"
                        label="Type of Report"
                        required
                        value={filters?.type}
                        optionValue={
                          isStoreAdmin
                            ? globalData?.reports?.store_admin_type ?? []
                            : globalData?.reports?.type ?? []
                        }
                        width="100%"
                        onChange={onChangeFilter}
                        error={validator.current.message(
                          "Type of Report",
                          filters.type,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "Type of Report",
                          filters.type,
                          "required"
                        )}
                      />

                      <Stack width="100%">
                        <InputControl
                          type="datePicker"
                          name="start_date"
                          label="Start Date"
                          disableFuture
                          inputFormat="dd-MM-yyyy"
                          sx={{
                            width: "100%",
                          }}
                          value={filters?.start_date}
                          onChange={(e) => onChangeFilter(e, "start_date")}
                        />
                        {!filters?.start_date && (
                          <Stack direction="row" marginTop={2}>
                            <img alt="edit" src="/icons/icon_not.svg" />
                            <Typography
                              color="#636363"
                              fontWeight={400}
                              fontSize="14px"
                              ml={1}
                            >
                              If left empty, current month first date will be
                              taken.
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                      <Stack width="100%">
                        <InputControl
                          type="datePicker"
                          name="end_date"
                          label="End Date"
                          disableFuture
                          inputFormat="dd-MM-yyyy"
                          sx={{
                            width: "100%",
                          }}
                          value={filters?.end_date}
                          onChange={(e) => onChangeFilter(e, "end_date")}
                          error={
                            filters?.start_date &&
                            validator.current.message(
                              "End Date",
                              {
                                startTime: filters?.start_date,
                                endTime: filters?.end_date,
                              },
                              "endDate"
                            )
                          }
                          helperText={
                            filters.start_date &&
                            validator.current.message(
                              "End Date",
                              {
                                startTime: filters.start_date,
                                endTime: filters.end_date,
                              },
                              "endDate"
                            )
                          }
                        />
                        {!filters?.end_date && (
                          <Stack direction="row" marginTop={2}>
                            <img alt="edit" src="/icons/icon_not.svg" />
                            <Typography
                              color="#636363"
                              fontWeight={400}
                              fontSize="14px"
                              ml={1}
                            >
                              If left empty, current date will be taken.
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                    {!isStoreAdmin && filters.type !== 11 && (
                      <>
                        <Stack direction="column" spacing={2} marginTop={2}>
                          <Stack>
                            <>
                              <InputControl
                                type="dropdown"
                                name="store_id"
                                label="Store"
                                multiple={true}
                                options={arraySort(storeOptions || [], "label")}
                                value={filters?.store_id}
                                disableCloseOnSelect
                                disableClearable={true}
                                onChange={(e, v) =>
                                  onChangeFilter({
                                    target: {
                                      name: "store_id",
                                      value: v,
                                    },
                                  })
                                }
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                renderTags={(value, getTagProps) =>
                                  value?.map((option, index) => <></>)
                                }
                                renderOption={(props, option, { selected }) => (
                                  <>
                                    <li {...props} key={option.value}>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.label}
                                    </li>
                                  </>
                                )}
                                filterOptions={filterOptions}
                                sx={{ width: "100%" }}
                                componentsProps={{
                                  paper: {
                                    sx: {
                                      width: "100%",
                                      minWidth: 250,
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Store"
                                    placeholder="Store"
                                  />
                                )}
                              />

                              {filters?.store_id?.length === 0 && (
                                <Stack direction="row" marginTop={2}>
                                  <img alt="edit" src="/icons/icon_not.svg" />
                                  <Typography
                                    color="#636363"
                                    fontWeight={400}
                                    fontSize="14px"
                                    ml={1}
                                  >
                                    If not selected all stores are applicable.
                                  </Typography>
                                </Stack>
                              )}
                            </>

                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                flexWrap: "wrap",
                                gap: "12px",
                                marginTop: 2,
                              }}
                            >
                              {filters?.store_id?.map((data, index) => (
                                <Chip
                                  key={index}
                                  label={
                                    <Typography variant="body2" fontSize="14px">
                                      {data.label}
                                    </Typography>
                                  }
                                  onDelete={() => {
                                    onDeleteStoreSelect(data);
                                  }}
                                  sx={{
                                    backgroundColor: "#F2F2F2",
                                    height: "30px",
                                    "& .MuiChip-deleteIcon": {
                                      color: "#CCCCCC",
                                    },
                                  }}
                                />
                              ))}
                            </Stack>
                          </Stack>
                        </Stack>
                        <Box marginTop={2}>
                          <Divider />
                        </Box>
                      </>
                    )}
                  </>

                  {filters?.type !== 4 && (
                    <>
                      <Stack direction="column" spacing={2} marginTop={2}>
                        <Stack>
                          <>
                            <InputControl
                              type="dropdown"
                              name="day"
                              label="Day"
                              multiple={true}
                              options={globalData?.reports?.day ?? []}
                              value={filters?.day}
                              disableCloseOnSelect
                              disableClearable={true}
                              onChange={(e, v) =>
                                onChangeFilter({
                                  target: {
                                    name: "day",
                                    value: v,
                                  },
                                })
                              }
                              getOptionLabel={(option) => option?.label}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value?.value
                              }
                              renderTags={(value, getTagProps) =>
                                value?.map((option, index) => <></>)
                              }
                              renderOption={(props, option, { selected }) => (
                                <>
                                  <li {...props} key={option.value}>
                                    <Checkbox
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option?.label}
                                  </li>
                                </>
                              )}
                              filterOptions={filterOptions}
                              sx={{ width: "100%" }}
                              componentsProps={{
                                paper: {
                                  sx: {
                                    width: "100%",
                                    minWidth: 250,
                                  },
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Day"
                                  placeholder="Day"
                                />
                              )}
                            />
                          </>
                          <Stack
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              flexWrap: "wrap",
                              gap: "12px",
                              marginTop: 2,
                            }}
                          >
                            {filters?.day?.map((data, index) => (
                              <Chip
                                key={index}
                                label={
                                  <Typography variant="body2" fontSize="14px">
                                    {data.label}
                                  </Typography>
                                }
                                onDelete={() => {
                                  onDeleteDaySelect(data);
                                }}
                                sx={{
                                  backgroundColor: "#F2F2F2",
                                  height: "30px",
                                  "& .MuiChip-deleteIcon": {
                                    color: "#CCCCCC",
                                  },
                                }}
                              />
                            ))}
                          </Stack>
                        </Stack>
                      </Stack>

                      <Box marginTop={3}>
                        <Divider />
                      </Box>
                    </>
                  )}
                  <Stack>
                    <Stack direction="row" marginTop={2} width="100%">
                      <Button variant="text" onClick={onClearFilter}>
                        Clear All
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              {state?.isLoading ? (
                <Loader />
              ) : (
                <CustomTable
                  drawerOpen={columnOptions}
                  drawerOnClose={() => toggleColumns()}
                  defaultFooter={
                    state.columnType === 1 ||
                      state.columnType === 5 ||
                      state.columnType === 9 ||
                      state.columnType === 2 ||
                      state.columnType === 8 ||
                      state.columnType === 6
                      ? true
                      : false
                  }
                  filterContent={
                    <>
                      <Button
                        type="button"
                        size="medium"
                        variant="text"
                        sx={{ minWidth: "85px" }}
                        disabled={
                          !state.columnType ||
                          !state?.generateData?.data?.length > 0
                        }
                        onClick={handleExportTableList}
                      >
                        <img
                          alt="export"
                          className={
                            (!state?.columnType ||
                              !state?.generateData?.data?.length > 0) &&
                            "img-disabled "
                          }
                          src="/icons/ic_export.svg"
                          style={{
                            marginRight: "4px",
                          }}
                        />
                        Export
                      </Button>
                      <Button
                        type="button"
                        size="medium"
                        sx={{ minWidth: "155px" }}
                        variant="text"
                        onClick={() => toggleColumns()}
                      >
                        <img
                          alt="tools"
                          src="/icons/ic_Tools.svg"
                          style={{
                            marginRight: "4px",
                          }}
                        />
                        Column Option
                      </Button>
                    </>
                  }
                  columns={columnTypes(state?.columnType)}
                  data={
                    state?.generateData?.data ? state?.generateData?.data : []
                  }
                  defaultSearch={true}
                />
              )}
            </Box>
          </>
        )}
      </ContentLayout>
    </>
  );
};
