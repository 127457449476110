import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { API } from "../../utils/api";
import { useChannel } from "@ably-labs/react-hooks";
import { AuthContext } from "../../store/AuthProvider";
import {
  deleteAllNotifications,
  deleteNotificationById,
  getNotifications,
} from "../api";
import { errorMessage, successMessage } from "../../utils";

export const useAppScope = () => {
  const { auth } = useContext(AuthContext);
  const [appState, setAppState] = useImmer({
    isInitialLoad: true,
    isIconButton: false,
    currentTabs: {
      profile: "1",
      stateandSuburb: "1",
      rolesAndPermission: "1",
      rolesAndPermissionList: "1",
      customers: "1",
      taxGroupsAndTaxes: "1",
      adminUsers: "1",
      productDetails: "1",
      availabilityGroupDetails: "1",
      storeDetails: "1",
      favourites: "1",
      CouponseDetails: "1",
      birthdayCoupons: "1",
      giftCardGroupDetails: "1",
      giftCardList: "1",
      loyaltyList: "1",
      discountGroupDetails: "1",
      loyaltyGroupDetails: "1",
      categoryDetails: "1",
      clientAccDetails: "1",
      loyaltyCardTypeDetails: "1",
      menulog: "1",
      ubereats: "1",
      doordash: "1",
      onlineOrdering: "1",
      dashboard: "1",
      xero: "1",
      myob: "1",
      generalTab: "1",
      order: "1",
    },
    globalData: {},
    notificationData: [],
    notificationMeta: {
      isAllbusy: false,
      isbusy: null,
    },
  });

  useEffect(() => {
    if (!appState.isInitialLoad) {
      sessionStorage.setItem(
        "current-tabs",
        JSON.stringify(appState.currentTabs)
      );
    }
  }, [appState.currentTabs, appState.isInitialLoad]);

  const getGlobalData = async () => {
    try {
      const {
        data: { data },
      } = await API.get(`boot`);
      setAppState((draft) => {
        draft.globalData = data;
      });
    } catch (err) {}
  };
  const getNotificationData = async () => {
    try {
      const { data } = await getNotifications();
      setAppState((draft) => {
        draft.notificationData = data;
      });
    } catch (err) {}
  };

  const deleteNotification = async (itemId) => {
    try {
      setAppState((draft) => {
        draft.notificationMeta.isbusy = itemId;
      });
      const data = await deleteNotificationById(itemId);
      if (data.success) {
        successMessage("Notification removed");
        setAppState((draft) => {
          draft.notificationData = draft.notificationData.filter(
            (item) => item.id !== itemId
          );
          draft.notificationMeta.isbusy = null;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const deleteAllNotification = async () => {
    try {
      setAppState((draft) => {
        draft.notificationMeta.isAllbusy = true;
      });
      const data = await deleteAllNotifications();
      if (data.success) {
        successMessage("Notifications removed");
        setAppState((draft) => {
          draft.notificationMeta.isAllbusy = false;
          draft.notificationData = [];
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  useEffect(() => {
    setAppState((draft) => {
      draft.isInitialLoad = false;
    });
    getGlobalData();
    const currentTabs = JSON.parse(sessionStorage.getItem("current-tabs"));
    if (currentTabs) {
      setAppState((draft) => {
        draft.currentTabs = currentTabs;
      });
    }
  }, []);
  useEffect(() => {
    if (auth?.data?.id) {
      getNotificationData();
    }
  }, [auth?.data?.id]);
  const [channel, ably] = useChannel(
    `public:user-${auth?.data?.id}`,
    (message) => {
      if (message.name === "xero-myob") {
        setAppState((draft) => {
          draft.notificationData = [message.data, ...draft.notificationData];
          draft.isIconButton = true;
        });
      }
    }
  );

  return { appState, setAppState, deleteNotification, deleteAllNotification };
};
