import * as React from "react";
import { Button, Chip, Box, Stack, Radio } from "@mui/material";
import {
  CustomDetailsList,
  Loader,
  usePermission,
  CustomTable,
  CustomeDialog,
  DeleteModal,
} from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import { sortItems } from "../../../utils";
import { ProductSizeDialog } from "./ProductSizeDialog";
import AddIcon from "@mui/icons-material/Add";
import SimpleReactValidator from "simple-react-validator";

export const GeneralList = (props) => {
  const {
    data,
    varients,
    globalData,
    HandleVarientChange,
    handleCreateAndUpdatevarient,
    handleModal,
    handleDeleteVariant,
    handleDefaultVariant,
  } = props;
  const { details, isLoading } = data;
  const isEdit = usePermission("product-modify");
  const navigate = useNavigate();
  const { id } = useParams();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const onSubmit = () => {
    if (validator.current.allValid()) {
      varients.id
        ? handleCreateAndUpdatevarient("edit", id)
        : handleCreateAndUpdatevarient("add", id);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const columns = [
    {
      title: "Alias",
      accessor: "alias",
    },
    {
      title: "Colour Code",
      accessor: (row) => {
        return (
          <Stack direction="row" gap={2}>
            {row.colour}
            <Box
              sx={{
                width: 25,
                height: 25,
                mr: "20px",
                borderRadius: "12px",
                backgroundColor: row.colour,
              }}
            />
          </Stack>
        );
      },
    },
    {
      title: "Short Description",
      accessor: "short_description",
    },
    {
      title: "Product Type",
      accessor: "product_type",
    },
    {
      title: "Category",
      accessor: (row) => {
        return row.category.name;
      },
    },
    {
      title: "Allergens & Dietary",
      accessor: (row) => {
        return row.modifier;
      },
    },
    {
      title: "Product Tags",
      hidden: (row) => {
        return row.product_type === "modifier" ? true : false;
      },
      accessor: (row) => {
        return (
          <Box>
            {row.tags.map((data, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    mr: 1,
                    borderRadius: "12px",
                    background: "rgba(0, 0, 0, 0.16)",
                  }}
                  label={data}
                  variant="filled"
                  size="small"
                />
              );
            })}
          </Box>
        );
      },
    },
    {
      title: "Marketing Tags",
      accessor: (row) => {
        return (
          <Box>
            {row.marketing_tags.map((data, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    mr: 1,
                    borderRadius: "12px",
                    background: "rgba(0, 0, 0, 0.16)",
                  }}
                  label={data}
                  variant="filled"
                  size="small"
                />
              );
            })}
          </Box>
        );
      },
    },
    {
      title: "PLU Code",
      accessor: "plu",
    },
    {
      title: "Old PLU Code",
      accessor: "old_plu",
    },
    {
      title: "Tax Group",
      accessor: (row) => {
        return row.tax_group?.name;
      },
    },
    {
      title: "Bump Screen Tags",
      accessor: (row) => {
        return (
          <Box>
            {row.bump_screen_tags.map((data, index) => {
              return (
                <Chip
                  key="index"
                  sx={{
                    mr: 1,
                    borderRadius: "12px",
                    background: "rgba(0, 0, 0, 0.16)",
                  }}
                  label={data}
                  variant="filled"
                  size="small"
                />
              );
            })}
          </Box>
        );
      },
      hidden: (row) => {
        return row.product_type === "modifier" ? true : false;
      },
    },
    {
      title: "Loyalty Cards",
      accessor: (row) => {
        const findLoyaltyDetails = (data) => {
          return globalData.loyalty_cards.loyalty_card_types.find(
            (cardDetails) => cardDetails.value === data
          );
        };

        return (
          <Box>
            {row.loyalty_card_type_id?.map((data, index) => {
              const details = findLoyaltyDetails(data);

              return (
                <Chip
                  key={index}
                  sx={{
                    mr: 1,
                    borderRadius: "12px",
                    background: "rgba(0, 0, 0, 0.16)",
                  }}
                  label={details?.label ?? ""}
                  variant="filled"
                  size="small"
                />
              );
            })}
          </Box>
        );
      },
    },
  ];

  const columns2 = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "PLU Code",
        accessor: "plu",
        disableSortBy: true,
      },
      {
        Header: "Old PLU Code",
        accessor: "old_plu",
        disableSortBy: true,
      },
      {
        Header: "Default",
        id: "defualt",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <Radio
              checked={row.default_variant ? true : false}
              value={row.id}
              onClick={() => {
                if (!row.default_variant) {
                  handleDefaultVariant(row.id);
                }
              }}
              name="radio-buttons"
              sx={{
                p: "3.5px",
              }}
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              editAction={() => handleModal("varient-edit", true, row.id)}
              deleteAction={() => handleModal("varient-delete", true, row.id)}
            />
          );
        },
      },
    ],
    [details]
  );

  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons">
        <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        <img alt="edit" onClick={editAction} src="/icons/ic_edit.svg" />
      </div>
    );
  };

  const dataColumns =
    details?.product_type === "bundle"
      ? columns.filter((v) => v.title !== "Bump Screen Tags")
      : columns;
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CustomDetailsList
            columns={dataColumns}
            data={details}
            title="General"
            headerRightContent={
              <>
                {isEdit && (
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => navigate(`/products/edit/${id}`)}
                    disabled={["gift_card", "delivery"].includes(
                      data.details?.product_type
                    )}
                  >
                    Edit
                  </Button>
                )}
              </>
            }
          />

          {details.product_type !== "modifier" && (
            <CustomTable
              columns={columns2}
              data={details.variants}
              searchBar={false}
              tableTitle="Product Variants"
              rowsSelection={false}
              pagination={false}
              headerRightContent={
                <>
                  <Button
                    type="button"
                    size="medium"
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => handleModal("varient-add", true)}
                    disabled={["gift_card", "delivery"].includes(
                      data.details?.product_type
                    )}
                  >
                    Create
                  </Button>
                </>
              }
            />
          )}
          {/* <Card sx={{ p: "32px 24px", mb: "24px" }}>

            <Stack>
              <Typography variant="subtitle1" fontWeight={600}>
                Dynamic Link
              </Typography>
            </Stack>
            <Stack mt="24px">
              <Button variant="contained" sx={{ width: "fit-content" }}>Create Dynamic Link</Button>
            </Stack>
          </Card> */}
          <CustomeDialog
            open={varients.isOpen}
            handleClose={() => handleModal("varient-add", false)}
            PaperProps={{
              sx: { width: "423px", height: "419px", p: "16px" },
            }}
            title="Product Variants"
            content={
              <ProductSizeDialog
                HandleVarientChange={HandleVarientChange}
                data={varients.data}
                onSubmit={onSubmit}
                validator={validator}
                variants={details.variants}
                isBusy={varients.isBusy}
              />
            }
          />
          <DeleteModal
            open={varients.isDelete}
            handleClose={() => handleModal("varient-delete", false, null)}
            onConfirm={handleDeleteVariant}
          />
        </>
      )}
    </>
  );
};
