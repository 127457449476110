import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { errorMessage, successMessage } from "../../../utils";
import {
  createCOA,
  deleteCOAbyId,
  getCOADetails,
  getCOAList,
  getCOAParentList,
  updateCOA,
} from "../api";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { useDebounce } from "use-debounce";

export const useCOA = ({ isList, isEdit, isAdd }) => {
  const navigate = useNavigate();

  const [state, setState] = useImmer({
    isLoading: true,
    isOpen: false,
    selectedID: null,
    dataList: [],
    filteredList: [],
    parentList: [],
    isSaveButton: false,
    initialLoad: true,
    updateDetails: {
      name: "",
      code: "",
      parent_id: null,
    },
    filters: {
      isSearching: false,
      searchText: "",
    },
  });

    const [debouncedText] = useDebounce(state.filters.searchText, 1000);

  useEffect(() => {
    if (isEdit) {
      handleCOADetails(isEdit);
    }
  }, [isEdit]);
  useEffect(() => {
    if (isAdd) {
      handleCOAParentList();
    }
  }, [isAdd]);
  useEffect(() => {
    if (isList) {
      handleGetCOAList();
    }
  }, [isList]);

  const handleModal = (type, state, data) => {
    switch (type) {
      case "coa-delete":
        setState((draft) => {
          draft.isOpen = state;
          draft.selectedID = data;
        });
        break;
    }
  };

  const handleGetCOAList = async () => {
    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.initialLoad = false;
      });
      const res = await getCOAList();
      if (res.success) {
        setState((draft) => {
          draft.dataList = res.data;
          draft.filteredList = res.data;
          draft.isLoading = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.updateDetails[name] = value;
    });
  };

  const handleCOADetails = async (id) => {
    try {
      const res = await getCOADetails(id);
      if (res.success) {
        setState((draft) => {
          draft.updateDetails = res.data;
          draft.isLoading = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleCOAParentList = async () => {
    try {
      const res = await getCOAParentList();
      if (res.success) {
        setState((draft) => {
          draft.parentList = res.data.map((v) => {
            return { value: v.id, label: v.name };
          });
        });
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  const onSaveCOA = async () => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const res = await createCOA(state.updateDetails);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButton = false;
        });
        navigate("/coa");
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const onUpdateCOA = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButton = true;
      });
      const res = await updateCOA(id, state.updateDetails);
      if (res.success) {
        successMessage(res.message);
        navigate(`/coa`);
        setState((draft) => {
          draft.isSaveButton = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButton = false;
      });
    }
  };

  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.searchText = "";
    });
  };

  const handleDeleteCOA = async () => {
    try {
      const res = await deleteCOAbyId(state.selectedID);
      if (res.success) {
        successMessage(res.message);
        handleModal("coa-delete", false, null);
        handleGetCOAList();
      } else {
        errorMessage(res.message);
        handleModal("coa-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleCOASearch = (e) => {
    const { value } = e.target;
    setState((draft) => {
      draft.filters.searchText = value;
    });
    const Search = (data, text) => {
      text = text.toLowerCase();
      return data.filter((val, i) => {
        if (val.name.toString().toLowerCase().indexOf(text) > -1) {
          return true;
        } else if (val.subData.length > 0) {
          const sub = Search(val.subData, text);
          return sub.length > 0 ? true : false;
        }
      });
    };

    const result = Search(state.dataList, value);
    setState((draft) => {
      draft.filteredList = result;
    });
  };

  return {
    state,
    handleInputChange,
    onSaveCOA,
    onUpdateCOA,
    handleModal,
    handleDeleteCOA,
    onSeachClear,
    handleCOASearch,
  };
};
