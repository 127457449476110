import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import {
  createCategory,
  getCategory,
  getCategoryDetailsById,
  editCategory,
  deleteCategoryById,
  changeCategoryOrder,
  getCategoryProducts,
  changeCategoryProductOrder,
} from "../api/CategoryApi";
import { useDebounce } from "use-debounce";
import { usePermission, useTabs } from "../../../shared";
import qs from "qs";

export const useCategory = ({ isCategory, categoryId, isProduct }) => {
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const { setCurentTab } = useTabs();
  const { categoryDetails: currentTab } = appState.currentTabs;
  const isEditable = usePermission("category-modify");
  const isDeletable = usePermission("category-delete");
  const isCreateVisible = usePermission("category-create");
  const isVisible = usePermission("category-view");
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    id: null,
    initialLoad: true,
    isSaveButtonBusy: false,
    filters: {
      isSearching: false,
      searchText: "",
      status: "",
    },
    category: [],
    iconFile: "",
    name: "",
    data: {
      id: "",
      name: "",
      slug: "",
      description: "",
      icon: "",
      color: "#f2f2f2",
      display_order: "",
      display_name: "",
      report_category_name: "",
      report_class_name: "",
      status: 1,
      created_at: "",
      updated_at: "",
      product_count: "",
      show_in_list: 0,
    },
    product: {
      isChanged: false,
      isBusy: false,
      searchText: null,
      products: [],
    },
    httpStatusCodes: {
      details: "",
    },
  });

  console.log(state.data);

  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  useEffect(() => {
    if (isCategory) {
      getCategorys();
    }
  }, [isCategory]);

  const getCategorys = async (search = null, status = "") => {
    let params = {};

    if (search) {
      params = { ...params, search };
    }
    if (status !== "") {
      params = { ...params, status };
    }

    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });

      const res = await getCategory(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.category = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      const { status } = state.filters;
      getCategorys(debouncedText, status);
    }
  }, [debouncedText]);
  const onSaveCategory = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.isSaveButtonBusy = true;
      });
      let formData = new FormData();
      Object.keys(state.data).map((key) => {
        if (state.data[key] !== "") {
          formData.append(key, state.data[key]);
        }
      });
      !state.iconFile
        ? formData.delete("icon")
        : formData.append("icon", state.iconFile);
      const res = await createCategory(formData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isBusy = false;
          draft.isSaveButtonBusy = false;
        });
        navigate("/category");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const getCategoryDetails = async (categoryId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
      });
      const res = await getCategoryDetailsById(categoryId);
      if (res.success) {
        setState((draft) => {
          draft.data = {
            ...res.data,
            show_in_list: res.data.show_in_list ? 1 : 0,
          };
          draft.isBusy = false;
          draft.name = res.data.name;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };
  useEffect(() => {
    if (categoryId && !isProduct) {
      getCategoryDetails(categoryId);
    }
  }, [categoryId]);
  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const handleDropdown = (e) => {
    setState((draft) => {
      draft.data.display_order = e?.value;
    });
  };

  // Handler for color picker
  const handleColorPick = (hex) => {
    setState((draft) => {
      draft.data.color = hex;
    });
  };

  const onSwitchChange = (e, name) => {
    const value = e.target.checked === true ? 1 : 0;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onDescription = (e) => {
    setState((draft) => {
      draft.data.description = e;
    });
  };
  const onFileChange = (e, name) => {
    if (e.target.value !== "") {
      if (e?.target?.files[0]?.type.includes("image")) {
        setState((draft) => {
          draft.iconFile = e.target.files[0];
        });
      } else {
        errorMessage("Unsupported file type ");
      }
    }
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, status } = state.filters;
    if (name === "status") {
      getCategorys(searchText, value);
    }
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  const resetFilters = () => {
    if (toString(state.filters.status) || state.filters.searchText) {
      getCategorys();
      setState((draft) => {
        draft.filters.status = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };

  const onUpdateCategory = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.isSaveButtonBusy = true;
      });
      let formData = new FormData();
      Object.keys(state.data).map((key) => {
        if (key !== "icon" || key === "description") {
          formData.append(key, state.data[key]);
        }
      });
      state.iconFile && formData.append("icon", state.iconFile);
      state.data.description !== null
        ? formData.append("description", state.data.description)
        : formData.append("description", "");
      formData.append("_method", "PUT");
      const res = await editCategory(id, formData);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(`/category/details/${id}`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const handleDeleteCategory = async (type = "list") => {
    try {
      const res = await deleteCategoryById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.category = draft.category.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal();
        if (type === "details") {
          navigate("/category");
        }
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onCategoryOrderChange = async (data) => {
    const payload = {
      categories: data.map((x, i) => ({
        id: x.id,
        display_order: i,
      })),
    };
    try {
      const res = await changeCategoryOrder(payload);
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const getCategoryProductsList = async () => {
    try {
      setState((draft) => {
        draft.product.isBusy = true;
      });
      const res = await getCategoryProducts(categoryId);
      if (res.success) {
        setState((draft) => {
          draft.product.isBusy = false;
          draft.product.products = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.product.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (categoryId && currentTab === "2") {
      getCategoryProductsList();
    }
  }, [categoryId, currentTab]);
  const onCategoryProductOrderChange = async (data) => {
    setState((draft) => {
      draft.product.products = data;
      draft.product.isChanged = true;
    });
  };
  const onSaveCategoryProductOrderChange = async (data) => {
    const payload = {
      products: state.product.products.map((x, i) => ({
        id: x.product_id,
        display_order: i,
      })),
    };
    try {
      const res = await changeCategoryProductOrder(categoryId, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.product.isChanged = false;
        });
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  return {
    state,
    onSaveCategory,
    onChange,
    handleDropdown,
    onSwitchChange,
    onDescription,
    handleColorPick,
    onFileChange,
    onChangeFilter,
    resetFilters,
    isEditable,
    isDeletable,
    isCreateVisible,
    isVisible,
    onUpdateCategory,
    toggleModal,
    handleDeleteCategory,
    globalData,
    onCategoryOrderChange,
    debouncedText,
    categories: state.category,
    currentTab,
    setCurentTab,
    onCategoryProductOrderChange,
    onSaveCategoryProductOrderChange,
  };
};
