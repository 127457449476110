import * as React from "react";
import { Stack, Button, FormControlLabel, Checkbox } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";



export function RefundOrderDialog(props) {
  const {
    handleClose,
    onSubmit,
    isButton,
    onChange,
    data
  } = props;

  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        greaterThanZero: {
          message: 'The deduction amount field is required.',
          rule: (val, params, validator) => {
            if (val.deduct_fee) {
              return val.deduction_amount > 0
            } else {
              return true
            }
          },
          required: true
        }
      }
    })
  );

  const submit = () => {
    if (validator.current.allValid()) {
      onSubmit()
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <InputControl
        type="textarea"
        label="Reason*"
        name="reason"
        placeholder="Describe..."
        value={data.reason}
        onChange={onChange}
        error={validator.current.message(
          "reason",
          data.reason,
          "required"
        )}
        helperText={validator.current.message(
          "reason",
          data.reason,
          "required"
        )}
      />
      <Stack direction="row" gap="12px" marginTop={2} mb={2}>
        <Stack sx={{
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          width: "50%"
        }}>
          <FormControlLabel
            sx={{ mx: 0 }}
            control={
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                checked={data.deduct_fee}
                onChange={(e) =>
                  onChange({
                    target: {
                      name: "deduct_fee",
                      value: e.target.checked ? 1 : 0,
                    },
                  })
                }
              />
            }
            label="Deduct fee"
          />
        </Stack>
        <InputControl
          type="number"
          name="deduction_amount"
          label="Deduction Amount($)"
          placeholder="Deduction Amount($)"
          value={data.deduction_amount}
          onChange={onChange}
          error={validator.current.message(
            "deduction amount",
            data,
            "required|greaterThanZero"
          )}
          helperText={validator.current.message(
            "deduction amount",
            data,
            "required|greaterThanZero"
          )}
        />
      </Stack>
      <Stack direction="row" gap="12px" justifyContent="flex-end">
        <Button
          onClick={submit}
          disabled={isButton}
          variant="contained"
        >
          Submit
        </Button>
      </Stack>
    </div>
  );
}
