import parse from "html-react-parser";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import qs from "qs";

export const getToken = () => {
  let token = "";
  var localData = JSON.parse(localStorage.getItem("pos-user-data"));
  token = localData?.token;
  return token;
};

export const getStoreId = () => {
  const isStoreAdmin = window.location.pathname.includes("/store-admin");
  const localData = JSON.parse(localStorage.getItem("pos-user-data"));
  return isStoreAdmin ? localData?.selectedStoreId : null;
};

export const successMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/success_toast.png",
    style: {
      background: "#388e3c",
    },
  }).showToast();
};
export const successRedMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/icon_not.svg",
    style: {
      background: "#FEB019",
    },
  }).showToast();
};
export const infoMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#FEB019",
    },
  }).showToast();
};

export const errorMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#d32f2f",
    },
  }).showToast();
};

export const sortItems = (prev, curr, columnId) => {
  if (
    prev.original[columnId]?.toLowerCase() >
    curr.original[columnId]?.toLowerCase()
  ) {
    return 1;
  } else if (
    prev.original[columnId]?.toLowerCase() <
    curr.original[columnId]?.toLowerCase()
  ) {
    return -1;
  } else {
    return 0;
  }
};

export const sortNestedItems = (prev, curr, columnId, objectId) => {
  if (
    prev.original[columnId][objectId]?.toLowerCase() >
    curr.original[columnId][objectId]?.toLowerCase()
  ) {
    return 1;
  } else if (
    prev.original[columnId][objectId]?.toLowerCase() <
    curr.original[columnId][objectId]?.toLowerCase()
  ) {
    return -1;
  } else {
    return 0;
  }
};

export const sortStatus = (prev, curr) => {
  if (prev?.toLowerCase() > curr?.toLowerCase()) {
    return 1;
  } else if (prev?.toLowerCase() < curr?.toLowerCase()) {
    return -1;
  } else {
    return 0;
  }
};

const ErrorFormatter = (error) => {
  let errorString = "";

  if (error.response) {
    if (error.response.data.message) {
      errorString += error.response.data.message + "</br>";
    }
    if (error.response.data.errors !== null) {
      for (var err in error.response.data.errors) {
        errorString += error.response.data.errors[err];
      }
    } else if (!error.response.data.message) {
      errorString += "An error has occured!";
    }
  } else {
    errorString += "An error has occured!";
  }
  return parse(errorString);
};

export const arraySort = (data, key) => {
  return [...data].sort(function (a, b) {
    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
  });
};
export const priceValidator = {
  required: true,
  message: "Please enter a number with not more than two decimal places",
  rule: (value) => {
    return /^[-]?(\d*\.\d{1,2}|\d+)$/.test(value);
  },
};
export const costValidator = {
  required: true,
  message: "Please enter a number with not more than three decimal places",
  rule: (value) => {
    return /^[-]?(\d*\.\d{1,3}|\d+)$/.test(value);
  },
};

export const serializeParams = (params) =>
  qs.stringify(params, { encode: true });
