import React from "react";
import { useLocation } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { useDropdownApis } from "../../../shared";
import { errorMessage, infoMessage, successMessage } from "../../../utils";
import { AppContext, AuthContext } from "../../../store";
import { useImmer } from "use-immer";
import {
  downloadXeroMyobListApi,
  createXeroAndMyoMappingApi,
  getAccountSettingsListApi,
} from "../api/XeroAndMyObApi";

export const useXeroAndMyOb = () => {
  // Need to remove the following code
  const [, forceUpdate] = React.useState();
  const { pathname } = useLocation();
  const { auth } = React.useContext(AuthContext);
  const { appState } = React.useContext(AppContext);
  const { xero, myob } = appState.currentTabs;
  const isStoreAdmin = pathname.includes("store-admin");
  const title = pathname.split("/")[isStoreAdmin ? 2 : 1];
  const payloadType = title.toLocaleLowerCase();
  const { selectedStoreId } = auth.data;
  const initialValidationState = {
    isValidated: false,
    start_date: null,
    end_date: null,
  };

  const [state, setState] = useImmer({
    isBusy: false,
    isSaving: false,
    isSettingsUpdated: true,
    reportList: [],
    accountSettingsList: [],
    searchValue: "",
    data: {
      store_id: isStoreAdmin ? selectedStoreId : "",
      start_date: "",
      end_date: "",
    },
    validation: initialValidationState,
  });

  // Breadcrumbs for navigation
  const breadcrumbsLink = [
    { name: "Dashboard", href: "/" },
    { name: title.toUpperCase(), href: "/" + title },
  ];

  const { storeList } = useDropdownApis({
    isStoreList: !isStoreAdmin,
  });

  const dateValidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        endDate: {
          message: "The end date should greater than start date",
          rule: (value) => {
            const { startDate, endDate } = value;
            return moment(startDate, "YYYY-MM-DD").isSameOrBefore(
              moment(endDate, "YYYY-MM-DD")
            );
          },
        },
      },
    })
  );

  const settingsValidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        accountCode: {
          message: "Account code is required !!",
          rule: (value) => {
            const { code, description, taxRate } = value;

            // Util function to check constraints
            const isError = () => {
              if (description || taxRate) {
                if (code?.trim()) {
                  return true;
                }
                return false;
              }
            };

            return isError();
          },
        },
      },
    })
  );

  // Validator function
  const validateDate = (reason, name) => {
    if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid";
      });
    } else if (reason === "minDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid date";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };

  // Function to handle form submission
  const onGenerateReport = () => {
    if (
      dateValidator.current.allValid() &&
      !state.validation.end_date &&
      !state.validation.start_date
    ) {
      const { store_id, start_date, end_date } = state.data;

      const payload = {
        type: payloadType,
        store_id: isStoreAdmin ? selectedStoreId : store_id,
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      };

      triggerLoading();

      handleDownloadXeroMyObList({ payload });
    } else {
      dateValidator.current.showMessages();
      forceUpdate(1);
      setState((draft) => {
        draft.validation.isValidated = true;
      });
    }
  };

  // Function to handle input changes
  const handleInputChange = (event, type) => {
    if (type === "store_id") {
      const { name, value } = event.target;

      setState((draft) => {
        draft.data[name] = value;
      });
    } else if (type === "start_date") {
      setState((draft) => {
        draft.data.start_date = event;
      });
    } else if (type === "end_date") {
      setState((draft) => {
        draft.data.end_date = event;
      });
    }
  };

  // Util function to trigger loading
  const triggerLoading = () => {
    setState((draft) => {
      draft.isBusy = !draft.isBusy;
    });
  };

  // Util Function to trigger save button loading
  const triggerSaveButtonLoading = () => {
    setState((draft) => {
      draft.isSaving = !draft.isSaving;
    });
  };

  // Function to handle download Xero and Myob list
  const handleDownloadXeroMyObList = async ({ payload }) => {
    try {
      const { success, message } = await downloadXeroMyobListApi(payload);

      if (success) {
        infoMessage(message);
      } else {
        errorMessage(message);
      }
      triggerLoading();
    } catch (err) {
      errorMessage(err.response.data.message);
      triggerLoading();
    }
  };

  // Function to handle fetch list
  const getAccountSettingsList = async ({ storeId }) => {
    try {
      const response = await getAccountSettingsListApi({ storeId });
      const { success, data, message } = response;

      if (success) {
        setState((draft) => {
          draft.accountSettingsList = data;
        });
      } else {
        errorMessage(message);
      }
      triggerLoading();
    } catch (error) {
      errorMessage(error.response.data.message);
      triggerLoading();
    }
  };

  // Function to handle create Xero and Myob mapping
  const handleCreateXeroAndMyob = async ({ payload }) => {
    try {
      const { success, message } = await createXeroAndMyoMappingApi(payload);

      // Cancel saveButton loading to false
      triggerSaveButtonLoading();

      if (success) {
        successMessage(message);
        setState((draft) => {
          draft.isSettingsUpdated = true;
        });
      } else {
        errorMessage(message);
      }
    } catch (err) {
      // Cancel saveButton loading to false
      triggerSaveButtonLoading();
      errorMessage(err.response.data.message);
    }
  };

  // Util function to reset the table-data
  const resetTable = () => {
    setState((draft) => {
      draft.searchValue = "";
    });
  };

  // Function to filter the mapping list based on search
  const filterAccountSettingsList = ({ value }) => {
    const convertText = (text, type = "lowerCase") => {
      return type === "lowerCase" ? text.toLowerCase() : text.toUpperCase();
    };

    const searchAndPlaceUnderParent = (value, data, parentPath = []) => {
      const { name, subData } = data;
      const match = convertText(name).includes(convertText(value));
      const currentPath = [...parentPath, { ...data, subData: [] }];

      let results = match ? [currentPath] : [];

      if (subData && subData.length > 0) {
        const subResults = subData.flatMap((subItem) =>
          searchAndPlaceUnderParent(value, subItem, currentPath)
        );
        results = results.concat(subResults);
      }

      return results;
    };

    const allResults = state.accountSettingsList.flatMap((item) =>
      searchAndPlaceUnderParent(value, item)
    );

    // Build the final filtered structure
    const filteredData = allResults.reduce((acc, item) => {
      const last = item[item.length - 1];
      const parentPath = item.slice(0, -1);

      // Find the parent in acc
      let parent = parentPath.reduce((nestedAcc, pathItem) => {
        const existingParent = nestedAcc.find(
          (accItem) => accItem.id === pathItem.id
        );
        if (existingParent) {
          return existingParent.subData;
        } else {
          const newParent = { ...pathItem, subData: [] };
          nestedAcc.push(newParent);
          return newParent.subData;
        }
      }, acc);

      // Add the last item under its parent
      parent.push(last);

      return acc;
    }, []);

    return filteredData;
  };

  // Function to handle table-search
  const handleSearch = (event) => {
    const { value } = event.target;
    setState((draft) => {
      draft.searchValue = value;
    });
  };

  // Function to handle search-clearing
  const handleSearchClear = () => resetTable();

  // Util function to handle mapping updates
  const handleRowChange = (event, row) => {
    const { value, name } = event.target;
    const objectName = payloadType === "xero" ? "xero_account" : "myob_account";

    // Recursive function to update accounts and their subData
    const makeUpdates = (accountsList) => {
      const response = accountsList.map((account) => {
        // Update the specific account with the new value
        if (account.id === row.id) {
          const updatedObject = {
            ...account,
            [objectName]: {
              account_id: account.id,
              ...account[objectName],
              [name]: value,
            },
          };
          return updatedObject;
        } else {
          // Check if there are subData and recursively update them
          const hasSubData = account.accounts > 0;
          if (hasSubData) {
            if (account[objectName] !== null) {
              return {
                ...account,
                [objectName]: {
                  account_id: account.id,
                  ...account[objectName],
                },
                subData: makeUpdates(account.subData),
              };
            } else {
              return {
                ...account,
                subData: makeUpdates(account.subData), // Recursively update subData
              };
            }
          } else {
            if (account[objectName] !== null) {
              return {
                ...account,
                [objectName]: {
                  account_id: account.id,
                  ...account[objectName],
                },
              };
            } else {
              return account; // Return unchanged account if no update is needed
            }
          }
        }
      });
      return response;
    };

    // Map through accountSettingsList and update relevant accounts
    const updatedValues = state.accountSettingsList.map((account) => {
      const { accounts, subData } = account;
      const hasSubData = accounts > 0;

      if (hasSubData) {
        // If account has subData, recursively update subData
        if (account[objectName] !== null) {
          return {
            ...account,
            [objectName]: { account_id: account.id, ...account[objectName] },
          };
        } else {
          return {
            ...account,
            subData: makeUpdates(subData),
          };
        }
      } else {
        // If no subData, return unchanged account
        if (account[objectName] !== null) {
          return {
            ...account,
            [objectName]: { account_id: account.id, ...account[objectName] },
          };
        }
        return account;
      }
    });

    // Update state immutably using Immer
    setState((draft) => {
      draft.isSettingsUpdated = false;
      draft.accountSettingsList = updatedValues;
    });
  };

  // Util function to construct accounts from accountListing
  const constructMappingObject = async (mappingArray) => {
    const objectName = payloadType === "xero" ? "xero_account" : "myob_account";
    const constructedArray = [];
    const subDataMapping = (account) => {
      const accObject = account[objectName];

      if (accObject !== null) {
        if (accObject.code?.trim()) {
          const updatedObject = {
            ...accObject,
            description: null,
            tax_rate: null,
          };
          if (accObject.description) {
            updatedObject.description = accObject.description;
          }
          if (accObject.tax_rate) {
            updatedObject.tax_rate = accObject.tax_rate;
          }
          if (
            !constructedArray.some(
              (obj) => obj.account_id === updatedObject.account_id
            )
          ) {
            constructedArray.push(updatedObject);
          }
        }
      }

      if (account.accounts > 0) {
        account.subData.forEach((subAcc) => {
          const subAccObject = subAcc[objectName];
          if (subAccObject !== null) {
            if (subAccObject?.code?.trim()) {
              const updatedObject = {
                ...subAccObject,
                description: null,
                tax_rate: null,
              };

              if (subAccObject.description) {
                updatedObject.description = subAccObject.description;
              }
              if (subAccObject.tax_rate) {
                updatedObject.tax_rate = subAccObject.tax_rate;
              }
              if (
                !constructedArray.some(
                  (obj) => obj.account_id === updatedObject.account_id
                )
              ) {
                constructedArray.push(updatedObject);
              }
            }
          }

          if (subAcc.accounts > 0) {
            subDataMapping(subAcc);
          }
        });
      }
    };

    mappingArray.forEach(subDataMapping);

    return constructedArray;
  };

  // Util function to validate settings
  const validateSettings = () => {
    return settingsValidator.current.allValid();
  };

  // Function to save settings changes
  const handleSaveUpdates = async () => {
    if (validateSettings()) {
      const mappingObject = await constructMappingObject(
        state.accountSettingsList
      );

      const payload = {
        type: payloadType,
        store_id: state.data.store_id,
        accounts: mappingObject,
      };

      if (payload.accounts.length > 0) {
        // Trigger loading
        triggerSaveButtonLoading();
        // API call to create settings
        handleCreateXeroAndMyob({ payload });
      } else {
        infoMessage("Oops, no updates found!!");
      }
    } else {
      settingsValidator.current.showMessages();
      forceUpdate(1);
      errorMessage("Account code is required !!");
    }
  };

  // useEffect to handle default store selection
  React.useEffect(() => {
    if (storeList.length > 0) {
      setState((draft) => {
        draft.data.store_id = storeList[0].id;
      });
    }
  }, [storeList, setState]);

  // useEffect to handle account-settings list fetch
  React.useEffect(() => {
    const handler = () => {
      triggerLoading();
      // Clear search value
      if (state.searchValue.trim()) handleSearchClear();

      if (isStoreAdmin && selectedStoreId) {
        getAccountSettingsList({ storeId: selectedStoreId });
      } else if (state.data.store_id) {
        getAccountSettingsList({ storeId: state.data.store_id });
      } else {
        triggerLoading();
      }
    };

    // Call handler
    if (xero === "2" || myob === "2") return handler();
  }, [selectedStoreId, xero, myob, state.data.store_id]);

  return {
    breadcrumbsLink,
    state,
    title,
    storeList,
    isStoreAdmin,
    dateValidator,
    settingsValidator,
    validateDate,
    onGenerateReport,
    handleInputChange,
    handleRowChange,
    handleSearch,
    handleSaveUpdates,
    handleSearchClear,
    filterAccountSettingsList,
    setState,
  };
};
