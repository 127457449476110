import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  usePermission,
  ExportCsv,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Chip,
  Typography,
  Checkbox,
  TextField,
  createFilterOptions,
  Box,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { useAdminUsers } from "..";
import { arraySort, sortItems } from "../../../utils";
import { AppContext } from "../../../store/AppScopeProvider";

export const SuperAdminList = () => {
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;
  const isDeletable = usePermission("user-delete");
  const isCreate = usePermission("user-create");
  const {
    state,
    setCurentTab,
    handleModal,
    handleDeleteUser,
    handleFilter,
    handleFilterClear,
    handleApplyFilter,
    handleSearch,
    onSeachClear,
    storeList,
  } = useAdminUsers({
    isList: true,
  });

  const { isLoading, usersList, deleteModalOpen, filterApplied, filterStores } =
    state;
  const [open, setOpen] = React.useState(false);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  const navigate = useNavigate();

  const ActionButtons = ({ id, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <Link to={`details/${id}`}>
          <img
            alt="details"
            onClick={editAction}
            src="/icons/arrow_forward.svg"
          />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone_formatted",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row }) => {
          return (
            <Typography variant="body2">
              {row.original.phone_formatted}
            </Typography>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableGlobalFilter: true,
        sortInverted: true,
        Cell: ({ value }) => {
          return value ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            <Chip
              label="Inactive"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Stores",
        disableGlobalFilter: true,
        disableSortBy: true,
        id: "stores",
        accessor: (row, index) => {
          return (
            <Box>
              {row.stores?.slice(0, 2).map((data) => {
                return (
                  <Chip
                    sx={{ mr: 1, borderRadius: "8px" }}
                    label={data.name}
                    variant="filled"
                    size="small"
                  />
                );
              })}
              {row.stores?.length > 2 && (
                <Chip
                  sx={{ borderRadius: "8px" }}
                  label={`+${
                    row.stores?.length - row.stores?.slice(0, 2)?.length
                  }`}
                  variant="filled"
                  size="small"
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              editAction={() => {
                setCurentTab("adminUsers", "1");
              }}
              deleteAction={() => {
                handleModal("all-list-delete", true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );
  const storeOptions = storeList?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_formatted" },
    { label: "Status", key: "status" },
    { label: "Stores", key: "stores" },
  ];

  const csvData = usersList.map((item) => {
    return {
      name: item.name,
      email: item.email,
      phone_formatted: item.phone_formatted,
      status: item.status ? "Active" : "Inactive",
      stores: item.stores.map((item) => item.name),
    };
  });

  return (
    <>
      <ContentLayout
        title="Admin Users"
        rightContent={
          <>
            <ExportCsv
              fileName="users_Infomation_xlsx"
              csvData={csvData}
              csvHeaders={headers}
            />
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => setOpen(!open)}
            >
              <img
                alt="tools"
                src="/icons/ic_Tools.svg"
                style={{
                  marginRight: "4px",
                }}
              />
              Column Option
            </Button>
            {isCreate && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New User
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={usersList}
          isLoading={isLoading}
          drawerOpen={open}
          drawerOnClose={() => setOpen(!open)}
          filterApplied={filterApplied.all}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          defaultSearch={false}
          filterContent={
            <InputControl
              type="table-search"
              value={filterApplied.searchText}
              onChange={(e) => handleSearch(e.target.value)}
              onClear={onSeachClear}
            />
          }
          filterSection={
            <>
              <Stack direction="row" gap="16px" alignItems="center">
                <InputControl
                  type="select"
                  name="Status"
                  label="Status"
                  value={state.filterApplied.status?.value ?? ""}
                  optionValue={globalData.status ?? []}
                  width="150px"
                  onChange={(e, v) => handleFilter(e, v, "status")}
                />

                <InputControl
                  type="dropdown"
                  name="store"
                  label="Store"
                  multiple={true}
                  options={arraySort(storeOptions || [], "label")}
                  value={filterApplied.stores}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "store")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
          onRowAction={(e) => {
            setCurentTab("adminUsers", "1");
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => handleModal("all-list-delete", false, null)}
        onConfirm={handleDeleteUser}
      />
    </>
  );
};
