import { ContentLayout, CustomTable, DeleteModal } from "../../../shared";
import * as React from "react";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRolesList } from "..";
import { usePermission } from "../../../shared/hooks/usePermission";
import { sortItems } from "../../../utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";

export const RolesList = ({ isStoreAdmin }) => {
  const { state, handleModal, handleDeleteRole, currentTab, setCurentTab } =
    useRolesList();
  const { isLoading, rolesList, deleteModalOpen } = state;
  const navigate = useNavigate();
  const isDelete = usePermission("role-delete");
  const isCreate = usePermission("role-create");

  const handleChange = (event, newValue) => {
    setCurentTab("rolesAndPermissionList", newValue);
  };

  React.useEffect(() => {
    if (isStoreAdmin) {
      setCurentTab("rolesAndPermissionList", "2");
    }
  }, [isStoreAdmin]);

  const ActionButtons = ({ id, row, editAction, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {!isStoreAdmin && isDelete && (
          <img
            alt="delete"
            onClick={() => {
              row?.system_role === false && deleteAction();
            }}
            src="/icons/ic_delete.svg"
            className={` ${row?.system_role && "disabled-img"}`}
          />
        )}
        <Link to={`details/${id}`}>
          <img
            alt="details"
            onClick={editAction}
            src="/icons/arrow_forward.svg"
          />
        </Link>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Role Name",
        accessor: "display_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Members",
        disableSortBy: true,
        id: "count",
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <div className="members-count">
              <img src="/icons/ic_people.svg" />
              {row.member_count}
            </div>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              row={row}
              editAction={() => setCurentTab("rolesAndPermission", "1")}
              deleteAction={() => {
                handleModal(true, row.id);
              }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout title="Roles & Permissions">
        {isStoreAdmin ? (
          <CustomTable
            columns={columns}
            isLoading={isLoading}
            data={rolesList}
            onRowLink={(e) => {
              return `details/${e.id}`;
            }}
            onRowAction={(e) => {
              setCurentTab("rolesAndPermission", "1");
            }}
          />
        ) : (
          <TabContext value={currentTab}>
            <Box
              className="sticky-Tab"
              sx={{ borderBottom: 1, borderColor: "divider", top: "88px" }}
            >
              <TabList onChange={handleChange}>
                <Tab label="Super Admin" value="1" />
                <Tab label="Store Admin" value="2" />
              </TabList>
            </Box>
            <TabPanel sx={{ px: "0" }} value="1">
              <CustomTable
                columns={columns}
                data={rolesList}
                isLoading={isLoading}
                tableTitle="Super Admin"
                headerRightContent={
                  <>
                    {isCreate && (
                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate("details/create")}
                      >
                        New Role
                      </Button>
                    )}
                  </>
                }
                onRowLink={(e) => {
                  return `details/${e.id}`;
                }}
                onRowAction={(e) => {
                  setCurentTab("rolesAndPermission", "1");
                }}
              />
            </TabPanel>
            <TabPanel sx={{ px: "0" }} value="2">
              <CustomTable
                columns={columns}
                hiddenColumns={["count"]}
                data={rolesList}
                isLoading={isLoading}
                tableTitle="Store Admin"
                headerRightContent={
                  <>
                    {isCreate && (
                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate("details/create")}
                      >
                        New Role
                      </Button>
                    )}
                  </>
                }
                onRowLink={(e) => {
                  return `details/${e.id}`;
                }}
                onRowAction={(e) => {
                  setCurentTab("rolesAndPermission", "1");
                }}
              />
            </TabPanel>
          </TabContext>
        )}
      </ContentLayout>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={() => handleModal(false, null)}
        onConfirm={handleDeleteRole}
      />
    </>
  );
};
