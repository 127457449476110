import * as React from "react";
import { Checkbox, FormControlLabel, Stack, Button } from "@mui/material";
import { CustomTable, Loader, usePermission } from "../../../shared";
import { sortItems } from "../../../utils";
import { useNavigate } from "react-router-dom";

export const PermissionsList = (props) => {
  const {
    data,
    roleDetails,
    isLoading,
    handleChecked,
    handleCheckindeterminate,
    isStoreAdmin,
  } = props;
  const navigate = useNavigate();
  const isEditable = usePermission("role-modify");
  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `0px 10px`,
                },
              })}
              style={{ padding: `0px 10px`, marginLeft: "-20px" }}
            >
              <img
                className={`${row.isExpanded && "expanded-icon"}`}
                src="/icons/ic_forward.svg"
              />
            </span>
          ) : null,
        padding: "checkbox",
      },
      {
        Header: () => {
          return <span style={{ marginLeft: `-15px` }}>Permission Name</span>;
        },
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                marginLeft: "-15px",
              }}
            >
              {row.depth ? (
                <img src="/icons/permission.svg" />
              ) : (
                <img src="/icons/Folder.svg" />
              )}

              <span>{row.original.display_name || value}</span>
            </Stack>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        id: "action",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={handleChecked(
                      row.depth,
                      row.original.id,
                      row.originalSubRows
                    )}
                    indeterminate={handleCheckindeterminate(row)}
                    color="primary"
                    disabled
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                  />
                }
              />
            </Stack>
          );
        },
      },
    ],
    [isLoading]
  );
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomTable
          columns={columns}
          data={data}
          searchBar={false}
          rowsSelection={false}
          rowsExpand={true}
          tableTitle="Permissions"
          pagination={false}
          subRow={"permissions"}
          headerRightContent={
            <>
              {!isStoreAdmin && isEditable && (
                <Button
                  type="button"
                  size="medium"
                  disabled={roleDetails?.system_role}
                  variant="contained"
                  onClick={() => navigate("edit")}
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
      )}
    </>
  );
};
