import { useEffect } from "react";
import { useImmer } from "use-immer";
import { getAdminUsersList, deleteUserById } from "..";
import qs from "qs";
import { useDebounce } from "use-debounce";
import { errorMessage, successMessage } from "../../../utils";

export const useStoreAdminUsers = ({ isList }) => {
  const [state, setState] = useImmer({
    isLoading: false,
    usersList: [],
    deleteModalOpen: false,
    selectedUser: null,
    searchText: null,
    status: null,
    isSearching: false,
  });
  const [debouncedText] = useDebounce(state.searchText, 1000);

  useEffect(() => {
    if (state.searchText !== null && !state.isSearching) {
      handleUsersList(debouncedText, state.status);
    }
  }, [debouncedText]);

  useEffect(() => {
    if (isList) {
      handleUsersList();
    }
  }, [isList]);

  //Admin User List
  const handleUsersList = async (searchText = "", status = null) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
      });
      let params = {};
      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (status !== null) {
        params = { ...params, status: status };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getAdminUsersList(query);
      if (res.success) {
        setState((draft) => {
          draft.usersList = res.data;
          draft.isLoading = false;
          draft.isSearching = false;
        });
      }
    } catch (error) {
      setState((draft) => {
        draft.isLoading = false;
        draft.isSearching = false;
      });
    }
  };

  const handleSearch = (v) => {
    setState((draft) => {
      draft.searchText = v;
    });
  };

  const onSeachClear = () => {
    setState((draft) => {
      draft.searchText = "";
    });
  };

  const handleModal = (id = null) => {
    setState((draft) => {
      draft.deleteModalOpen = !draft.deleteModalOpen;
      draft.selectedUser = id;
    });
  };

  const handleDeleteUser = async () => {
    try {
      const res = await deleteUserById(state.selectedUser);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.usersList = draft.usersList.filter(
            (item) => item.id !== state.selectedUser
          );
        });
        handleModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleStatusFilter = (e) => {
    setState((draft) => {
      draft.status = e.target.value;
    });
    const { searchText } = state;
    handleUsersList(searchText, e.target.value);
  };

  const clearFilters = () => {
    if (state.status !== null || state.searchText !== null) {
      setState((draft) => {
        draft.isSearching = true;
        draft.status = null;
        draft.searchText = null;
      });
      handleUsersList();
    }
  };

  return {
    state,
    handleModal,
    handleDeleteUser,
    handleStatusFilter,
    handleSearch,
    onSeachClear,
    clearFilters,
  };
};
