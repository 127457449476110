import {
  ContentLayout,
  CustomTable,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import * as React from "react";
import {
  Button,
  Stack,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
  Card,
  Grid,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { useCreateOrEditRoles } from "..";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { errorMessage } from "../../../utils";

import { sortItems } from "../../../utils";

export const AddOrEditRoles = () => {
  const { id } = useParams();
  const updateMode = id ? true : false;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    state,
    handleCheckPermission,
    handleChecked,
    handleOnChange,
    handleAddRole,
    handleEditRole,
    handleCheckindeterminate,
    globalData,
  } = useCreateOrEditRoles();
  const { isLoading, allPermissionsList, data } = state;

  const onSubmit = () => {
    if (validator.current.allValid()) {
      if (state.data.permission_id.length === 0) {
        errorMessage("At least one permission must be selected");
      } else {
        updateMode ? handleEditRole() : handleAddRole();
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `0px 10px`,
                },
              })}
              style={{ padding: `0px 10px`, marginLeft: "-20px" }}
            >
              <img
                alt="forward"
                className={`${row.isExpanded && "expanded-icon"}`}
                src="/icons/ic_forward.svg"
              />
            </span>
          ) : null,
        padding: "checkbox",
      },
      {
        Header: () => {
          return <span style={{ marginLeft: `-15px` }}>Permission Name</span>;
        },
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                marginLeft: "-15px",
              }}
            >
              {row.depth ? (
                <img alt="permission" src="/icons/permission.svg" />
              ) : (
                <img alt="folder" src="/icons/Folder.svg" />
              )}
              <span>{row.original.display_name || value}</span>
            </Stack>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        id: "action",
        accessor: "permission",
        padding: "checkbox",
        disableSortBy: true,
        Header: (row) => {
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={handleChecked(2)}
                    indeterminate={handleCheckindeterminate("head")}
                    color="primary"
                    onChange={(e) => {
                      handleCheckPermission(e, row, "head");
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 24,
                        height: "24px",
                        width: "24px",
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="body2" fontWeight={500}>
                    All
                  </Typography>
                }
              />
            </Stack>
          );
        },
        Cell: ({ row, value }) => {
          return (
            <Stack
              sx={{
                flexDirection: "row ",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={handleChecked(
                      row.depth,
                      row.original.id,
                      row.originalSubRows
                    )}
                    indeterminate={handleCheckindeterminate("body", row)}
                    color="primary"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                    onChange={(e) => {
                      handleCheckPermission(e, row, "body");
                    }}
                  />
                }
              />
            </Stack>
          );
        },
      },
    ],
    [state]
  );

  return (
    <>
      <ContentLayout
        title={updateMode ? state.details.display_name : "New Role"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={() => onSubmit()}
            >
              {updateMode ? "Update" : "Save"}
            </Button>
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: "Roles & Permissions",
                href: "/roles-and-permissions",
              },
              updateMode
                ? {
                    name: state.details?.display_name,
                    href: `/roles-and-permissions/details/${id}`,
                  }
                : null,
              { name: updateMode ? "Edit" : "Create" },
            ].filter(Boolean)}
          />
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Card sx={{ p: "32px 24px", mb: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    sx={{ width: "150px" }}
                  >
                    Basic Details
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <Stack width={"100%"} gap="20px">
                    <InputControl
                      label="Role Name *"
                      name="name"
                      type="text"
                      placeholder="Role Name"
                      onChange={(e) => handleOnChange(e)}
                      value={data.name}
                      error={validator.current.message(
                        "Role Name",
                        data.name,
                        "required|min:3"
                      )}
                      helperText={validator.current.message(
                        "Role Name",
                        data.name,
                        "required|min:3"
                      )}
                    />
                    <InputControl
                      name="type"
                      label="Role Type"
                      type="select"
                      value={data.type}
                      optionValue={globalData?.role_type ?? []}
                      onChange={(e) => handleOnChange(e)}
                      disabled
                      error={validator.current.message(
                        "Role Type",
                        data.type,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Role Type",
                        data.type,
                        "required"
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            <Paper>
              <Stack sx={{ p: "32px 24px", height: "84px" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Permissions
                </Typography>
              </Stack>
              <CustomTable
                columns={columns}
                data={allPermissionsList}
                rowsSelection={false}
                searchBar={false}
                pagination={false}
                subRow={"permissions"}
              />
            </Paper>
          </>
        )}
      </ContentLayout>
    </>
  );
};
