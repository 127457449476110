import { API } from "../../../utils";

export const getAccountSettingsListApi = async ({ storeId }) => {
  const response = await API.get("coas", {
    params: {
      store_id: storeId,
    },
  });
  return response.data;
};

export const downloadXeroMyobListApi = async (payload) => {
  const response = await API.post(`xero-myob-reports/export`, payload);
  return response.data;
};

export const createXeroAndMyoMappingApi = async (payload) => {
  const response = await API.post("xero-myob", payload);
  return response.data;
};
