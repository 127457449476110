import * as React from "react";
import { Chip, Button, Typography } from "@mui/material";
import {
  CustomTable,
  CustomeDialog,
  Loader,
  DeleteModal,
} from "../../../shared";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { AddUserDialog } from "..";
import InputControl from "../../../shared/components/InputControl";
import { usePermission } from "../../../shared/hooks/usePermission";
import { sortItems } from "../../../utils";
import { useNavigate } from "react-router-dom";

export const MembersList = (props) => {
  const {
    state,
    HandleFilterUsers,
    handleUsersSelect,
    isSelectedUser,
    handleAssignUser,
    handleClickOpen,
    handleClose,
    HandleStatusFilter,
    handleMemberModal,
    handleDeleteMember,
    isStoreAdmin,
    resetFilters,
  } = props;
  const navigate = useNavigate();
  const isEditable = usePermission("user-modify");
  const options = [
    {
      value: 2,
      label: "All",
    },
    {
      value: 1,
      label: "Active",
    },
    {
      value: 0,
      label: "Inactive",
    },
  ];

  const ActionButtons = ({ id, deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isEditable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone_formatted",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Typography variant="body2">
              {row.original.phone_formatted}
            </Typography>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return value ? (
            <Chip
              label="Active"
              color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            <Chip
              label="Inactive"
              color="success"
              variant="outlined"
              size="small"
              disabled
            />
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              id={row.id}
              deleteAction={() => handleMemberModal(true, row.id)}
            />
          );
        },
      },
    ],
    [state.isLoading]
  );
  return (
    <>
      {state.isLoading ? (
        <Loader />
      ) : (
        <CustomTable
          columns={columns}
          data={state.filteredMembersList ?? []}
          searchBar={true}
          filterContent={
            <>
              <InputControl
                type="select"
                name="Status"
                label="Status"
                value={state.filterStatus ?? ""}
                optionValue={options}
                width="200px"
                // onChange={() => HandleStatusFilter()}
                onChange={(e) => {
                  HandleStatusFilter(e.target.value);
                }}
              />
              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => resetFilters()}
              >
                <CloseIcon />
              </Button>
            </>
          }
          tableTitle="Admin Users"
          headerRightContent={
            <>
              {isEditable && (
                <Button
                  type="button"
                  size="medium"
                  variant="text"
                  className="delte"
                  startIcon={<AddIcon />}
                  onClick={() => handleClickOpen()}
                >
                  Add User
                </Button>
              )}
            </>
          }
          onRowLink={(e) => {
            return isStoreAdmin
              ? `/store-admin/admin-users/details/${e.id}`
              : `/admin-users/details/${e.id}`;
          }}
        />
      )}
      <CustomeDialog
        open={state.open}
        handleClose={() => {
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "423px", height: "535px", p: "16px" },
        }}
        title="Users"
        content={
          <AddUserDialog
            userList={state.filteredUsers}
            HandleFilterUsers={HandleFilterUsers}
            handleUsersSelect={handleUsersSelect}
            isSelectedUser={isSelectedUser}
            handleAssignUser={handleAssignUser}
            disabled={state.selectedUsers.length === 0}
            isStoreAdmin={isStoreAdmin}
            value={state.userSearchText}
          />
        }
      />
      <DeleteModal
        open={state.memberDeleteModal}
        handleClose={() => handleMemberModal(false, null)}
        onConfirm={handleDeleteMember}
      />
    </>
  );
};
