import * as React from "react";
import { useImmer } from "use-immer";
import { errorMessage, ratingOptions } from "../../../utils";
import moment from "moment";
import { useDebounce } from "use-debounce";
import { AppContext, TableStateContext } from "../../../store";
import { useDropdownApis } from "../../../shared";
import { getReviewListApi } from "../../ReviewsAndRatings/api/reviewAndRatingsApi";

// Default date-object
const defaultDateObject = {
  isCalenderOpen: false,
  value: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  showDate: [],
};

// Default filter state
const defaultFilterObject = {
  list: {
    rating: [],
    published_status: [],
    status: [],
  },
  date: defaultDateObject,
  appliedFilters: [],
  reset: false,
};

// Default pagination
const defaultPagination = {
  pageIndex: 1,
  pageSize: 50,
  pageCount: 0,
  total: 0,
  hasMorePages: true,
  lastPage: 1,
};

export const useProductRatingsDetails = ({ defaultFilter, table }) => {
  const { appState } = React.useContext(AppContext);
  const { filterState, filterStateDispatch } =
    React.useContext(TableStateContext);
  const { generalTab } = appState.currentTabs;

  // Retrieve the filter applied for the specific table from the filter state context
  const storedFilter = filterState.params[table]?.filterApplied;

  // Format the date values from the stored filter context, if available
  const storedDateValue = storedFilter
    ? [
        {
          // Convert the start and end dates from the stored filter to JavaScript Date objects
          startDate: moment(storedFilter.date.value[0].startDate).toDate(),
          endDate: moment(storedFilter.date.value[0]?.endDate).toDate(),
          key: "selection", // Use a fixed key for selection
        },
      ]
    : defaultDateObject.value; // Fallback to default date values if no filter is applied

  const [state, setState] = useImmer({
    review: {
      list: [],
      pagination: filterState.params[table]?.pagination ?? defaultPagination,
      isLoading: false,
      sortBy: filterState.params[table]?.sortBy ?? [],
      search: filterState.params[table]?.search ?? "",
    },
    rating: {
      options: ratingOptions,
      selectedOptions: [],
    },
    filter: storedFilter
      ? {
          ...storedFilter,
          date: {
            ...storedFilter.date,
            value: storedDateValue,
            showDate:
              storedFilter.date.showDate.length === 0 ? [] : storedDateValue,
          },
        }
      : defaultFilterObject,
  });

  const { categoriesList } = useDropdownApis({
    isCategoryList: table === "products-list",
  });

  const { review_rating } = appState.globalData;

  // Use debounce to delay the search input for reviews by 1000ms
  const [debounceSearch] = useDebounce(state.review.search, 1000);

  // Util function to trigger loading
  const triggerTableLoading = () => {
    setState((draft) => {
      draft.review.isLoading = !draft.review.isLoading;
    });
  };

  // Util function to handle navigation to first/last page of the table
  const gotoPage = (page) => {
    setState((draft) => {
      draft.review.pagination.pageIndex = page;
    });
  };

  // Util function to handle table page-size changes
  const setPageSize = (e) => {
    setState((draft) => {
      draft.review.pagination.pageIndex = 1;
      draft.review.pagination.pageSize = +e.target.value;
    });
  };

  // Util function to handle table sort
  const handleSortBy = (sortBy) => {
    setState((draft) => {
      draft.review.sortBy = sortBy;
    });
  };

  // Function to fetch review-list
  const getReviewList = async ({ page, perPage, ...queryParams }) => {
    try {
      const response = await getReviewListApi({
        page,
        per_page: perPage,
        ...queryParams,
      });
      const { success, data, message } = response;
      // Cancel table loading
      triggerTableLoading();
      if (success && data?.Reviews) {
        // Update table context
        filterStateDispatch({
          type: "SET_DATA",
          page: table,
          data: {
            filterApplied: state.filter,
            pagination: data.pagination,
            search: state.review.search,
            sortBy: state.review.sortBy,
          },
        });

        // Update table state
        setState((draft) => {
          draft.review.list = data.Reviews;
          draft.review.pagination = data.pagination;
        });
      } else {
        errorMessage(message);
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  // Function to handle table filter
  const handleReviewFilterSelection = async ({
    value,
    type = "rating" | "status" | "published_status" | "date_range",
  }) => {
    if (type === "date_range") {
      setState((draft) => {
        draft.filter.date.value = value;
      });
    } else {
      const handleUpdateSelectedItems = (type) => {
        setState((draft) => {
          draft.filter.list[type] = value;
        });
      };

      handleUpdateSelectedItems(type);
    }
  };

  // Function to handle reset the specific filter
  const handleClearFilter = (event) => {
    const formateType = (type) => type.replace(" ", "_").toLowerCase();
    const clearedFilterType = formateType(event.type);
    setState((draft) => {
      clearedFilterType === "date_range"
        ? (draft.filter.date = defaultDateObject)
        : (draft.filter.list[clearedFilterType] = []);

      draft.filter.appliedFilters = state.filter.appliedFilters.filter(
        (filter) => {
          return formateType(filter.type) !== clearedFilterType;
        }
      );
    });
  };

  // Function to handle reset all filter
  const handleClearAllFilter = () => {
    setState((draft) => {
      draft.filter.appliedFilters = [];
      draft.filter.list = {
        rating: [],
        published_status: [],
        status: [],
      };
      draft.filter.date = defaultDateObject;
      draft.filter.reset = !draft.filter.reset;
    });
  };

  // Function to open data picker dialog
  const handleOpenDateRangeSelection = (e) => {
    setState((draft) => {
      draft.filter.date.isCalenderOpen = e;
    });
  };

  // Function to handle apply date-changes
  const handleDateChangeApply = () => {
    setState((draft) => {
      draft.filter.date.showDate = state.filter.date.value;
      draft.filter.date.isCalenderOpen = false;
    });
  };

  // Async function to gather search and sort params
  const getRequestUtilities = async (formData) => {
    // Async function to gather filter parameters
    const getFilterParams = async (formData) => {
      const { list, appliedFilters, date } = state.filter; // Destructuring filter properties from state

      // Iterate through each filter entry in list object
      Object.entries(list).map((filter) => {
        if (filter[0].length > 0) {
          // Ensure key (filter[0]) has a length greater than 0
          const key = filter[0].toLowerCase(); // Convert key to lowercase

          // Map values of the filter to formData under actualKey
          if (filter[1].length > 0) {
            const ids = filter[1].map((item) => item.value ?? item.id);
            Object.assign(formData, {
              [key]: ids,
            });
          }
        }
      });

      // Check if 'Date Range' filter is applied
      if (appliedFilters.find((item) => item.type === "Date Range")) {
        formData.start_date = moment(date.value[0].startDate).format(
          "yyyy-MM-DD"
        ); // Set start_date from date value
        formData.end_date = moment(date.value[0].endDate).format("yyyy-MM-DD"); // Set end_date from date value
      }

      if (defaultFilter && Object.keys(defaultFilter).length > 0) {
        Object.entries(defaultFilter).forEach(([key, value]) => {
          Object.assign(formData, { [`${key}[]`]: value });
        });
      }
    };

    // Attach sortBy
    if (state.review.sortBy.length > 0) {
      state.review.sortBy.forEach((item) => {
        const sortKey =
          item.id === "product_review_count"
            ? "review_count"
            : item.id === "product_average_rating"
            ? "average_rating"
            : item.id === "approved_by_name"
            ? "authorised_by"
            : item.id;
        Object.assign(formData, {
          [`sort[${sortKey}]`]: item.desc ? "desc" : "asc",
        });
      });
    }

    // Attach search
    if (state.review.search.trim()) {
      formData.search = state.review.search;
    }

    // Call filter-params
    await getFilterParams(formData);
  };

  // Function to handle apply filter
  const handleApplyFilter = async () => {
    const { pageIndex } = state.review.pagination;
    setState((draft) => {
      draft.review.pagination.pageIndex = pageIndex > 1 ? 1 : pageIndex;
      draft.filter.reset =
        pageIndex === 1 ? !draft.filter.reset : draft.filter.reset;
    });
  };

  // Util Function to handle table search
  const handleTableSearch = (event) => {
    setState((draft) => {
      draft.review.search = event.target.value;
    });
  };

  // useEffect to fetch review-list
  React.useEffect(() => {
    const formData = {};
    const { pageSize } = state.review.pagination;
    const pageIndex = state.review.pagination.pageIndex;

    (async () => await getRequestUtilities(formData))();

    triggerTableLoading();
    // Fetch reviewList
    getReviewList({
      page: pageIndex,
      perPage: pageSize,
      ...formData,
    });
  }, [
    generalTab,
    debounceSearch,
    state.review.sortBy,
    state.filter.reset,
    state.review.pagination.pageIndex,
    state.review.pagination.pageSize,
  ]);

  // useEffect to update appliedFilter state
  React.useEffect(() => {
    const handleSelectFilter = () => {
      let response = Object.entries(state.filter.list)
        .map((filter) => {
          const values = filter[1].map((data) => data.label || data.name);
          if (values.length > 0) {
            const firstCharacter = filter[0].charAt(0);
            return {
              type: filter[0].replace(
                firstCharacter,
                firstCharacter.toUpperCase()
              ),
              label: values.join(", "),
            };
          }
        })
        .filter((val) => val !== undefined);

      if (state.filter.date.showDate.length > 0) {
        response = [
          ...response,
          {
            label: `${moment(state.filter.date.showDate[0].startDate).format(
              "yyy-MM-DD"
            )} to ${moment(state.filter.date.showDate[0].endDate).format(
              "yyy-MM-DD"
            )}`,
            type: "Date Range",
          },
        ];
      }
      return response;
    };

    setState((draft) => {
      draft.filter.appliedFilters = handleSelectFilter();
    });
  }, [state.filter.list, state.filter.date.showDate]);

  return {
    state,
    handleClearFilter,
    handleApplyFilter,
    handleClearAllFilter,
    handleReviewFilterSelection,
    publishedStatusList: review_rating?.review_rating_publish,
    status: review_rating?.review_rating_status,
    handleOpenDateRangeSelection,
    handleDateChangeApply,
    handleTableSearch,
    categoriesList,
    handleSortBy,
    gotoPage,
    setPageSize,
  };
};
