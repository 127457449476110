import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { AppContext } from "../../../store/AppScopeProvider";
import { getWastageDetailsById } from "../api/wastageApi";
import { errorMessage } from "../../../utils";
var fileDownload = require("js-file-download");

export const useWastage = ({ wastageId }) => {
  const { appState } = useContext(AppContext);
  const {
    globalData: { wastage_types: wastageType },
  } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    wastage: [],
    initialLoad: true,
    wastage_number: "",
    data: {
      id: "",
      wastage_number: "",
      wastage_date: "",
      wastage_type: "",
      terminal_id: "",
      store_id: "",
      user_id: "",
      grant_total: "",
      total_products: "",
      notes: "",
      created_at: "",
      updated_at: "",
      Products: [],
    },
  });

  const getWastageDetails = async (wastageId) => {
    try {
      setState((draft) => {
        draft.isDetailsFetching = true;
        draft.isBusy = true;
      });
      const res = await getWastageDetailsById(wastageId);
      if (res.success) {
        setState((draft) => {
          draft.data = {
            ...res.data,
          };
          draft.isBusy = false;
          draft.wastage_number = res.data.wastage_number;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isDetailsFetching = false;
        draft.isBusy = false;
        // draft.httpStatusCodes.details = err?.response?.status;
      });
    }
  };
  useEffect(() => {
    if (wastageId) {
      getWastageDetails(wastageId);
    }
  }, [wastageId]);

  return {
    state,
  };
};
