import React, { useEffect } from "react";
import {
  getHolidaysInStore,
  getPublicHolidaysInStore,
  getSalesChannels,
  getWeekdaysInStore,
  updateStoreHoliday,
  updateStoreOpeningClosing,
  updateStoreWeekdays,
} from "../..";
import { useImmer } from "use-immer";
import { useParams } from "react-router-dom";
import { errorMessage, successMessage } from "../../../utils";
import { AppContext } from "../../../store/AppScopeProvider";
import moment from "moment";

export const useStoreOpeningHours = ({ details }) => {
  const { id } = useParams();
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;
  const [state, setState] = useImmer({
    isBusy: false,
    PriceLevels: [],
    data: [],
    openingClosing: {
      isSaveButton: true,
      isBusy: false,
      data: {
        is_open_today: null,
        timezone: null,
        opening_time: null,
        closing_time: null,
      },
    },
    holiday: {
      isSaveButton: true,
      isBusy: false,
      data: [],
    },
    week: {
      isSaveButton: true,
      isBusy: false,
      data: [],
    },
  });

  useEffect(() => {
    getStoreHoliday(id);
    getStorePublicHoliday(id);
    getStoreWeekDays(id);
  }, []);

  useEffect(() => {
    setState((draft) => {
      draft.openingClosing.data = {
        is_open_today: details.is_open_today,
        timezone: details.timezone,
        opening_time: details.opening_time,
        closing_time: details.closing_time,
      };
    });
  }, [details]);

  const getStoreHoliday = async (id) => {
    try {
      const res = await getHolidaysInStore(id);
      if (res.success) {
        setState((draft) => {
          draft.holiday.data = [...draft.holiday.data, ...res.data];
        });
      } else {
        errorMessage(res.message);
      }
    } catch {}
  };

  const getStorePublicHoliday = async (id) => {
    try {
      const res = await getPublicHolidaysInStore(id);
      if (res.success) {
        setState((draft) => {
          draft.holiday.data = [...draft.holiday.data, ...res.data];
        });
      } else {
        errorMessage(res.message);
      }
    } catch {}
  };

  const onUpdateOpenigClosing = async (id) => {
    try {
      setState((draft) => {
        draft.openingClosing.isBusy = true;
        draft.openingClosing.isSaveButton = true;
      });
      let formData = new FormData();
      const data = { ...state.openingClosing.data, _method: "PUT" };
      Object.keys(data).map((key) => {
        formData.append(key, data[key]);
      });
      const res = await updateStoreOpeningClosing(id, formData);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.openingClosing.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.openingClosing.isSaveButton = false;
        draft.openingClosing.isBusy = false;
      });
    }
  };

  const handleInputChange = (event, type, index) => {
    const { value, name } = event?.target;
    if (type === "open") {
      setState((draft) => {
        draft.openingClosing.data[name] = value;
        draft.openingClosing.isSaveButton = false;
      });
    } else if (type === "holiday") {
      setState((draft) => {
        draft.holiday.data = [
          ...draft.holiday.data,
          { holiday: value, is_public_holiday: name === "holiday" ? 0 : 1 },
        ];
        draft.holiday.isSaveButton = false;
      });
    } else if (type === "week") {
      setState((draft) => {
        draft.week.data = [
          ...draft.week.data.map((data, i) => {
            return i === index ? { ...data, [name]: value } : data;
          }),
        ];
        draft.week.isSaveButton = false;
      });
    }
  };

  const onUpdateHoliday = async () => {
    try {
      setState((draft) => {
        draft.holiday.isBusy = true;
        draft.holiday.isSaveButton = true;
      });
      let payload = {
        holidays: state.holiday.data,
      };
      const res = await updateStoreHoliday(id, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.holiday.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.holiday.isSaveButton = false;
        draft.holiday.isBusy = false;
      });
    }
  };

  const getStoreWeekDays = async (id) => {
    try {
      const res = await getWeekdaysInStore(id);
      if (res.success) {
        setState((draft) => {
          draft.week.data = res.data.map((data) => {
            return {
              ...data,
              closing_time: moment(data.closing_time, "HH:mm").format("HH:mm"),
              opening_time: moment(data.opening_time, "HH:mm").format("HH:mm"),
            };
          });
        });
      } else {
        errorMessage(res.message);
      }
    } catch {}
  };

  const onUpdateWeekdays = async () => {
    try {
      setState((draft) => {
        draft.week.isBusy = true;
        draft.week.isSaveButton = true;
      });
      let payload = {
        store_hours: state.week.data,
      };
      const res = await updateStoreWeekdays(id, payload);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.week.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.week.isSaveButton = false;
        draft.week.isBusy = false;
      });
    }
  };

  const onDeleteHoliday = (e) => {
    setState((draft) => {
      draft.holiday.data = draft.holiday.data.filter(
        (data) => data.holiday !== e
      );
      draft.holiday.isSaveButton = false;
    });
  };

  const selectedHoliday = state.holiday.data
    .filter((v) => !v.is_public_holiday)
    .map((data) => {
      return data.holiday;
    });

  const selectedpublicHoliday = state.holiday.data
    .filter((v) => v.is_public_holiday)
    .map((data) => {
      return data.holiday;
    });

  return {
    state,
    globalData,
    onUpdateOpenigClosing,
    handleInputChange,
    onUpdateHoliday,
    onUpdateWeekdays,
    onDeleteHoliday,
    selectedHoliday,
    selectedpublicHoliday,
  };
};
