import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { RolesList, RoleDetailsList, AddOrEditRoles } from "..";
import { ProtectedRoute } from "../../shared";

export const RolesAndPermissions = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  return (
    <>
      <Routes>
        <Route path="/" element={<RolesList isStoreAdmin={isStoreAdmin} />} />
        <Route element={<ProtectedRoute allowedRoute={["role-view"]} />}>
          <Route
            path="/details/:id"
            element={<RoleDetailsList isStoreAdmin={isStoreAdmin} />}
          />
        </Route>
        <Route
          element={
            <ProtectedRoute
              allowedRoute={["role-create"]}
              showOnStoreAdmin={false}
            />
          }
        >
          <Route path="/details/create" element={<AddOrEditRoles />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              allowedRoute={["role-modify"]}
              showOnStoreAdmin={false}
            />
          }
        >
          <Route path="/details/:id/edit" element={<AddOrEditRoles />} />
        </Route>
      </Routes>
    </>
  );
};
