import { Box, Button, Card, Grid } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";

const ReportGenerationPanel = ({
  state,
  validator,
  validateDate,
  handleInputChange,
  onSubmit,
}) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 1,
      }}
    >
      <Card>
        <Grid
          container
          p="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Grid
            item
            flex={1}
            display="grid"
            gridColumn="auto"
            gridAutoFlow="column"
            gap={2}
          >
            <InputControl
              type="datePicker"
              name="start_date"
              label="Start Date"
              disableFuture
              inputFormat="dd/MM/yyyy"
              sx={{
                minWidth: "251.33px",
              }}
              value={state.data.start_date ? state.data.start_date : null}
              onChange={(e) => {
                handleInputChange(e, "start_date");
              }}
              onError={(reason, _) => validateDate(reason, "start_date")}
              error={
                state.validation.start_date
                  ? state.validation.isValidated && state.validation.start_date
                  : validator.current.message(
                      "start date",
                      state.data.start_date,
                      "required"
                    )
              }
              helperText={
                state.validation.start_date
                  ? state.validation.isValidated && state.validation.start_date
                  : validator.current.message(
                      "start date",
                      state.data.start_date,
                      "required"
                    )
              }
            />
            <InputControl
              type="datePicker"
              name="end_date"
              label="End Date"
              disableFuture
              inputFormat="dd/MM/yyyy"
              sx={{
                minWidth: "251.33px",
              }}
              value={state.data.end_date ? state.data.end_date : null}
              onChange={(e) => {
                handleInputChange(e, "end_date");
              }}
              onError={(reason, value) => validateDate(reason, "end_date")}
              error={
                state.validation.end_date
                  ? state.validation.isValidated && state.validation.end_date
                  : validator.current.message(
                      "end date",
                      {
                        startDate: state.data.start_date,
                        endDate: state.data.end_date,
                      },
                      "required|endDate"
                    )
              }
              helperText={
                state.validation.end_date
                  ? state.validation.isValidated && state.validation.end_date
                  : validator.current.message(
                      "end date",
                      {
                        startDate: state.data.start_date,
                        endDate: state.data.end_date,
                      },
                      "required|endDate"
                    )
              }
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              sx={{ height: "40px", minWidth: "172px" }} //
              disabled={
                state.isBusy ||
                state.data.start_date === "" ||
                state.data.end_date === "" ||
                state.data.store_id === ""
              }
              onClick={onSubmit}
            >
              Generate and Export
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default ReportGenerationPanel;
