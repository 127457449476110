import * as React from "react";
import { Typography, Button, Stack, Chip, Tab, Box } from "@mui/material";
import {
  ContentLayout,
  CustomDetailsList,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  usePermission,
} from "../../../shared";
import { useNavigate, useParams, Link } from "react-router-dom";
import { CustomTable } from "../../../shared";
import { SettlementDescription, useClientAccount, useCustomers } from "../..";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { LedgerAccountsList } from "../../Configurations/components";
import InputControl from "../../../shared/components/InputControl";

export const ClientAccountDetailsList = (prop) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    state: clientAccountState,
    isEditable,
    globalData,
    handleModal,
    handleDeleteClientAccount,
    currentTab,
    setCurentTab,
  } = useClientAccount({
    isList: false,
  });
  const { state, isStoreAdmin, HandleClientAccountStatus } = useCustomers({
    ClientAccountID: id,
  });
  const isCustomersEditable = usePermission("customer-modify");
  const { details, isLoading, updateData } = state?.clientAccountDetails;
  const [value, setValue] = React.useState(2);
  const handleChange = (event, newValue) => {
    setCurentTab("clientAccDetails", newValue);
  };

  const handleNavigation = (e) => {
    let link = null;
    switch (e.transaction_type) {
      case "Payment":
        link = `transactions/${e.transaction_id}`;
        break;
      case "Purchase":
        if (e.order_id) {
          link = `${isStoreAdmin ? "/store-admin" : ""}/orders/details/${
            e.order_id
          }`;
        }
        break;
      case "Refund":
        if (e.refund_id) {
          link = `${isStoreAdmin ? "/store-admin" : ""}/refund/details/${
            e.refund_id
          }`;
        }
        break;
      default:
        link = null;
    }
    return link;
  };

  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {handleNavigation(row) !== null && (
          <img
            alt="details"
            onClick={() => {
              navigate(handleNavigation(row));
            }}
            src="/icons/arrow_forward.svg"
          />
        )}
      </div>
    );
  };
  React.useEffect(() => {
    if (!updateData.bill_day) {
      setValue(1);
    } else {
      setValue(2);
    }
  }, [updateData.bill_day]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
      },
      {
        Header: "Order Number",
        accessor: "order_number",
      },
      {
        Header: "Date",
        accessor: (val) => {
          return (
            <Typography variant="subtitle2" fontWeight={400} color="#636363">
              {val.transaction_date}
            </Typography>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
          return <div>${value?.toFixed(2)}</div>;
        },
      },
      {
        Header: "Note",
        accessor: "notes",
      },
      {
        Header: "Transaction Type",
        accessor: "transaction_type",
        Cell: ({ value }) => {
          return (
            <>
              <Chip
                label={value}
                color={value === "Payment" ? "success" : "error"}
                variant="outlined"
                size="small"
                sx={{
                  border: "none !important",
                }}
              />
            </>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return row.type === "credit" && !row.order_id ? null : (
            <ActionButtons row={row} details={details} />
          );
        },
      },
    ],
    []
  );

  const column = [
    {
      title: "Store Name",
      accessor: "store_name",
    },

    {
      title: "Credit Limit",
      accessor: ({ credit_limit }) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>${Math.round(credit_limit)?.toFixed(2)}</Typography>
            <Typography variant="subtitle2" fontWeight={600} color="#C07302">
              Total Due Amount - ${details?.due_amount.toFixed(2)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      title: "Restrict Credit",
      accessor: ({ restrict_usage }) => {
        return restrict_usage === 1 ? (
          <Typography>Restricted</Typography>
        ) : (
          <Typography> Not Restricted</Typography>
        );
      },
    },
    {
      title: "Statement Frequency",
      accessor: ({ bill_day, bill_duration, bill_period, bill_date }) => {
        return (
          <Stack direction="row" spacing={1}>
            <SettlementDescription
              bill_day={bill_day}
              bill_duration={bill_duration}
              bill_period={bill_period}
              bill_date={bill_date}
              globalData={globalData}
            />
          </Stack>
        );
      },
    },
    // {
    //   title: "Opening Credit / Carry Forward",
    //   accessor: ({ carry_forward }) => {
    //     return `$${carry_forward?.toFixed(2)}`;
    //   },
    // },
  ];

  const columns2 = [
    {
      title: "Business Name",
      accessor: "business_name",
    },
    {
      title: "ABN",
      accessor: "abn_number",
    },
    {
      title: "Business Address",
      accessor: (row) => {
        return (
          <Stack direction="row" gap={1}>
            {row?.business_address?.unit_number && (
              <div> {row?.business_address?.unit_number},</div>
            )}
            {row?.business_address?.street_number && (
              <div> {row?.business_address?.street_number},</div>
            )}
            {row?.business_address?.street_name && (
              <div> {row?.business_address?.street_name},</div>
            )}
            {row?.business_address?.suburb && (
              <div> {row?.business_address?.suburb},</div>
            )}
            {row?.business_address?.postcode && (
              <div> {row?.business_address?.postcode},</div>
            )}
            {row?.business_address?.state && (
              <div> {row?.business_address?.state}</div>
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <ContentLayout
        title={details?.customer_name}
        active={details?.status ? "Active" : "Inactive"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: isStoreAdmin ? "/store-admin/" : "/",
                },
                {
                  name: "Client Account",
                  href: isStoreAdmin
                    ? "/store-admin/client-account"
                    : `/client-account`,
                },
                { name: details.customer_name },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isStoreAdmin && (
              <Link
                to={Number(details.due_amount) <= 0 ? "#" : `settlement`}
                state={details?.customer_name}
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  disabled={Number(details.due_amount) <= 0}
                >
                  Make Settlement
                </Button>
              </Link>
            )}
          </>
        }
      >
        <TabContext value={currentTab}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Details" value="1" />
              <Tab label="Transactions" value="2" />
              {!isStoreAdmin && <Tab label="Accounts" value="3" />}
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <CustomDetailsList
                    columns={column}
                    data={details}
                    title="Details"
                    headerRightContent={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>Account Status</Typography>
                        {isEditable && (
                          <InputControl
                            type="switch"
                            checked={
                              state?.clientAccountDetails?.details?.status === 1
                                ? true
                                : false
                            }
                            onChange={() => {
                              HandleClientAccountStatus(id);
                            }}
                          />
                        )}
                        {isEditable && (
                          <Button
                            type="button"
                            size="medium"
                            variant="text"
                            flex-direction="right"
                            onClick={() =>
                              isStoreAdmin
                                ? navigate(
                                    `/store-admin/client-account/edit/${id}`
                                  )
                                : navigate(`/client-account/edit/${id}`)
                            }
                          >
                            Edit
                          </Button>
                        )}
                        {isCustomersEditable && isStoreAdmin && (
                          <Button
                            type="button"
                            size="medium"
                            variant="text"
                            flex-direction="right"
                            onClick={() =>
                              navigate(
                                `/store-admin/client-account/details/${id}/customers/${state?.clientAccountDetails?.details?.customer_id}`
                              )
                            }
                          >
                            Customer Edit
                          </Button>
                        )}
                      </Stack>
                    }
                  />
                  {!!details?.is_business_user && (
                    <CustomDetailsList
                      columns={columns2}
                      data={state?.clientAccountDetails?.details}
                      title="Business Details"
                    />
                  )}
                </>
              )}
            </>
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            {isLoading ? (
              <Loader />
            ) : (
              <Stack Stack={3}>
                <CustomTable
                  columns={columns}
                  data={details?.transactions}
                  searchBar={false}
                  tableTitle="Transactions"
                  rowsSelection={false}
                  onRowLink={(e) => {
                    return handleNavigation(e);
                  }}
                />
              </Stack>
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="3">
            <LedgerAccountsList type="clientaccount" />
          </TabPanel>
        </TabContext>

        <DeleteModal
          open={clientAccountState.clientAccount.isOpen}
          handleClose={() => handleModal("details", false, null)}
          onConfirm={() => {
            handleDeleteClientAccount("details");
          }}
        />
      </ContentLayout>
    </>
  );
};
