import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  usePermission,
} from "../../../shared";
import * as React from "react";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InputControl from "../../../shared/components/InputControl";
import { sortItems } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useCOA } from "../hooks";

export const COAList = () => {
  const navigate = useNavigate();

  const { state, handleModal, handleDeleteCOA, onSeachClear, handleCOASearch } =
    useCOA({ isList: true });
  const { filteredList, isLoading, isOpen } = state;
  const isCreate = usePermission("coa-create");
  const isDeletable = usePermission("coa-delete");
  const isEdit = usePermission("coa-modify");
  const ActionButtons = ({ value, id }) => {
    return (
      <div className="action-buttons">
        {!!value.depth && (
          <>
            {isEdit && (
              <img
                alt="edit"
                src="/icons/ic_edit.svg"
                onClick={() => navigate(`edit/${id}`)}
              />
            )}
            {isDeletable && (
              <img
                alt="delete"
                onClick={() => handleModal("coa-delete", true, id)}
                src="/icons/ic_delete.svg"
              />
            )}
          </>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => {
          return <span style={{ marginLeft: `-15px` }}>Account Name</span>;
        },
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap="4px"
                sx={{
                  fontWeight: row.depth ? 400 : 600,
                }}
              >
                <div
                  style={{
                    marginLeft: `${row.depth * 32 - 15}px`,
                    height: "24px",
                  }}
                >
                  {row.canExpand ? (
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          paddingLeft: `0px 10px`,
                        },
                      })}
                    >
                      <img
                        height="24px"
                        alt="forward"
                        className={`${row.isExpanded && "expanded-icon"}`}
                        src="/icons/ic_forward.svg"
                      />
                    </span>
                  ) : null}
                </div>
                <span
                  style={{ marginLeft: !row.canExpand && !row.depth && "24px" }}
                >
                  {row.original.name || value}
                </span>
              </Stack>
            </>
          );
        },
        // padding: "checkbox",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Account Code",
        accessor: "code",
      },
      {
        Header: "Accounts",
        accessor: "accounts",
      },
      {
        Header: "Action",
        id: "id",
        accessor: "id",
        align: "right",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return <ActionButtons value={row} id={value} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <ContentLayout
        title="Chart Of Accounts"
        rightContent={
          <>
            {isCreate && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New Ledger
              </Button>
            )}
          </>
        }
      >
        <>
          <CustomTable
            defaultSearch={false}
            isLoading={isLoading}
            filterContent={
              <>
                <InputControl
                  type="table-search"
                  name="searchText"
                  placeholder="Search"
                  size="small"
                  value={state.filters.searchText}
                  onChange={handleCOASearch}
                  onClear={onSeachClear}
                />
              </>
            }
            columns={columns}
            data={filteredList}
            subRow={"subData"}
            pagination={false}
          />
          <DeleteModal
            open={isOpen}
            handleClose={() => handleModal("coa-delete", false, null)}
            onConfirm={handleDeleteCOA}
          />
        </>
      </ContentLayout>
    </>
  );
};
