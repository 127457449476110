import { API } from "../../../utils";

export const getRolesList = async (type) => {
  let url = "roles";
  if (type) {
    url = `roles?type=${type}`;
  }
  const res = await API.get(url);
  return res.data;
};

export const getRoleDetailsList = async (id) => {
  const res = await API.get(`roles/${id}`);
  return res.data;
};

export const getPermissionList = async () => {
  const res = await API.get(`permissions`);
  return res.data;
};

export const createRoleItem = async (payload) => {
  const res = await API.post("roles", payload);
  return res.data;
};

export const editRoleItem = async (id, payload) => {
  const res = await API.put(`roles/${id}`, payload);
  return res.data;
};

export const getUsersList = async () => {
  const res = await API.get("users");
  return res.data;
};

export const assignUserToRole = async (payload, id) => {
  const res = await API.post(`roles/${id}/assign_user`, payload);
  return res.data;
};

export const deleteRoleById = async (id) => {
  const res = await API.delete(`roles/${id}`);
  return res.data;
};

export const deleteMemberFromRole = async (payload, id) => {
  const res = await API.post(`roles/${id}/delete_user`, payload);
  return res.data;
};
