import * as React from "react";
import {
  ContentLayout,
  HeaderBreadcrumbs,
  Loader,
  useDropdownApis,
} from "../../../shared";
import { useParams } from "react-router-dom";
import {
  Button,
  Stack,
  Card,
  TextField,
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { useConditions } from "../hooks/useConditions";
import { useProducts } from "../../Products";
import { ConditionItems } from "../components/CondtionItems";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";

export const AddOrEditConditions = () => {
  const { id } = useParams();
  const {
    state,
    globalData,
    onAddConditions,
    onChange,
    handleScopeDropdown,
    handleChange,
    onUpdateConditions,
    onSaveConditions,
    handleDelete,
    onValueChange,
    handleConditionType,
    onDeleteProducts,
    onDeleteCustomerSelect,
  } = useConditions({ isConditions: false, conditionId: id, isProduct: true });
  const {
    data: { scope },
  } = state;
  const { handleGetCustomers } = useDropdownApis({});
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { state: productsState } = useProducts({ isProduct: true });
  const ToggleButtonStyled = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#1560D4",
    },
  });
  const onSubmit = () => {
    if (validator.current.allValid()) {
      id ? onUpdateConditions(id) : onSaveConditions();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const productOptions = productsState.productsList.listData?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const cartAttributes = [{ label: "Total", value: "total" }];
  const productAttributes = [
    { label: "Product", value: "product" },
    { label: "Price", value: "price" },
  ];
  const customerAttributes = [{ label: "Customer", value: "customer" }];
  const comparisonOperators = [
    { label: "Greater than", value: ">" },
    { label: "Greater than or Equal to", value: ">=" },
    { label: "Less than", value: "<" },
    { label: "Less than or Equal to", value: "<=" },
    { label: "Equal to", value: "==" },
  ];

  const logicalOperators = [
    { label: "Include", value: "in" },
    { label: "Exclude", value: "not_in" },
  ];
  const ConditionType = [
    { label: "AND", value: 1 },
    { label: "OR", value: 2 },
  ];

  const conditionList = (scope, attribute, rules, operator) => {
    let list = [];
    if (scope === 1) {
      if (attribute === "total") {
        list = comparisonOperators;
      }
    } else if (scope === 2) {
      if (attribute === "customer") {
        list = logicalOperators;
      }
    } else if (scope === 3) {
      if (attribute === "product") {
        list = logicalOperators;
      } else if (attribute === "price") {
        list = comparisonOperators;
      }
    }
    return (
      list.filter((item) => {
        return !rules?.find((rule) => rule.operator === item.value);
      }) || []
    );
  };

  const attributeList = (rules) => {
    let attributes = [];

    if (scope === 1) {
      attributes = cartAttributes;
    } else if (scope === 2) {
      attributes = customerAttributes;
    } else if (scope === 3) {
      attributes = productAttributes;
    } else {
      attributes = [];
    }
    return attributes;
  };

  const disabledValue = state.data.rules.map((i) => i.operator).includes("");
  const disabledValues = state.data.rules.map((i) => i.value).includes("");
  let disableByRemainingOptions = false;

  return (
    <>
      <ContentLayout
        title={id ? state.condition_name : "New Rule"}
        rightContent={
          <>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={state.isSaveButtonBusy}
              onClick={() => onSubmit()}
            >
              {!id ? "Save" : "Update"}
            </Button>
          </>
        }
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Coupon Rules", href: "/coupon-rules" },
              { name: id ? state.condition_name : "Create" },
            ]}
          />
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 1,
              }}
            >
              <Card sx={{ marginBottom: "20px" }}>
                {validator.current.purgeFields()}

                <Stack p="24px 24px 16px">
                  <Stack direction="row" gap="24px" width="100%">
                    <InputControl
                      name="name"
                      label="Rule Name"
                      value={state.data.name}
                      onChange={(e) => onValueChange(e)}
                      error={validator.current.message(
                        "Condition Name",
                        state.data.name,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Condition Name",
                        state.data.name,
                        "required"
                      )}
                      required
                    />
                  </Stack>

                  <Stack direction="row" spacing={2} marginTop={3}>
                    <InputControl
                      name="scope"
                      type="dropdown"
                      disableClearable
                      options={globalData?.conditions?.scope}
                      getOptionLabel={(option) => option.label}
                      value={
                        (globalData?.conditions?.scope.length > 0 &&
                          state.data?.scope &&
                          globalData?.conditions?.scope?.find(
                            (c) => c.value === state.data?.scope
                          )) ||
                        null
                      }
                      onChange={(e, newValue) => {
                        handleScopeDropdown(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Scope"
                          error={validator.current.message(
                            "Scope",
                            state.data.scope,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Scope",
                            state.data.scope,
                            "required"
                          )}
                          required
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Card>

              <Card sx={{ marginBottom: "20px" }}>
                {validator.current.purgeFields()}

                <Stack p="24px 24px 16px">
                  <Stack direction="row" spacing={2}>
                    <Stack
                      direction="row"
                      gap="24px"
                      width="100%"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        color="#636363"
                        fontWeight={400}
                        fontSize="14px"
                      >
                        Condition Type
                      </Typography>

                      <ToggleButtonGroup
                        color="primary"
                        value={state.data.logical_operator}
                        exclusive
                        onChange={handleConditionType}
                        aria-label="Platform"
                        className="custom-pos"
                      >
                        {ConditionType.map((item) => (
                          <ToggleButtonStyled
                            sx={{
                              width: "102px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#636363",
                            }}
                            value={item.value}
                          >
                            {item.label}
                          </ToggleButtonStyled>
                        ))}
                      </ToggleButtonGroup>
                    </Stack>
                  </Stack>

                  {state?.data?.rules?.map((value, index, rules) => {
                    const selectedProductsInOtherIndex = [];
                    const selectedCustomersInOtherIndex = [];
                    rules.forEach((rule, i) => {
                      if (i !== index && Array.isArray(rule.value)) {
                        rule.value.forEach((val) => {
                          if (value.rule_type === "product") {
                            selectedProductsInOtherIndex.push(val);
                          } else if (value.rule_type === "customer") {
                            selectedCustomersInOtherIndex.push(val);
                          }
                        });
                      }
                    });

                    const filteredProducts = productOptions.filter((prd) => {
                      return !selectedProductsInOtherIndex.find(
                        (item) => item.value === prd.value
                      );
                    });

                    return (
                      <>
                        {index > 0 && (
                          <Box mt={2}>
                            <Chip
                              label={
                                state.data.logical_operator === 1 ? "AND" : "OR"
                              }
                              color="primary"
                              variant="outlined"
                              size="small"
                              sx={{ bgcolor: "#1560D414" }}
                              disabled={false}
                            />
                          </Box>
                        )}
                        <Stack direction={"row"} gap={2}>
                          <Stack
                            marginTop={3}
                            direction="column"
                            gap={2}
                            width="100%"
                          >
                            <Stack direction={"row"} gap={2} width="100%">
                              <InputControl
                                type="dropdown"
                                name="rule_type"
                                disableClearable
                                options={attributeList(rules)}
                                getOptionLabel={(option) => option.label}
                                sx={{
                                  width: "100%",
                                }}
                                onChange={(e, newValue) => {
                                  handleChange(newValue, index, "rule_type");
                                }}
                                value={
                                  (value.rule_type &&
                                    attributeList().find(
                                      (c) => c.value === value.rule_type
                                    )) ||
                                  null
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Attribute"
                                    error={validator.current.message(
                                      "Attribute",
                                      value.rule_type,
                                      "required"
                                    )}
                                    helperText={validator.current.message(
                                      "Attribute",
                                      value.rule_type,
                                      "required"
                                    )}
                                    required
                                  />
                                )}
                              />
                              {value.rule_type !== "" && (
                                <InputControl
                                  type="dropdown"
                                  name="operator"
                                  disableClearable
                                  options={conditionList(
                                    scope,
                                    value.rule_type,
                                    rules,
                                    value.operator
                                  )}
                                  getOptionLabel={(option) => option.label}
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={(e, newValue) => {
                                    handleChange(newValue, index, "operator");
                                  }}
                                  value={
                                    (value.operator &&
                                      conditionList(
                                        scope,
                                        value.rule_type
                                      ).find(
                                        (c) => c.value === value.operator
                                      )) ||
                                    null
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Condition"
                                      error={validator.current.message(
                                        "Condition",
                                        value.operator,
                                        "required"
                                      )}
                                      helperText={validator.current.message(
                                        "Condition",
                                        value.operator,
                                        "required"
                                      )}
                                      required
                                    />
                                  )}
                                />
                              )}
                            </Stack>
                            <ConditionItems
                              value={value}
                              scope={scope}
                              onChange={onChange}
                              index={index}
                              validator={validator}
                              productOptions={state?.productList}
                              selectedCustomersInOtherIndex={
                                selectedCustomersInOtherIndex
                              }
                              handleGetCustomers={handleGetCustomers}
                              onDeleteProducts={onDeleteProducts}
                              onDeleteCustomerSelect={onDeleteCustomerSelect}
                            />
                          </Stack>
                          <Box marginTop={3} className="action-buttons">
                            <img
                              alt="delete"
                              onClick={() => {
                                state?.data?.rules?.length > 1 &&
                                  handleDelete(value.id, index);
                              }}
                              className={` ${
                                state?.data?.rules?.length === 1 &&
                                "disabled-img"
                              }`}
                              src="/icons/ic_delete.svg"
                            />
                          </Box>
                        </Stack>
                      </>
                    );
                  })}

                  <Box marginTop={3}>
                    <Stack>
                      <Stack direction="row" marginTop={2} width="100%">
                        <Button
                          variant="contained"
                          onClick={() => onAddConditions()}
                          disabled={disabledValues || disabledValue}
                          startIcon={<AddIcon />}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Card>
            </Box>
          </>
        )}
      </ContentLayout>
    </>
  );
};
