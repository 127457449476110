import React, { useEffect, useRef } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { usePermission } from "../../../shared";
import qs from "qs";
import {
  createFuturePrice,
  deleteFutrePrice,
  editFuturePrice,
  getFilteredProducts,
  getFuturePrice,
  getFuturePriceDetails,
} from "../api";
import { getProductPriceLevel, useProductsList } from "../../Products";
import { importFuturePrice } from "../api";
import { useDebounce } from "use-debounce";

export const useFuturePrice = ({
  isList,
  isProduct,
  futurePriceId,
  productFuturePriceId,
  product_id,
}) => {
  const AlbumArtRef = useRef();
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isBusy: false,
    isSaveButtonBusy: false,
    isLoading: false,
    initialLoad: true,
    product_name: "",
    id: "",
    isOpen: false,
    tax: "",
    data: {
      product_id: "",
      price_level_id: "",
      start_date: "",
      future_price: "",
      current_price: "",
      cost_exclusive_gst: "",
      cost_inclusive_gst: "",
      price_adjustment: "",
      variants: [],
    },
    futureprice: [],
    priceleveles: [],
    products: [],
    filters: {
      isSearching: false,
      searchText: "",
    },
  });
  const [debouncedText] = useDebounce(state.filters.searchText, 1000);
  const isDeletable = usePermission("future-price-delete");
  const isCreateVisible = usePermission("future-price-create");
  const isEditable = usePermission("future-price-modify");
  const [open, setOpen] = React.useState(null);

  const productsArray = state.products;
  const extractedProducts = productsArray.flatMap((category) =>
    category.products.map((product) => ({
      label: product.label,
      value: product.value,
      tax: product.tax,
    }))
  );

  const optionPriceLevel = state?.priceleveles?.map((item) => ({
    label: item.price_level_name,
    value: item.price_level_id,
    item: item,
  }));
  const onLogoClick = () => {
    AlbumArtRef.current.click();
  };

  const getProductsFuturePrice = async () => {
    const product_types = ["bundle", "platter", "retail"];
    try {
      let params = {};

      if (product_types.length) {
        params = { ...params, product_types: product_types };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      const res = await getFilteredProducts(query);
      if (res.data) {
        setState((draft) => {
          draft.products = res.data
            .map((v) => {
              return (
                v.products.length > 0 && {
                  ...v,
                  products: v.products
                    .map((val) => {
                      return {
                        value: val.id,
                        label: val.name,
                        tax: val.tax_rate,
                      };
                    })
                    .filter(Boolean),
                }
              );
            })
            .filter(Boolean);
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (isProduct) {
      getProductsFuturePrice();
    }
  }, [isProduct]);

  useEffect(() => {
    if (product_id) {
      setState((draft) => {
        draft.data.product_id = product_id;
      });
    }
  }, [product_id]);

  useEffect(() => {
    if (isList) {
      getFuturePrices();
    }
  }, [isList]);

  useEffect(() => {
    if (!state.initialLoad && !state.filters.isSearching) {
      getFuturePrices(debouncedText);
    }
  }, [debouncedText]);

  const getFuturePrices = async (searchText = "") => {
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (productFuturePriceId) {
        params = { ...params, product_id: productFuturePriceId };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      const res = await getFuturePrice(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.initialLoad = false;
          draft.futureprice = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };
  const handleDropdown = (e, newValue, type) => {
    const { value } = e.target;
    const priceLevelFilter = state?.priceleveles?.find(
      (i) => i?.price_level_id === newValue?.value
    );

    if (type === "products") {
      setState((draft) => {
        draft.data.product_id = value?.value;
        draft.tax = value.tax;
        draft.data.price_level_id = "";
        draft.data.start_date = "";
        draft.data.future_price = "";
        draft.data.current_price = "";
        draft.data.cost_exclusive_gst = "";
        draft.data.cost_inclusive_gst = "";
        draft.data.price_adjustment = "";
        draft.data.variants = [];
      });
    } else if (type === "priceLevel") {
      setState((draft) => {
        draft.data.price_level_id = newValue?.value;
        draft.data.current_price = Number(priceLevelFilter?.price);
        draft.data.cost_exclusive_gst = Number(
          priceLevelFilter?.cost_exclusive_gst
        );
        draft.data.cost_inclusive_gst = Number(priceLevelFilter?.cost);
        draft.data.price_adjustment = Number(priceLevelFilter?.cost);
        draft.data.variants =
          priceLevelFilter?.variants?.length > 0
            ? [
                ...priceLevelFilter?.variants?.map((data, i) => {
                  return {
                    ...data,
                    id: data?.id,
                    price_adjustment: data?.price_adjustment,
                    cost_adjustment_inclusive_gst: data?.cost_adjustment
                      ? Number(data?.cost_adjustment)
                      : "",
                    cost_adjustment_exclusive_gst:
                      data?.cost_adjustment_exclusive_gst
                        ? Number(data?.cost_adjustment_exclusive_gst)
                        : "",
                  };
                }),
              ]
            : priceLevelFilter?.variants;
      });
    }
  };

  const HandlePriceLevelChange = (e, type = "", vi) => {
    const { name, value } = e.target;

    const tax = state.tax;

    const taxRate = (Number(value) / 100) * tax;
    if (name === "price_adjustment") {
      setState((draft) => {
        draft.data.variants = [
          ...draft.data.variants.map((data, i) =>
            i === vi ? { ...data, [name]: value } : data
          ),
        ];
      });
    } else if (name === "cost_adjustment_exclusive_gst") {
      setState((draft) => {
        draft.data.variants = [
          ...draft.data.variants.map((data, i) =>
            i === vi
              ? {
                  ...data,
                  [name]: value,
                  cost_adjustment_inclusive_gst: (
                    Number(value) + taxRate
                  )?.toFixed(3),
                }
              : data
          ),
        ];
      });
    } else if (name === "cost_adjustment_inclusive_gst") {
      setState((draft) => {
        draft.data.variants = [
          ...draft.data.variants.map((data, i) =>
            i === vi
              ? {
                  ...data,
                  [name]: value,
                  cost_adjustment_exclusive_gst: (
                    Number(value) - taxRate
                  )?.toFixed(3),
                }
              : data
          ),
        ];
      });
    }
  };
  const onChange = (e) => {
    const { value, name } = e.target;

    const tax = state.tax;
    const taxRate = (Number(value) * tax) / 100;
    if (name === "cost_exclusive_gst") {
      setState((draft) => {
        draft.data.cost_exclusive_gst = value;
        draft.data.cost_inclusive_gst = (Number(value) + taxRate)?.toFixed(3);
      });
    } else if (name === "cost_inclusive_gst") {
      setState((draft) => {
        draft.data.cost_inclusive_gst = value;
        draft.data.cost_exclusive_gst = (Number(value) - taxRate)?.toFixed(3);
      });
    } else {
      setState((draft) => {
        draft.data[name] = value;
      });
    }
  };
  const getPriceLevelListProducts = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getProductPriceLevel(state?.data?.product_id);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.priceleveles = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (state?.data?.product_id) {
      getPriceLevelListProducts();
    }
  }, [state?.data?.product_id]);

  const onSaveFuturePrice = async (url) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createFuturePrice(state.data);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(url ? `${url}` : "/future-price");
      } else {
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        errorMessage(res?.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const handleFuturePriceDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getFuturePriceDetails(futurePriceId);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.product_name = res.data.product_name;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (futurePriceId) {
      handleFuturePriceDetailsList();
    }
  }, [futurePriceId]);
  const onUpdateFuturePrice = async (futurePriceId, url) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const res = await editFuturePrice(futurePriceId, state.data);

      if (res.success) {
        successMessage("Updated Successfully");
        navigate(url ? `${url}` : "/future-price");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      } else {
        errorMessage(res?.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const toggleModal = (type, id) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = type;
    });
  };

  const handleDeleteFuturePrice = async () => {
    try {
      const res = await deleteFutrePrice(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.futureprice = draft.futureprice.filter(
            (item) => item.id !== state.id
          );
        });
        toggleModal(false);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const handleImportFuturePriceList = async (e) => {
    try {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      const res = await importFuturePrice(formData);
      if (res.success) {
        successMessage("Future Price file uploaded successfully");
        getFuturePrices();
        AlbumArtRef.current.value = "";
      } else {
        errorMessage(res.message);
        AlbumArtRef.current.value = "";
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      AlbumArtRef.current.value = "";
    }
  };
  const onChangeFilter = (e) => {
    setState((draft) => {
      draft.filters.searchText = e.target.value;
      draft.filters.isSearching = false;
    });
  };
  const onSeachClear = () => {
    setState((draft) => {
      draft.filters.searchText = "";
      draft.filters.isSearching = true;
    });
    getFuturePrices();
  };

  const handleClick = (id) => {
    setOpen(id === open ? null : id);
  };
  return {
    state,
    optionPriceLevel,
    handleDropdown,
    onChange,
    onSaveFuturePrice,
    onUpdateFuturePrice,
    HandlePriceLevelChange,
    handleDeleteFuturePrice,
    toggleModal,
    handleImportFuturePriceList,
    onSeachClear,
    onChangeFilter,
    isCreateVisible,
    isEditable,
    isDeletable,
    onLogoClick,
    AlbumArtRef,
    handleClick,
    open,
    extractedProducts,
  };
};
