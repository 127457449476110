import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Stack, TableCell, TableRow } from "@mui/material";
import InputControl from "../../../shared/components/InputControl"; // Importing a custom InputControl component
import { AppContext } from "../../../store";

// Vertical border style
const verticalBorderStyle = {
  border: 0,
  borderRight: 1,
  borderColor: "#E6E6E6",
};

// Horizontal border style
const horizontalBorder = { borderBottom: "1px solid #E6E6E6 !important" };

// Row component that renders each collapsible row
const TableRowComponent = ({
  row,
  title,
  handleRowChange,
  validator,
  level = 0,
}) => {
  const [open, setOpen] = React.useState(false); // State to track whether the row is expanded or collapsed
  const { appState } = React.useContext(AppContext);

  const { xero } = appState.currentTabs;
  // Check is current tab xero > settings
  const currentPage = title.toLowerCase();
  const isXero = currentPage === "xero" && xero === "2";
  const accountCode = isXero ? row.xero_account?.code : row.myob_account?.code;
  const description = isXero
    ? row.xero_account?.description
    : row.myob_account?.description;
  const taxRate = isXero
    ? row.xero_account?.tax_rate
    : row.myob_account?.tax_rate;

  const parentGutter = level > 0 ? `${level * 20}px !important` : 0; // Indentation based on the level of nesting

  return (
    <React.Fragment>
      {/* Main row of the table */}
      <TableRow sx={horizontalBorder}>
        {/* Cell for the row name and expand/collapse icon */}
        <TableCell
          component="th"
          scope="row"
          sx={{
            ...horizontalBorder,
            marginLeft: parentGutter,
            paddingLeft: parentGutter, // Indentation based on the level of nesting
          }}
        >
          <Stack
            direction="row"
            gap={0.5}
            onClick={() => setOpen(!open)}
            sx={{ "&:hover": { cursor: "pointer" } }}
          >
            {/* Toggle open state on click */}
            <Box component="span" aria-label="expand row">
              {/* Expand/collapse icon */}
              {open ? (
                <KeyboardArrowUpIcon
                  fontSize="small"
                  sx={{
                    visibility: row.subData.length > 0 ? "visible" : "hidden", // Show icon if there are subData elements
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={{
                    visibility: row.subData.length > 0 ? "visible" : "hidden", // Show icon if there are subData elements
                  }}
                />
              )}
            </Box>
            {row.name} {/* Display row name */}
          </Stack>
        </TableCell>
        {/* Cell for the row code */}
        <TableCell component="td" align="center" sx={verticalBorderStyle}>
          {row.code} {/* Display row code */}
        </TableCell>

        {row.parent_id !== null && (
          <>
            <TableCell sx={horizontalBorder}>
              <InputControl
                name="code"
                type="outlinedInput"
                onChange={(event) => handleRowChange(event, row)}
                defaultValue={accountCode}
                error={validator.current.message(
                  "accountCode" + row.id,
                  {
                    code: accountCode,
                    description,
                    taxRate,
                  },
                  "required|accountCode"
                )}
                helperText={validator.current.message(
                  "accountCode" + row.id,
                  {
                    code: accountCode,
                    description,
                    taxRate,
                  },
                  "required|accountCode"
                )}
              />
            </TableCell>
            <TableCell sx={horizontalBorder}>
              <InputControl
                name="description"
                type="outlinedInput"
                onChange={(event) => handleRowChange(event, row)}
                defaultValue={description}
              />
            </TableCell>
            <TableCell sx={horizontalBorder}>
              <InputControl
                name="tax_rate"
                type="outlinedInput"
                onChange={(event) => handleRowChange(event, row)}
                defaultValue={taxRate}
              />
            </TableCell>
          </>
        )}
        {/* Input controls for Myob account details */}
      </TableRow>

      {/* sub-rows for nested data */}
      {row.subData.map((data) => {
        const subAccountCode = isXero
          ? data.xero_account?.code
          : data.myob_account?.code;
        const subAccDescription = isXero
          ? data.xero_account?.description
          : data.myob_account?.description;
        const subAccTaxRate = isXero
          ? data.xero_account?.tax_rate
          : data.myob_account?.tax_rate;

        return open ? ( // Render sub-rows if the parent row is open
          <React.Fragment key={data.id}>
            {/* Only render if there are no further subData elements */}
            {data.subData.length === 0 && (
              <TableRow
                sx={{
                  display: open ? "table-row" : "none", // Show when open, hide when closed
                }}
              >
                {/* Indent sub-row based on the level */}
                <TableCell
                  sx={{
                    paddingLeft:
                      (level > 0 ? level * 31 : (level + 2.2) * 20) +
                      "px !important",
                    ...horizontalBorder,
                  }}
                >
                  {data.name} {/* Display sub-row name */}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...verticalBorderStyle, ...horizontalBorder }}
                >
                  {data.code} {/* Display sub-row code */}
                </TableCell>
                {/* Input controls for Myob account details */}
                <TableCell sx={horizontalBorder}>
                  <InputControl
                    name="code"
                    type="outlinedInput"
                    onChange={(event) => handleRowChange(event, data)}
                    defaultValue={subAccountCode}
                    error={validator.current.message(
                      "accountCode" + data.id,
                      {
                        code: subAccountCode,
                        description: subAccDescription,
                        taxRate: subAccTaxRate,
                      },
                      "required|accountCode"
                    )}
                    helperText={validator.current.message(
                      "accountCode" + data.id,
                      {
                        code: subAccountCode,
                        description: subAccDescription,
                        taxRate: subAccTaxRate,
                      },
                      "required|accountCode"
                    )}
                  />
                </TableCell>
                <TableCell sx={horizontalBorder}>
                  <InputControl
                    name="description"
                    type="outlinedInput"
                    onChange={(event) => handleRowChange(event, data)}
                    defaultValue={subAccDescription}
                  />
                </TableCell>
                <TableCell sx={horizontalBorder}>
                  <InputControl
                    name="tax_rate"
                    type="outlinedInput"
                    onChange={(event) => handleRowChange(event, data)}
                    defaultValue={subAccTaxRate}
                  />
                </TableCell>
              </TableRow>
            )}
            {/* Recursively render nested rows */}
            {data.subData.length > 0 && (
              <TableRowComponent
                {...{
                  row: data,
                  title,
                  level: level + 1,
                  handleRowChange,
                  validator,
                }}
              />
            )}
          </React.Fragment>
        ) : (
          "" // Empty fragment when closed
        );
      })}
    </React.Fragment>
  );
};

export default TableRowComponent;
