import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTable, Loader, ServerPaginatedTable } from "../../../shared";
import { useCustomers } from "../hooks";
import { Chip } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";

export const TransactionsList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state,
    handleSortTransaction,
    state: {
      transactionsDetails: { sortBy, pagination, searchText },
    },
    gotoPageTransaction,
    setPageSizeTransaction,
    onChangeTransactionSearch,
    onSeachClearTransaction,
  } = useCustomers({ transactionId: id });

  const ActionButtons = ({ id, deleteAction, currentTabAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <img
          alt="details"
          onClick={currentTabAction}
          src="/icons/arrow_forward.svg"
        />
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Date & Time",
        accessor: "order_date",
      },
      {
        Header: "Order Number",
        accessor: "order_number",
      },
      {
        Header: "Payment Media",
        accessor: "transaction_id",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Amount",
        accessor: "grand_total",
        Cell: ({ value }) => {
          return <div>${value?.toFixed(2)}</div>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <>
              <Chip
                label={value}
                color={value === "Completed" ? "success" : "error"}
                variant="outlined"
                size="small"
                sx={{
                  border: "none !important",
                }}
              />
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "order_id",
        align: "right",
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ActionButtons
              currentTabAction={() => {
                navigate(`/orders/details/${value}`);
              }}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <>
      {state?.isBusy ? (
        <Loader />
      ) : (
        <ServerPaginatedTable
          rowsSelection={false}
          columns={columns}
          data={state?.transactionsDetails?.data}
          key={sortBy}
          isLoading={state.isBusy}
          gotoPage={gotoPageTransaction}
          setPageSize={setPageSizeTransaction}
          pagination={pagination}
          sortByGlobal={sortBy}
          handleSort={handleSortTransaction}
          defaultSearch={false}
          onRowLink={(e) => {
            return `/orders/details/${e.order_id}`;
          }}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={searchText}
                onChange={(e) => onChangeTransactionSearch(e)}
                onClear={onSeachClearTransaction}
              />
            </>
          }
        />
      )}
    </>
  );
};
