import {
  ContentLayout,
  CustomTable,
  DeleteModal,
  PageNotFound,
} from "../../../shared";
import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, Link } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { useDiscount } from "../hooks/useDiscount";
import { arraySort, sortItems } from "../../../utils";

export const DiscountsList = () => {
  const navigate = useNavigate();
  const {
    state,
    storeList,
    globalData,
    onChangeFilter,
    handleModal,
    handleDeleteDiscount,
    isCreateVisible,
    isEditable,
    setCurentTab,
    handleFilterClear,
    onSeachClear,
    handleFilter,
    handleApplyFilter,
  } = useDiscount({ isDiscount: true });
  const storeOptions = storeList?.map((v) => {
    return { value: v.id, label: v.name };
  });
  const ActionButtons = ({ id }) => {
    return (
      <Link to={`/discount/details/${id}`}>
        <img alt="details" src="/icons/arrow_forward.svg" />
      </Link>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "display_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ value, row }) => {
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {value}
              {row.original.require_manager_passcode !== 0 && (
                <Tooltip
                  title="Access Denied. Only managers can access the locked discounts."
                  placement="top"
                  arrow
                >
                  <Box component="img" pl={0.5} src="/icons/lock.png" />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
      {
        Header: "Discount ",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return (
            <div>
              {row?.original?.discount_mode === "fixed" ? "$" : ""}
              {value}
              {row?.original?.discount_mode === "percentage" ? "%" : ""}
            </div>
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Stores",
        accessor: "store_specific",

        Cell: ({ value, row }) => {
          return (
            <>
              {value === 1 ? (
                <Box borderRadius="2px" maxWidth="85px">
                  <Tooltip
                    title={row?.original?.stores
                      .map((i) => i?.name)
                      ?.join(", ")}
                    placement="right"
                    color="#636363"
                  >
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      fontWeight={400}
                      fontSize="12px"
                      color="#171717"
                    >
                      Selected Stores
                    </Typography>
                  </Tooltip>
                </Box>
              ) : (
                <Typography fontWeight={400} fontSize="12px" color="#171717">
                  All Stores
                </Typography>
              )}
            </>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        disableGlobalFilter: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    []
  );
  if (state.httpStatusCodes.details === 404) {
    return <PageNotFound />;
  }
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  return (
    <>
      <ContentLayout
        title="Discount"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("create")}
              >
                New Discount
              </Button>
            )}
          </>
        }
      >
        <CustomTable
          columns={columns}
          data={state.discounts}
          isLoading={state?.isBusy}
          defaultSearch={false}
          filterApplied={state.filters.all}
          onFilterClear={(e) => handleFilterClear("single", e)}
          onAllFilterClear={(e) => handleFilterClear("all")}
          filterContent={
            <>
              <InputControl
                type="table-search"
                value={state.filters.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={onSeachClear}
              />
            </>
          }
          filterSection={
            <>
              <Stack direction="row" gap="16px" alignItems="center">
                <InputControl
                  type="dropdown"
                  name="store"
                  label="Store"
                  multiple={true}
                  options={arraySort(storeOptions || [], "label")}
                  value={state.filters.stores}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "store")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />

                <InputControl
                  type="select"
                  name="locked"
                  label="Status"
                  optionValue={globalData?.discount?.lock_status ?? []}
                  width="250px"
                  onChange={(e, v) => handleFilter(e, v, "locked")}
                  value={state.filters.locked?.value ?? ""}
                  placeholder="Status"
                />
              </Stack>
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </>
          }
          onRowLink={(e) => {
            if (isEditable) {
              return `details/${e.id}`;
            }
          }}
          onRowAction={() => {
            if (isEditable) {
              setCurentTab("discountGroupDetails", "1");
            }
          }}
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => handleModal()}
        onConfirm={() => handleDeleteDiscount()}
      />
    </>
  );
};
