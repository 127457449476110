import React, { useEffect, useContext, useMemo } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import {
  getStoreList,
  createStore,
  getStoreDetailsById,
  deleteStoreById,
  editStores,
  copyStore,
  getBumpScreen,
  UnpairBumpScreenData,
  createBumpScreen,
  updateBumpScreen,
  getBumpScreenDetail,
  deleteBumpScreen,
  getBumpScreenTag,
  getStoresBankAccount,
  deleteAccountsFromProduct,
  createBankAccount,
  updateBankAccount,
  updateDefaultBankAccount,
  getProducts,
  getSuburbList,
  getStateList,
} from "../..";
import { useDebounce } from "use-debounce";
import { useActivityLog, usePermission, useTabs } from "../../../shared";
import qs from "qs";
import { useChannel } from "@ably-labs/react-hooks";

export const useStore = ({
  isStore,
  storeId,
  isBumpScreen,
  BumpScreensId,
  storeListId,
  isTags,
}) => {
  const { appState } = useContext(AppContext);
  const {
    globalData,
    currentTabs: { storeDetails: currentTab },
  } = appState;
  const { addActivityLog } = useActivityLog();
  const navigate = useNavigate();
  const { setCurentTab, resetCurrentTabs } = useTabs();
  const isEditable = usePermission("store-modify");
  const isDeletable = usePermission("store-delete");
  const isCreateVisible = usePermission("store-create");
  const isVisible = usePermission("store-view");
  const isBumpScreenDeletable = usePermission("bump-screen-delete");
  const isBumpScreenCreateVisible = usePermission("bump-screen-create");
  const isBumpScreenEditable = usePermission("bump-screen-modify");
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");

  const [states, setState] = useImmer({
    isBusy: false,
    isSaveButtonBusy: false,
    isOpen: false,
    isUnpairOpen: false,
    isUnpairBusy: false,
    initialLoad: true,
    isViewOpen: false,
    isDetailsfetching: true,
    storeDeleteModal: false,
    isLoading: false,
    name: "",
    status: 0,
    bumpScreen: [],
    bumpScreenTags: [],
    httpStatusCodes: {
      details: "",
    },
    productsList: [],
    tagFilters: {
      isSearching: false,
      searchText: "",
      bump_screen_tags: [],
    },
    filters: {
      isSearching: false,
      searchText: "",
      status: "",
    },
    bumpscreenfilters: {
      isSearching: false,
      searchText: "",
      type: "",
      pairing_status: "",
    },
    copyStore: {
      isOpen: false,
      id: null,
      storeName: "",
      copyOptions: [],
      isBusy: false,
    },
    id: "",
    store: [],
    iconFile: "",
    isMapOpen: false,

    data: {
      id: "",
      name: "",
      email: "",
      contact_email: "",
      icon: "",
      country_code: 61,
      phone: "",
      phone_formatted: "",
      abn: "",
      state: "",
      city: "",
      franchisee: "",
      franchisee_id: [],
      unit_number: "",
      street_number: "",
      address: "",
      zip_code: "",
      longitude: "",
      latitude: "",
      timezone: [],
      status: 0,
      created_at: "",
      updated_at: "",
      city_id: [],
      state_id: [],
    },
    suburbList: [],
    bumpscreenName: "",
    bumpScreenData: {
      name: "",
      display_product_alias: 0,
      display_category_name: 0,
      conditions: [
        {
          channel_id: "",
          tags: [],
          sales: [],
        },
      ],
    },
    bankAccounts: {
      isLoading: true,
      dialogLoading: true,
      dataList: [],
      isBusy: false,
      isOpen: false,
      isDeleteOpen: false,
      deleteModalOpen: false,
      selectedId: null,
      selectedAccount: null,
      updateData: {
        name: "",
        number: "",
        bsb: "",
        alias: "",
      },
    },
    settings: {
      isSaveButton: true,
      isLoading: true,
      details: {
        variance_status: 0,
        eod_variance: null,
        auto_log_status: 0,
        auto_log_time: null,
        email: [],
      },
      input: "",
    },
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
  });
  const [debouncedText] = useDebounce(states.filters.searchText, 1000);
  const [debounced] = useDebounce(states.tagFilters.searchText, 1000);
  const [BumpScreendebounced] = useDebounce(
    states.bumpscreenfilters.searchText,
    1000
  );

  const [channel, ably] = useChannel(`public:bump-screen`, (message) => {
    const { name, data } = message;
    if (name === "BumpScreenPaired") {
      updateBumScreenPairingStatus(data.bumpScreen.id, 1);
    } else if (name === "BumpScreenUnPaired") {
      updateBumScreenPairingStatus(data.bumpScreen.id, 0);
    }
  });
  const activitySuperAdminUnpairBumpScreen = (res) => {
    const newData = res?.data;
    const { store_name, store_id, id, name } = newData;
    const parameterData = {
      BumpscreenName: name,
      BumpscreenId: id,
      StoreName: store_name,
      StoreId: store_id,
    };
    addActivityLog({
      templateId: 21,
      parameters: parameterData,
      uniqueParameter: store_id,
      data: {
        newLogData: newData,
        oldLogData: {},
      },
    });
  };
  const updateBumScreenPairingStatus = (id, status) => {
    setState((draft) => {
      draft.bumpScreen = draft.bumpScreen.map((item) => {
        if (item.id === id) {
          return { ...item, pairing_status: status };
        } else {
          return item;
        }
      });
    });
  };
  useEffect(() => {
    if (isStore) {
      getStoreLists();
    }
  }, [isStore]);

  const getStoreLists = async (search = null, status = "") => {
    let params = {};
    if (search) {
      params = { ...params, search };
    }
    if (status !== "") {
      params = { ...params, status };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = false;
      });
      const res = await getStoreList(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.store = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  useEffect(() => {
    if (!states.initialLoad && !states.filters.isSearching) {
      const { status } = states.filters;
      getStoreLists(debouncedText, status);
    }
  }, [debouncedText]);

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onSaveState = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      let formData = new FormData();
      Object.keys(states.data).map((key) => {
        if (key !== "franchisee_id") {
          formData.append(key, states.data[key]);
        }
      });
      formData.append("city_id", states.data.city_id.id);
      formData.append("state_id", states.data.state_id.id);
      states.data.franchisee_id.map((i) =>
        formData.append("franchisee_id[]", JSON.stringify(i.id))
      );
      !states.iconFile
        ? formData.delete("icon")
        : formData.append("icon", states.iconFile);
      const res = await createStore(formData);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isBusy = false;
        });
        navigate("/stores");
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const onSwitchChange = (e) => {
    let value = e.target.checked === true ? "1" : "0";
    setState((draft) => {
      draft.data.status = value;
    });
  };
  const onFileChange = (e, name) => {
    if (e.target.value !== "") {
      if (e?.target?.files[0]?.type.includes("image")) {
        setState((draft) => {
          draft.iconFile = e.target.files[0];
        });
      } else {
        errorMessage("Unsupported file type ");
      }
    }
  };
  const getStoreDetail = async (storeId) => {
    setState((draft) => {
      draft.isBusy = true;
      draft.isDetailsfetching = true;
    });
    try {
      const res = await getStoreDetailsById(storeId);
      if (res.success) {
        setState((draft) => {
          draft.name = res.data.name;
          draft.status = res.data.status;
          draft.data = {
            ...res.data,
            franchisee_id: res.data.franchisee,
            city_id: res.data.city,
            state_id: res.data.state,
          };
          draft.isBusy = false;
          draft.isDetailsfetching = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.isDetailsfetching = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };
  useEffect(() => {
    if (storeId && !isStoreAdmin) {
      getStoreDetail(storeId);
    }
  }, [storeId, currentTab]);

  const handleModal = (data, id) => {
    setState((draft) => {
      draft.id = id;
      draft.storeDeleteModal = data;
    });
  };
  const handleDeleteStore = async () => {
    try {
      const res = await deleteStoreById(states.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        navigate(-1);
        handleModal(false);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onUpdateStore = async (storeId) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      let formData = new FormData();
      Object.keys(states.data).map((key) => {
        if (key !== "icon" && key !== "franchisee_id") {
          if (key === "unit_number") {
            if (states.data[key] === null) {
              formData.append(key, "");
            } else {
              formData.append(key, states.data[key]);
            }
          } else {
            formData.append(key, states.data[key]);
          }
        }
      });
      formData.append("city_id", states.data.city_id.id);
      formData.append("state_id", states.data.state_id.id);
      states.data.franchisee_id.map((i) =>
        formData.append("franchisee_id[]", JSON.stringify(i.id))
      );
      states.iconFile && formData.append("icon", states.iconFile);
      formData.append("_method", "PUT");
      const res = await editStores(storeId, formData);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(`/stores/details/${storeId}`);
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const toggleMapModal = () => {
    setState((draft) => {
      draft.isMapOpen = !draft.isMapOpen;
    });
  };
  const onPlaceChange = (lat, lng, address = "") => {
    setState((draft) => {
      draft.data.latitude = lat;
      draft.data.longitude = lng;
      draft.data.address = address;
    });
  };

  const onChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, status } = states.filters;
    if (name === "status") {
      getStoreLists(searchText, value);
    }
    setState((draft) => {
      draft.filters[name] = value;
      draft.filters.isSearching = false;
    });
  };

  const resetFilters = () => {
    if (toString(states.filters.status) || states.filters.searchText) {
      getStoreLists();
      setState((draft) => {
        draft.filters.status = "";
        draft.filters.searchText = "";
        draft.filters.isSearching = true;
      });
    }
  };
  const handleDropdown = (e) => {
    setState((draft) => {
      draft.data.country_code = e?.value;
    });
  };
  const onDropdown = (e) => {
    setState((draft) => {
      draft.data.franchisee_id = e;
    });
  };
  const onCityDropdown = (e) => {
    setState((draft) => {
      draft.data.city_id = e;
      draft.data.state = e.state;
      draft.data.state_id = e.state;
      draft.data.zip_code = e.postcode;
    });
  };
  const onStateDropdown = (e) => {
    setState((draft) => {
      draft.data.state_id = e;
    });
  };
  const onTimezoneDropdown = (e) => {
    setState((draft) => {
      draft.data.timezone = e?.value;
    });
  };

  const onStoreCopyClick = (storeName = "", id = null) => {
    setState((draft) => {
      draft.copyStore.id = id;
      draft.copyStore.storeName = storeName;
      draft.copyStore.isOpen = !draft.copyStore.isOpen;
      draft.copyStore.copyOptions = [];
    });
  };

  const storeCloneOptions = useMemo(
    () =>
      globalData?.clone?.store.map((item) => ({
        id: item.value,
        name: item.label,
      })) || [][globalData?.clone?.store]
  );

  const onCloneOptionsSelect = (id) => {
    const isSelected = states.copyStore.copyOptions.find((item) => item === id);
    if (isSelected) {
      setState((draft) => {
        draft.copyStore.copyOptions = draft.copyStore.copyOptions.filter(
          (item) => item !== id
        );
      });
    } else {
      setState((draft) => {
        draft.copyStore.copyOptions = [...draft.copyStore.copyOptions, id];
      });
    }
  };
  const handleSelectAllCopyOptions = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.copyStore.copyOptions = [
          ...storeCloneOptions.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.copyStore.copyOptions = [];
      });
    }
  };

  const onCloneSubmit = async () => {
    const payload = {
      contents: states.copyStore.copyOptions,
    };
    const { id } = states.copyStore;
    try {
      setState((draft) => {
        draft.copyStore.isBusy = true;
      });
      const res = await copyStore(payload, id);
      if (res.success) {
        setState((draft) => {
          draft.copyStore.isBusy = false;
        });
        navigate(`/stores/details/edit/${res.data.id}`);
      }
    } catch (err) {
      setState((draft) => {
        draft.copyStore.isBusy = false;
      });
    }
  };

  useEffect(() => {
    if (isBumpScreen && storeListId) {
      getBumpScreenLists();
    }
  }, [isBumpScreen, storeListId]);
  useEffect(() => {
    if (!states.initialLoad && !states.bumpscreenfilters.isSearching) {
      const { type, pairing_status } = states.bumpscreenfilters;
      getBumpScreenLists(BumpScreendebounced, type, pairing_status);
    }
  }, [BumpScreendebounced]);
  const getBumpScreenLists = async (
    search = null,
    type = null,
    pairing_status = null
  ) => {
    let params = {
      store_id: storeListId,
    };
    if (search) {
      params = { ...params, search };
    }
    if (type !== null && type !== "") {
      params = { ...params, online_status: type };
    }
    if (pairing_status !== null && pairing_status !== "") {
      params = { ...params, pairing_status: pairing_status };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
      });
      const res = await getBumpScreen(query);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.bumpScreen = res.data;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
      });
    }
  };

  const onPairingStatus = async (id) => {
    try {
      const res = await UnpairBumpScreenData(id);
      if (res.success) {
        successMessage("Bump Screen updated successfully.");
        activitySuperAdminUnpairBumpScreen(res);
        setState((draft) => {
          draft.bumpScreen = [
            ...draft.bumpScreen.map((v) => {
              return id === v.id
                ? { ...v, pairing_status: v.pairing_status === 0 ? 1 : 0 }
                : v;
            }),
          ];
        });
        toggleUnpair();
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onChangeInput = (e, index) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.bumpScreenData.conditions = [
        ...draft?.bumpScreenData?.conditions?.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      ];
    });
  };
  const onUpdateBumpScreen = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        name: states.bumpScreenData.name,
        store_id: storeId,
        display_product_alias: states.bumpScreenData.display_product_alias,
        display_category_name: states.bumpScreenData.display_category_name,
        conditions: states.bumpScreenData.conditions.map((item) => {
          return {
            sales: item.sales.map((item) => item?.id),
            channel_id: item?.channel_id,
            tags: item.tags.map((item) => item?.id),
          };
        }),
      };
      const res = await updateBumpScreen(id, payload);
      if (res.success) {
        successMessage("Updated Successfully");
        navigate(`/stores/details/${storeId}`);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onSaveBumpScreen = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        display_product_alias: states.bumpScreenData.display_product_alias,
        display_category_name: states.bumpScreenData.display_category_name,
        name: states.bumpScreenData.name,
        store_id: storeId,
        conditions: states.bumpScreenData.conditions.map((item) => {
          return {
            sales: item.sales.map((item) => item?.id),
            channel_id: item?.channel_id,
            tags: item.tags.map((item) => item?.id),
          };
        }),
      };

      const res = await createBumpScreen(payload);
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/stores/details/${storeId}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleBumpScreenDetailsLists = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getBumpScreenDetail(BumpScreensId);
      if (res.data) {
        const sale = SaleType?.map((name) => name);
        const tags = BumpScreenTags?.map((name) => name);
        setState((draft) => {
          draft.bumpscreenName = res?.data?.name;
          draft.bumpScreenData = {
            ...res.data,
            name: res.data.name,
            conditions: res?.data?.conditions?.map((item) => {
              return {
                sales: sale?.filter(({ id }) =>
                  item?.sale_type_id?.includes(id)
                ),
                channel_id: item.channel_id,
                tags: tags?.filter(({ id }) => item?.tag_id?.includes(id)),
              };
            }),
          };
          draft.isBusy = false;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (BumpScreensId && states.bumpScreenTags && globalData) {
      handleBumpScreenDetailsLists();
    }
  }, [BumpScreensId, globalData, states.bumpScreenTags]);
  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };
  const onDeleteBumpScreen = async () => {
    try {
      const res = await deleteBumpScreen(states.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.bumpScreen = draft.bumpScreen.filter(
            (item) => item.id !== states.id
          );
        });
        toggleModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onBumpScreenChangeFilter = (e) => {
    const { name, value } = e.target;
    const { searchText, type } = states.bumpscreenfilters;
    if (name === "type") {
      getBumpScreenLists(
        searchText,
        value,
        states.bumpscreenfilters.pairing_status
      );
    }
    if (name === "pairing_status") {
      getBumpScreenLists(
        searchText,
        states.bumpscreenfilters.online_status,
        value
      );
    }
    setState((draft) => {
      draft.bumpscreenfilters[name] = value;
      draft.bumpscreenfilters.isSearching = false;
    });
  };
  const resetFilter = () => {
    if (
      states.bumpscreenfilters.type.toString() ||
      states.bumpscreenfilters.pairing_status.toString() ||
      states.bumpscreenfilters.searchText
    ) {
      getBumpScreenLists();
      setState((draft) => {
        draft.bumpscreenfilters.type = "";
        draft.bumpscreenfilters.pairing_status = "";
        draft.bumpscreenfilters.searchText = "";
        draft.bumpscreenfilters.isSearching = true;
      });
    }
  };
  const getBumpScreenTagsLists = async () => {
    try {
      const res = await getBumpScreenTag();
      if (res.success) {
        setState((draft) => {
          draft.bumpScreenTags = res.data;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (isTags) {
      getBumpScreenTagsLists();
    }
  }, [isTags]);

  // bank Accounts

  useEffect(() => {
    if (storeId && (currentTab === "6" || isStoreAdmin)) {
      HandleStoreBankAccounts(storeId);
    }
  }, [storeId, currentTab, states.bankAccounts.isLoading]);

  const HandleStoreBankAccounts = async (id) => {
    if (states.bankAccounts.isLoading) {
      try {
        const res = await getStoresBankAccount(id);
        if (res.success) {
          setState((draft) => {
            draft.bankAccounts.dataList = res.data;
            draft.bankAccounts.isLoading = false;
          });
        }
      } catch (err) {
        setState((draft) => {
          draft.bankAccounts.isLoading = false;
        });
      }
    }
  };

  const handleAccountsModal = (type, state, data, data2) => {
    const { name, number, bsb, alias } = states.bankAccounts.updateData;

    switch (type) {
      case "delete-accounts":
        setState((draft) => {
          draft.bankAccounts.isDeleteOpen = state;
          draft.bankAccounts.selectedId = data;
        });
        break;
      case "accounts-add":
        if (state && data === 2) {
          setState((draft) => {
            draft.bankAccounts.isOpen = state;
            draft.bankAccounts.type = data;
            draft.bankAccounts.selectedAccount = data2;
            draft.bankAccounts.updateData = draft.bankAccounts.dataList.find(
              (item) => item.id === data2
            );
          });
        } else if (data2 === "backdropClick") {
        } else {
          setState((draft) => {
            draft.bankAccounts.isOpen = state;
            draft.bankAccounts.type = data;
            draft.bankAccounts.selectedAccount = data2;
            draft.bankAccounts.updateData = {
              name: "",
              number: "",
              bsb: "",
              alias: "",
            };
          });
        }

        break;
    }
  };

  const handleDeleteAccontsFromProduct = async () => {
    try {
      const res = await deleteAccountsFromProduct(
        storeId,
        states.bankAccounts.selectedId
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.bankAccounts.dataList = draft.bankAccounts.dataList.filter(
            (item) => item.id !== states.bankAccounts.selectedId
          );
          draft.bankAccounts.isLoading = true;
        });
        handleAccountsModal("delete-accounts", false, null);
      } else {
        errorMessage(res.message);
        handleAccountsModal("delete-accounts", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((draft) => {
      draft.bankAccounts.updateData = {
        ...draft.bankAccounts.updateData,
        [name]: value,
      };
    });
  };

  const onSaveBankAccounts = async () => {
    try {
      setState((draft) => {
        draft.bankAccounts.isBusy = true;
      });

      const res = await createBankAccount(
        storeId,
        states.bankAccounts.updateData
      );
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.bankAccounts.dataList = [
            ...draft.bankAccounts.dataList,
            res.data,
          ];
          draft.bankAccounts.isBusy = false;
          return draft;
        });
        handleAccountsModal("accounts-add", false, null, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.bankAccounts.isBusy = false;
      });
    }
  };

  const onUpdateBankAccounts = async () => {
    try {
      setState((draft) => {
        draft.bankAccounts.isBusy = true;
      });

      const res = await updateBankAccount(
        storeId,
        states.bankAccounts.selectedAccount,
        states.bankAccounts.updateData
      );
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.bankAccounts.dataList = [
            ...draft.bankAccounts.dataList.map((data) => {
              return data.id === states.bankAccounts.selectedAccount
                ? res.data
                : data;
            }),
          ];
          draft.bankAccounts.isBusy = false;
          return draft;
        });
        handleAccountsModal("accounts-add", false, null, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.bankAccounts.isBusy = false;
      });
    }
  };

  const onSubmitBankAccount = () => {
    if (states.bankAccounts.type === 1) {
      onSaveBankAccounts();
    } else {
      onUpdateBankAccounts();
    }
  };

  const handleDefaultAccount = async (id) => {
    try {
      const res = await updateDefaultBankAccount(storeId, id);
      if (res.success) {
        successMessage("Default account updated");
        setState((draft) => {
          draft.bankAccounts.dataList = states.bankAccounts.dataList.map(
            (data) => {
              return { ...data, is_default: data.id === id ? 1 : 0 };
            }
          );
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const toggleViewModal = async (type, i, index, item) => {
    setState((draft) => {
      draft.isViewOpen = type;
    });
    if (type) {
      setState((draft) => {
        draft.tagFilters.bump_screen_tags = item?.map((data, i) =>
          i === index ? { value: data.id, label: data.name } : data
        );
      });
      onChangeTagFilter({
        target: {
          name: "bump_screen_tags",
          value: item?.map((v) => {
            return { value: v.id, label: v.name };
          }),
        },
      });
    }
  };
  const handlegetProductByTags = async ({
    searchText = null,
    tags = null,
    pagination,
    sortBy = [],
  }) => {
    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.initialLoad = true;
      });
      let params = {};

      if (searchText) {
        params = { ...params, search: searchText };
      }
      if (sortBy.length > 0) {
        const sortByParams = sortBy.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
          {}
        );
        params = {
          ...params,
          sort: sortByParams,
        };
      }
      if (tags?.length > 0) {
        const bump_screen_tagIds = tags?.map((v) => {
          return v.value;
        });

        params = { ...params, bump_screen_tags: bump_screen_tagIds };
      }
      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };
      const res = await getProducts(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.initialLoad = false;
          draft.productsList = res.data.products;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
        draft.initialLoad = false;
      });
    }
  };

  useEffect(() => {
    if (!states.initialLoad && !states.tagFilters.isSearching) {
      const { bump_screen_tags } = states.tagFilters;
      handlegetProductByTags({
        searchText: debounced,
        tags: bump_screen_tags,
        pagination: {
          pageIndex: 1,
          pageSize: states.pagination.pageSize,
        },
      });
    }
  }, [debounced]);

  const onChangeTagFilter = (e) => {
    const { name, value } = e?.target;
    const { searchText, bump_screen_tags } = states.tagFilters;
    if (name === "bump_screen_tags") {
      handlegetProductByTags({
        searchText,
        tags: value,
        pagination: {
          pageIndex: 1,
          pageSize: states.pagination.pageSize,
        },
      });
    }
    setState((draft) => {
      draft.tagFilters[name] = value;
      draft.tagFilters.isSearching = false;
    });
  };

  const handleSuburbList = async ({ search = null, page, type }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 10,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res =
        type === "suburb"
          ? await getSuburbList(query, pagination)
          : await getStateList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.suburbList = res.data.data.map((data) => {
            return { id: data.id, name: data.name };
          });
          draft.isSuburbBusy = false;
        });
        const options = res.data.data.map((data) => {
          return {
            id: data.id,
            name: data.name,
            postcode: data.postcode,
            state: { id: data.state_id, name: data.state },
          };
        });
        return {
          options: options,
          hasMore: res.data.pagination.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };
  const gotoPage = (page) => {
    const { searchText, bump_screen_tags } = states.tagFilters;
    // const { sortBy } = states;
    handlegetProductByTags({
      searchText: searchText,
      tags: bump_screen_tags,
      pagination: {
        pageIndex: page,
        pageSize: states.pagination.pageSize,
      },
    });

    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { searchText, bump_screen_tags } = states.tagFilters;
    handlegetProductByTags({
      searchText: searchText,
      tags: bump_screen_tags,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
    });

    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };
  const onAddConditions = (e) => {
    setState((draft) => {
      draft.bumpScreenData.conditions.push({
        channel_id: "",
        tags: [],
        sales: [],
      });
    });
  };
  const handleDeleteCondition = (index) => {
    setState((draft) => {
      draft.bumpScreenData.conditions = draft.bumpScreenData.conditions.filter(
        (item, i) => index !== i
      );
    });
  };
  const SaleType =
    globalData?.sale_type?.map((item) => {
      return { id: item?.value, name: item?.label };
    }) || [];
  const BumpScreenTags =
    (states.bumpScreenTags.length > 0 &&
      states.bumpScreenTags?.map((item) => {
        return { id: item, name: item };
      })) ||
    [];
  const handleChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.bumpScreenData[name] = value;
    });
  };

  const toggleUnpair = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isUnpairOpen = !draft.isUnpairOpen;
    });
  };

  const onConfirmUnpair = () => {
    onPairingStatus(states.id);
  };

  const handleChangeSwitch = (e) => {
    const { checked, name } = e.target;
    setState((draft) => {
      draft.bumpScreenData[name] = checked;
    });
  };

  return {
    handleDropdown,
    onCityDropdown,
    onStateDropdown,
    onTimezoneDropdown,
    onDropdown,
    states,
    currentTab,
    setCurentTab,
    onChange,
    onSaveState,
    onSwitchChange,
    onFileChange,
    handleModal,
    handleDeleteStore,
    onUpdateStore,
    toggleMapModal,
    onPlaceChange,
    onChangeFilter,
    resetFilters,
    storeList: states.store,
    globalData,
    isEditable,
    isDeletable,
    isVisible,
    isCreateVisible,
    resetCurrentTabs,
    onStoreCopyClick,
    storeCloneOptions,
    onCloneSubmit,
    onCloneOptionsSelect,
    handleSelectAllCopyOptions,
    onPairingStatus,
    onChangeInput,
    onUpdateBumpScreen,
    onSaveBumpScreen,
    toggleModal,
    onDeleteBumpScreen,
    onBumpScreenChangeFilter,
    resetFilter,
    isBumpScreenEditable,
    isBumpScreenDeletable,
    isBumpScreenCreateVisible,
    handleAccountsModal,
    handleDeleteAccontsFromProduct,
    handleInputChange,
    onSubmitBankAccount,
    handleDefaultAccount,
    toggleViewModal,
    onChangeTagFilter,
    handleSuburbList,
    gotoPage,
    setPageSize,
    onAddConditions,
    handleDeleteCondition,
    SaleType,
    handleChange,
    BumpScreenTags,
    toggleUnpair,
    onConfirmUnpair,
    handleChangeSwitch,
  };
};
